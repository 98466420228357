import React, { useState, useEffect } from "react";
import TextField from "@mui/material/TextField";
import { useSelector, useDispatch } from "react-redux";
import Grid from "@mui/material/Grid";
import LangPicker from "../global/LangPicker";
import { getDefaultLanguage } from "../../utils/languages";
import MultiLangTextField from "../global/textFields/MultilanguageTextField";
import ImageSelector from "../global/imageSelector/ImageSelector";
import { APP_ASSETS_STORES } from "../../constants";
import AddressAutocomplete from "../global/address/AddressAutocomplete";
import MyStatusPicker from "../global/statusPicker/MyStatusPicker";
import { toast } from "react-toastify";
import { POST_STORES_ADD, POST_STORES_UPDATE_LOGO } from "../../redux/actionTypes";
import { postStoresUpdateLogo } from "../../redux/stores/action";
import MyListPicker from "../global/listPicker/MyListPicker";

const AddEditStore = (props) => {
  const dispatch = useDispatch();

  //SELECTORS
  const settingsData = useSelector(({ settings }) => settings.settingsData);
  const updateStoreLogoData = useSelector(
    ({ stores }) => stores.updateStoreLogoData
  );

  const { editItem } = props;

  //HOOKS VARIABLES
  const [selectedLang, setSelectedLang] = useState(
    getDefaultLanguage(settingsData.app_languages)
  );

  const [names, setNames] = useState([]);
  const [descriptions, setDescriptions] = useState([]);
  const [addressItem, setAddressItem] = useState(null);

  const [order_radius, setOrderRadus] = useState(10);
  const [delivery_time, setDeliveryTime] = useState(25);
  const [delivery_method, setDeliveryMethod] = useState("RADIUS");
  const [delivery_method_options, setDeliveryMethodOptions] = useState([]);
  const [min_order_cost, setMinOrderCost] = useState(5);
  const [takeaway_time, setTakeawayTime] = useState(25);
  const [delivery_cost, setDeliveryCost] = useState(0);
  const [min_delivery_cost, setMinDeliveryCost] = useState(0);


  const [status, setStatus] = useState(false);
  const [has_delivery_waiting, setHasDeliveryWaiting] = useState(false);
  

  //VARIABLES
  const trans = settingsData.trans;

  //HOOKS
  useEffect(() => {
    var deliveryOptions = [];
    settingsData.delivery_methods_list.forEach((element) => {
      deliveryOptions.push({
        value: element.method_id,
        text: element.method_text,
      });
    });
    setDeliveryMethodOptions(deliveryOptions);
  }, [settingsData]);

  useEffect(() => {
    if (addressItem == null) {
      props.onSubmitData(null);
    } else {
      if (props.toGetData && addressItem != null) {
        let postData = makePostItem();

        if (editItem != null) {
          postData.storeId = editItem.id;
        }

        props.onSubmitData(postData);
      }
    }
  }, [props.toGetData]);

  const makePostItem = () => {
    let postData = {
      names: names,
      descriptions: descriptions,
      address: addressItem.address,
      street_number: addressItem.street_number,
      area: addressItem.area,
      postalCode: addressItem.postalCode,
      lat: addressItem.lat,
      lng: addressItem.lng,
      delivery_method: delivery_method,
      order_radius: order_radius,
      delivery_time: delivery_time,
      min_order_cost: min_order_cost,
      status: status,
      takeaway_time: takeaway_time,
      delivery_cost: delivery_cost,
      min_delivery_cost: min_delivery_cost
    };

    return postData;
  };

  useEffect(() => {
    if (editItem != null) {
      setNames(editItem.names);
      setDescriptions(editItem.descriptions);
      setAddressItem({
        address: editItem.address,
        street_number: editItem.street_number,
        area: editItem.area,
        postalCode: editItem.postalCode,
        lat: editItem.lat,
        lng: editItem.lng,
      });
      setOrderRadus(editItem.order_radius);
      setDeliveryTime(editItem.delivery_time);
      setDeliveryMethod(editItem.delivery_method);
      setMinOrderCost(editItem.min_order_cost);
      setStatus(editItem.status.value);

      setTakeawayTime(editItem.takeaway_time);
      setDeliveryCost(editItem.delivery_cost);
      setMinDeliveryCost(editItem.min_delivery_cost);
      setHasDeliveryWaiting(editItem.has_delivery_waiting);

    } else {
      clearData();
    }
  }, [editItem]);

  useEffect(() => {
    if (props.toClearData) {
      clearData();
    }
  }, [props.toClearData]);

  useEffect(() => {
    if (updateStoreLogoData != null) {
      if (updateStoreLogoData.status) {
        toast.dismiss();
        toast.success(updateStoreLogoData.message, {
          position: toast.POSITION.TOP_RIGHT,
        });
        props.realoadData();
      } else {
        toast.dismiss();
        toast.error(updateStoreLogoData.message, {
          position: toast.POSITION.TOP_RIGHT,
        });
      }
      dispatch({ type: POST_STORES_UPDATE_LOGO, payload: null });
    }
  }, [updateStoreLogoData]);

  //FUNCTIONS
  const clearData = () => {
    setNames([]);
  };

  const changeLanguage = (lang) => {
    setSelectedLang(lang);
  };

  const updateImageItem = (imageItem) => {
    dispatch(
      postStoresUpdateLogo({
        storeId: editItem.id,
        logo: imageItem.img,
        logo_thumb: imageItem.img_thumb,
      })
    );
  };

  const handleAddressChange = (aItem) => {
    console.log(aItem);
    
    setAddressItem(aItem);
  };

  //UI
  return (
    <div>
      <Grid container spacing={3} alignItems="center">
        <Grid item xs={12}>
          <LangPicker onLangSelectedChanged={changeLanguage} />
        </Grid>

        <Grid item xs={12}>
          <MultiLangTextField
            label={trans.table_head_store_name}
            currentLang={selectedLang}
            texts={names}
            onTextsChange={(texts) => setNames(texts)}
          />
        </Grid>

        <Grid item xs={12}>
          <MultiLangTextField
            label={trans.table_head_store_description}
            currentLang={selectedLang}
            texts={descriptions}
            onTextsChange={(texts) => setDescriptions(texts)}
          />
        </Grid>

        <Grid item xs={12}>
          <AddressAutocomplete
            initialAddressItem={addressItem}
            onAddressSelect={(aItem) => handleAddressChange(aItem)}
          />
        </Grid>

        <Grid item xs={4}>
          <TextField
            margin="dense"
            id="delivery_time"
            label={trans.store_delivery_time}
            value={delivery_time}
            type="number"
            onChange={(e) => {
              setDeliveryTime(e.target.value);
            }}
            fullWidth
            variant="standard"
          />
        </Grid>

        <Grid item xs={4}>
          <TextField
            margin="dense"
            id="takeaway_time"
            label={trans.takeaway_time}
            value={takeaway_time}
            type="number"
            onChange={(e) => {
              setTakeawayTime(e.target.value);
            }}
            fullWidth
            variant="standard"
          />
        </Grid>


        <Grid item xs={4}>
          <TextField
            margin="dense"
            id="min_order_cost"
            label={trans.store_min_order_cost}
            value={min_order_cost}
            type="number"
            onChange={(e) => {
              setMinOrderCost(e.target.value);
            }}
            fullWidth
            variant="standard"
          />
        </Grid>

        <Grid item s={12} xs={12} md={6}>
          <MyListPicker
            labelId={"delivery_method"}
            label={trans.store_delivery_method}
            selected={delivery_method}
            onChange={(value) => setDeliveryMethod(value)}
            list={delivery_method_options}
          />
        </Grid>

        <Grid item xs={6}>
          <TextField
            margin="dense"
            id="order_radius"
            label={trans.store_order_radius}
            value={order_radius}
            type="number"
            onChange={(e) => {
              setOrderRadus(e.target.value);
            }}
            fullWidth
            variant="standard"
          />
        </Grid>

       

        <Grid item xs={4}>
          <TextField
            margin="dense"
            id="delivery_cost"
            label={trans.delivery_cost}
            value={delivery_cost}
            type="number"
            onChange={(e) => {
              setDeliveryCost(e.target.value);
            }}
            fullWidth
            variant="standard"
          />
        </Grid>

        <Grid item xs={4}>
          <TextField
            margin="dense"
            id="min_delivery_cost"
            label={trans.min_delivery_cost}
            value={min_delivery_cost}
            type="number"
            onChange={(e) => {
              setMinDeliveryCost(e.target.value);
            }}
            fullWidth
            variant="standard"
          />
        </Grid>


        <Grid item xs={4}>
          <MyStatusPicker
            label={trans.has_delivery_waiting}
            status={has_delivery_waiting}
            onChange={(value) => setHasDeliveryWaiting(value)}
          />
        </Grid>

  

        {editItem != null ? (
          <Grid item xs={4}>
            <ImageSelector
              title={trans.store_logo_field}
              desc={`${trans.graphics_best_fit} 1024 x 1024`}
              initialImageUrl={editItem != null && editItem.logo_thumb_url}
              type="store_logo"
              mainOnly="false"
              path={APP_ASSETS_STORES}
              onSave={(image) => updateImageItem(image)}
            />
          </Grid>
        ) : null}

        <Grid item xs={8}>
          <MyStatusPicker
            label={trans.store_status}
            status={status}
            onChange={(value) => setStatus(value)}
          />
        </Grid>
      </Grid>
    </div>
  );
};

export default AddEditStore;
