import {
  POST_CATALOG_EXTRAS_LIST_ALL,
  POST_CATALOG_EXTRAS_ADD_EDIT,
  POST_CATALOG_EXTRAS_SINGLE,
  POST_CATALOG_EXTRAS_DELETE,
  POST_CATALOG_EXTRAS_ADD_EXTRA_ITEM,
  POST_CATALOG_EXTRAS_DELETE_EXTRA_ITEM,
  POST_ADD_EDIT_PRODUCT_EXTRA,
  POST_BULK_EDIT_PRODUCT_EXTRA
} from "../actionTypes";

const INIT_STATE = {
  catalogExtrasListData: null,
  addEditCatalogExtrasData: null,
  singleCatalogExtrasData: null,
  deleteCatalogExtrasData: null,
  addEditCatalogExtraItemData: null,
  deleteEditCatalogExtraItemData: null,
  addEditProductExtraData: null,
  bulkEditProductExtraData: null
};

const reducer = (state = INIT_STATE, action) => {
  switch (action.type) {
    case POST_CATALOG_EXTRAS_LIST_ALL:
      return {
        ...state,
        catalogExtrasListData: action.payload,
      };
    case POST_CATALOG_EXTRAS_ADD_EDIT:
      return {
        ...state,
        addEditCatalogExtrasData: action.payload,
      };
    case POST_CATALOG_EXTRAS_SINGLE:
      return {
        ...state,
        singleCatalogExtrasData: action.payload,
      };
    case POST_CATALOG_EXTRAS_DELETE:
      return {
        ...state,
        deleteCatalogExtrasData: action.payload,
      };
    case POST_CATALOG_EXTRAS_ADD_EXTRA_ITEM:
      return {
        ...state,
        addEditCatalogExtraItemData: action.payload,
      };
    case POST_CATALOG_EXTRAS_DELETE_EXTRA_ITEM:
      return {
        ...state,
        deleteEditCatalogExtraItemData: action.payload,
      };
      case POST_ADD_EDIT_PRODUCT_EXTRA:
        return {
          ...state,
          addEditProductExtraData: action.payload,
        };
        case POST_BULK_EDIT_PRODUCT_EXTRA:
          return {
            ...state,
            bulkEditProductExtraData: action.payload,
          };

        

    default:
      return state;
  }
};

export default reducer;
