import {combineReducers} from 'redux';

//Reducers Imports
import settings from './settings/reducer';
import auth from './auth/reducer';
import stores from './stores/reducer';
import upload from './upload/reducer';
import users from './users/reducer';
import banners from './banners/reducer';
import notifications from './notifications/reducer';
import contacts from './contacts/reducer';
import admin from './admin/reducer';
import scripts from './scripts/reducer';
import sideMenu from './sideMenu/reducer';
import catalog from './catalog/reducer';
import catalogExtras from './catalog-extras/reducer';
import orders from './orders/reducer';
import offers from './catalog-offers/reducer';
import coupons from './coupons/reducer';
import userReview from './userReview/reducer';
//End of Reducers Imports

export default combineReducers({
    settings: settings,
    auth: auth,
    stores: stores,
    upload: upload,
    users: users,
    banners: banners,
    notifications: notifications,
    contacts:contacts,
    admin: admin,
    scripts: scripts,
    sideMenu: sideMenu,
    catalog: catalog,
    catalogExtras: catalogExtras,
    orders: orders,
    offers: offers,
    coupons: coupons,
    userReview: userReview
});