import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Autocomplete from "@mui/material/Autocomplete";
import TextField from "@mui/material/TextField";
import Grid from "@mui/material/Grid";
import { postCatalogCategoryList } from "../../../redux/catalog/action";

// Adjust the props to accept an array of preSelectedCatalogCategoryIds
const CatalogCategoriesPicker = ({
  onCatalogCategorySelect,
  multiple = false,
  autoSelectFirst = false,
  preSelectedCatalogCategoryIds = [],
  selectedStores
}) => {
  const settingsData = useSelector(({ settings }) => settings.settingsData);
  const listCatalogCategoryData = useSelector(({ catalog }) => catalog.listCatalogCategoryData);

  const dispatch = useDispatch();
  const trans = settingsData.trans;

  const [catalogCategoriesList, setCatalogCategoriesList] = useState([]);
  const [selectedCatalogCategorys, setSelectedCatalogCategorys] = useState(multiple ? [] : null);
  const selectionDone = React.useRef(false);

  useEffect(() => {
    dispatch(postCatalogCategoryList({storeIds: selectedStores}));
  }, [dispatch]);

  // Adjust the useEffect hook that handles setting the preselected catalogCategorys
  useEffect(() => {
    if (listCatalogCategoryData && listCatalogCategoryData.catalog_categories && listCatalogCategoryData.catalog_categories.length > 0) {
        setCatalogCategoriesList(listCatalogCategoryData.catalog_categories);


      // This check ensures we have the catalogCategorys data to work with
      let updatedSelectedCatalogCategorys = [];

      // If there are preSelectedCatalogCategoryIds, attempt to find and set them
      if (preSelectedCatalogCategoryIds.length > 0) {
        updatedSelectedCatalogCategorys = listCatalogCategoryData.catalog_categories.filter((catalogCategory) =>
          preSelectedCatalogCategoryIds.includes(catalogCategory.id)
        );
      } else if (autoSelectFirst && !multiple) {
        // Auto-select the first catalogCategory if applicable
        updatedSelectedCatalogCategorys.push(listCatalogCategoryData.catalog_categories[0]);
      }

      if (updatedSelectedCatalogCategorys.length > 0) {
        setSelectedCatalogCategorys(
          multiple ? updatedSelectedCatalogCategorys : updatedSelectedCatalogCategorys[0]
        );
        onCatalogCategorySelect(
          multiple ? updatedSelectedCatalogCategorys : updatedSelectedCatalogCategorys[0]
        );
        selectionDone.current = true;
      }
    }
  }, [
    listCatalogCategoryData,
    autoSelectFirst,
    multiple,
    preSelectedCatalogCategoryIds,
    onCatalogCategorySelect,
  ]);

  const handleSelectCatalogCategory = (event, value) => {
    setSelectedCatalogCategorys(value);
    onCatalogCategorySelect(value);
  };

  return (
    <Grid item xs={12}>
      <Autocomplete
        multiple={multiple}
        id="catalogCategorys-autocomplete"
        options={catalogCategoriesList || []}
        getOptionLabel={(option) => `${option.name}` || ""}
        value={selectedCatalogCategorys}
        onChange={handleSelectCatalogCategory}
        renderInput={(params) => (
          <TextField
            {...params}
            label={trans.catalog_category_picker_field}
            placeholder={trans.catalog_category_picker_field}
            variant="outlined"
          />
        )}
        isOptionEqualToValue={(option, value) => option.id === value.id}
      />
    </Grid>
  );
};

export default CatalogCategoriesPicker;
