import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';
import Grid from '@mui/material/Grid';
import { postUsersAll } from '../../redux/users/action';

const UsersPicker = ({
  onUserSelect,
  multiple = false,
  autoSelectFirst = false,
  preSelectedUserIds = [],
}) => {
  const dispatch = useDispatch();
  const usersData = useSelector(({ users }) => users.usersData);
  const settingsData = useSelector(({ settings }) => settings.settingsData);

  const [usersList, setUsersList] = useState([]);
  const [selectedUsers, setSelectedUsers] = useState(multiple ? [] : null);

  useEffect(() => {
    dispatch(postUsersAll({}));
  }, [dispatch]);

  useEffect(() => {
    if (usersData && usersData.users.length > 0) {
      setUsersList(usersData.users);
  
      if (autoSelectFirst && !multiple && selectedUsers === null) {
        const firstUser = usersData.users[0];
        handleSelectUser(null, firstUser);
      } else if (preSelectedUserIds.length > 0 && selectedUsers.length === 0) {
        const preSelectedUsers = usersData.users.filter(user => preSelectedUserIds.includes(user.id));
        setSelectedUsers(multiple ? preSelectedUsers : preSelectedUsers[0]);
      }
    }
  }, [usersData, autoSelectFirst, multiple, preSelectedUserIds]);
  

  const handleSelectUser = (event, value) => {
    if (multiple) {
      const userIds = value.map((user) => user.id);
      setSelectedUsers(value);
      onUserSelect(userIds);
    } else {
      setSelectedUsers(value);
      onUserSelect(value.id);
    }
  };

  return (
    <Grid item xs={12}>
      <Autocomplete
        multiple={multiple}
        id="users-autocomplete"
        options={usersList}
        getOptionLabel={(option) => option.fullname || `${option.firstname} ${option.lastname}`.trim() || "No Name"}
        value={selectedUsers}
        onChange={handleSelectUser}
        renderInput={(params) => (
          <TextField
            {...params}
            label={settingsData.trans.user_picker_field || "Select User"}
            variant="outlined"
          />
        )}
        isOptionEqualToValue={(option, value) => option.id === value.id}
      />
    </Grid>
  );
};

export default UsersPicker;
