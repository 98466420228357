import React, { useState,useEffect } from 'react';
import './Home.css';
import {useDispatch, useSelector} from "react-redux";
import { styled } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import MuiDrawer from '@mui/material/Drawer';
import Box from '@mui/material/Box';
import MuiAppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import List from '@mui/material/List';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import { Link,Route,Routes } from "react-router-dom";
import { useParams } from 'react-router';
import MenuIcon from '@mui/icons-material/Menu';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import SettingsIcon from '@mui/icons-material/Settings';
import LogoutIcon from '@mui/icons-material/Logout';
import AccountBoxIcon from '@mui/icons-material/AccountBox';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import DashboardIcon from '@mui/icons-material/Dashboard';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Switch from '@mui/material/Switch';
import Dashboard from '../dashboard';
import * as General from '../../helpers/General'
import SweetAlert from 'sweetalert2';
import { APP_NAME, PRIMARY_COLOR } from '../../constants';
import { getUserStoreIds, postLogout } from '../../redux/auth/action';
import { ListItemButton } from '@mui/material';
import InitSettings from '../initSettings';
import InitGraphics from '../initGraphics';
import GroupIcon from '@mui/icons-material/Group';
import DesignServicesIcon from '@mui/icons-material/DesignServices';
import CatalogImport from '../CatalogImport';
import TapasIcon from '@mui/icons-material/Tapas';
import StoreIcon from '@mui/icons-material/Store';
import StoresList from '../stores';
import MenuOpenIcon from '@mui/icons-material/MenuOpen';
import SideMenuList from '../sidemenu';
import RestaurantIcon from '@mui/icons-material/Restaurant';
import LocalPharmacyIcon from '@mui/icons-material/LocalPharmacy';
import CatalogMenuList from '../catalog/CatalogMenuList';
import CatalogExtrasList from '../catalog-extras/CatalogExtrasList';
import ShoppingBagIcon from '@mui/icons-material/ShoppingBag';
import OrdersList from '../orders';
import Users from '../users';
import { updateUserTopics } from '../../firebase/firebaseSet';
import LocalOfferIcon from '@mui/icons-material/LocalOffer';
import CatalogOffersList from '../catalog-offers/CatalogOffersList';
import PercentIcon from '@mui/icons-material/Percent';
import CouponsList from '../coupons/CouponsList';
import { english_flag, greek_flag } from '../../images';
import { USER_LOCALE } from '../../redux/actionTypes';
import UserReviewsList from '../userReview';
import ReviewsIcon from '@mui/icons-material/Reviews';
import NotificationsIcon from '@mui/icons-material/Notifications';
import NotificationsListPage from '../notifications';

const drawerWidth = 240;

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== 'open',
})(({ theme, open }) => ({
  zIndex: theme.zIndex.drawer + 1,
  transition: theme.transitions.create(['width', 'margin'], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

const Drawer = styled(MuiDrawer, { shouldForwardProp: (prop) => prop !== 'open' })(
  ({ theme, open }) => ({
    '& .MuiDrawer-paper': {
      position: 'relative',
      whiteSpace: 'nowrap',
      width: drawerWidth,
      transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
      }),
      boxSizing: 'border-box',
      ...(!open && {
        overflowX: 'hidden',
        transition: theme.transitions.create('width', {
          easing: theme.transitions.easing.sharp,
          duration: theme.transitions.duration.leavingScreen,
        }),
        width: theme.spacing(7),
        [theme.breakpoints.up('sm')]: {
          width: theme.spacing(9),
        },
      }),
    },
  }),
);


const Home = (props) => {

  //SELECTORS 
  const userInfoData = useSelector(({auth}) => auth.userInfoData);
  const settingsData = useSelector(({settings}) => settings.settingsData);
  const logoutData = useSelector(({auth}) => auth.logoutData);
  const userTypeStoreUser = useSelector(({auth}) => auth.userTypeStoreUser);

  
  //HOOKS VARIABLES
  const [darkModeChecked, setDarkModeChecked] = useState(false);
  const [selectedMenu, setSelectedMenu] = useState("dashboard");
  const [open, setOpen] = useState(true);
  const [userLang, setUserLang] = useState(General.getLocale());

  //VARIABLES
  const paramData = useParams();
  const dispatch = useDispatch();
  const trans = settingsData.trans;

  //HOOKS
  useEffect(()=>{
    setDarkModeChecked(General.getLightsMode() == "dark")
    },[]);

  useEffect(()=>{
    setSelectedMenu(paramData["*"]);
  },[paramData]);

  useEffect(()=>{
    if (logoutData != null){
      if (logoutData.status){
        window.location.reload();
      }
    }
  },[logoutData]);

  //FUNCTIONS
  const toggleDrawer = () => {
    setOpen(!open);
  };
 
  const askToLogoutUser = (e) => {
    e.preventDefault();
    SweetAlert.fire({
      theme: "dark",
      title:trans.logout_title,
      text:trans.logout_descr,
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: PRIMARY_COLOR,
      confirmButtonText:trans.ok_btn,
      cancelButtonText: trans.cancel_btn,
      reverseButtons: true
    }).then((result) => {
      if (result.value) {
          //OK
          //LOGOUT
          confirmLogoutUser();
      }
    })
  }

  const confirmLogoutUser = () => {
    let postData = {}

    var preselectdstores = getUserStoreIds(userInfoData.userInfo);

    if (preselectdstores.length > 0) {
      updateUserTopics(false,preselectdstores);
    }
    
    dispatch(postLogout(postData));
  }

  const mainListItems = (
    <div>
      <ListItemButton  selected={selectedMenu == "dashboard"} component={Link} to="" >
        <ListItemIcon>
          <DashboardIcon />
        </ListItemIcon>
        <ListItemText primary={<span className='_menu_text_item'>{trans.menu_dashboard}</span>} /> 
      </ListItemButton>
      <ListItemButton  selected={selectedMenu == "users"}  component={Link} to="users">
        <ListItemIcon>
          <GroupIcon />
        </ListItemIcon>
        <ListItemText primary={<span className='_menu_text_item'>{trans.menu_users}</span>} />
      </ListItemButton>
      {userTypeStoreUser == false ? 
      <ListItemButton  selected={selectedMenu == "stores"}  component={Link} to="stores">
        <ListItemIcon>
          <StoreIcon />
        </ListItemIcon>
        <ListItemText primary={<span className='_menu_text_item'>{trans.menu_stores}</span>} />
      </ListItemButton> : null}
      {userTypeStoreUser == false ? 
      <ListItemButton  selected={selectedMenu == "sidemenu"}  component={Link} to="sidemenu">
        <ListItemIcon>
          <MenuOpenIcon />
        </ListItemIcon>
        <ListItemText primary={<span className='_menu_text_item'>{trans.menu_side_menu}</span>} />
      </ListItemButton> : null }
      <ListItemButton  selected={selectedMenu == "catalog-list"}  component={Link} to="catalog-list">
        <ListItemIcon>
          <RestaurantIcon />
        </ListItemIcon>
        <ListItemText primary={<span className='_menu_text_item'>{trans.menu_catalog}</span>} />
      </ListItemButton>
      <ListItemButton  selected={selectedMenu == "catalog-offers"}  component={Link} to="catalog-offers">
        <ListItemIcon>
          <LocalOfferIcon />
        </ListItemIcon>
        <ListItemText primary={<span className='_menu_text_item'>{trans.menu_catalog_offers}</span>} />
      </ListItemButton>
      <ListItemButton  selected={selectedMenu == "catalog-extras"}  component={Link} to="catalog-extras">
        <ListItemIcon>
          <LocalPharmacyIcon />
        </ListItemIcon>
        <ListItemText primary={<span className='_menu_text_item'>{trans.menu_catalog_extras}</span>} />
      </ListItemButton>
      <ListItemButton  selected={selectedMenu == "coupons"}  component={Link} to="coupons">
        <ListItemIcon>
          <PercentIcon />
        </ListItemIcon>
        <ListItemText primary={<span className='_menu_text_item'>{trans.menu_coupons}</span>} />
      </ListItemButton>
      <ListItemButton  selected={selectedMenu == "userreviews"}  component={Link} to="userreviews">
        <ListItemIcon>
          <ReviewsIcon />
        </ListItemIcon>
        <ListItemText primary={<span className='_menu_text_item'>{trans.menu_userreviews}</span>} />
      </ListItemButton>
      <ListItemButton  selected={selectedMenu == "orders"}  component={Link} to="orders">
        <ListItemIcon>
          <ShoppingBagIcon />
        </ListItemIcon>
        <ListItemText primary={<span className='_menu_text_item'>{trans.menu_orders}</span>} />
      </ListItemButton>
    </div>
  );
  
  const secondaryListItems = (
    <div>
      <ListItemButton  selected={selectedMenu == "notifications"}  component={Link} to="notifications">
        <ListItemIcon>
          <NotificationsIcon />
        </ListItemIcon>
        <ListItemText primary={<span className='_menu_text_item'>{trans.menu_notifications}</span>} />
      </ListItemButton>
       {userTypeStoreUser == false ? 
      <ListItemButton  selected={selectedMenu == "catalog-import"}  component={Link} to="catalog-import">
        <ListItemIcon>
          <TapasIcon />
        </ListItemIcon>
        <ListItemText primary={<span className='_menu_text_item'>{trans.menu_catalog_import}</span>} />
      </ListItemButton> : null}
      {userTypeStoreUser == false ? 
      <ListItemButton  selected={selectedMenu == "settings"}  component={Link} to="settings">
        <ListItemIcon>
          <SettingsIcon />
        </ListItemIcon>
        <ListItemText primary={<span className='_menu_text_item'>{trans.menu_settings}</span>} />
      </ListItemButton> : null}
      {userTypeStoreUser == false ? 
      <ListItemButton  selected={selectedMenu == "graphics"}  component={Link} to="graphics">
        <ListItemIcon>
          <DesignServicesIcon />
        </ListItemIcon>
        <ListItemText primary={<span className='_menu_text_item'>{trans.menu_graphics}</span>} />
      </ListItemButton> : null}
      <ListItemButton  selected={selectedMenu == "logout"} onClick={(e) => {askToLogoutUser(e)}}>
        <ListItemIcon>
          <LogoutIcon />
        </ListItemIcon>
        <ListItemText primary={<span className='_menu_text_item'>{trans.menu_sign_out}</span>} /> 
      </ListItemButton>
    </div>
  );
  
  const handleModeChange = (event) => {
     let checked = event.target.checked;
    setDarkModeChecked(event.target.checked);
    if (checked){
       General.storeLightsMode("dark")
    } else {
      General.storeLightsMode("light")
    }
    props.onModeChange(checked);
  }; 

  const openToChangeLanguage  = () => {
    var newLang = "";
    if (userLang == "en"){
      newLang = "el";
    } else {
      newLang = "en";
    }
    setUserLang(newLang);
    General.setUserLocale(newLang);
    window.location.reload();
  }

  return (
      <Box sx={{ display: 'flex' }}>
        <CssBaseline />
        <AppBar position="absolute" open={open}>
          <Toolbar
            sx={{
              pr: '24px', // keep right padding when drawer closed
            }}
          >
            <IconButton
              edge="start"
              color="inherit"
              aria-label="open drawer"
              onClick={toggleDrawer}
              sx={{
                marginRight: '36px',
                ...(open && { display: 'none' }),
              }}
            >
              <MenuIcon />
            </IconButton>
            <Typography
              component="h1"
              variant="h6"
              color="inherit"
              noWrap
              sx={{ flexGrow: 1 }}
            >
             <span className='fontMedium' style={{fontSize:'13px'}}> {trans.dashboard_header_title} | {APP_NAME} v{process.env.REACT_APP_VERSION} </span>
            </Typography>
            {/* <IconButton color="inherit">
              <Badge badgeContent={4} color="secondary">
                <NotificationsIcon />
              </Badge>
            </IconButton> */}
            <FormGroup style={{marginRight:'20px'}}>
              <img src={userLang == "en" ? english_flag : greek_flag} width={"20px"} onClick={() => {openToChangeLanguage()}}></img>
            </FormGroup>
            <FormGroup style={{marginLeft:'8px'}}>
              <FormControlLabel  control={<Switch size='small' checked={darkModeChecked} onChange={handleModeChange} />} label={<span className='fontBook' style={{fontSize:'14px'}}>{General.getLightsMode() == "dark" ? trans.dark_mode : trans.light_mode}</span>}/>
            </FormGroup>
          </Toolbar>
        </AppBar>
        <Drawer variant="permanent" open={open}>
          <Toolbar
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'flex-end',
              px: [1],
            }}>
            <div style={{display:'flex',marginTop:'5px'}}>
                <div>
                    <AccountBoxIcon fontSize="14px"/>
                </div>
                &nbsp;
                &nbsp;
                <div className='fontBook' style={{fontSize:'14px'}} >
                    {userInfoData.userInfo.firstname} {userInfoData.userInfo.lastname}
                </div>
            </div>  
              
            <IconButton onClick={toggleDrawer}>
              <ChevronLeftIcon />
            </IconButton>
         
          </Toolbar>
          <Divider />
          <List>{mainListItems}</List>
          <Divider />
          <List>{secondaryListItems}</List>
        </Drawer>
          <Box
            component="main"
            sx={{
          
              flexGrow: 1,
              height: '100vh',
              overflow: 'auto',
            }}
          >
          <Toolbar />
          <div>
              <Routes>
                <Route path="/" element={<Dashboard />}></Route>
                <Route path='catalog-import' element={userTypeStoreUser == false ?  <CatalogImport/>: <Dashboard/>}></Route>
                <Route path='settings' element={userTypeStoreUser == false ? <InitSettings/> : <Dashboard/>}></Route>
                <Route path='graphics' element={userTypeStoreUser == false ?  <InitGraphics/> : <Dashboard/>}></Route>
                <Route path='users' element={<Users/>}></Route>
                <Route path='stores' element={<StoresList/>}></Route>
                <Route path='userreviews' element={<UserReviewsList/>}></Route>
                <Route path='sidemenu' element={userTypeStoreUser == false ?  <SideMenuList/> : <Dashboard/>}></Route>
                <Route path='catalog-list' element={<CatalogMenuList/>}></Route>
                <Route path='catalog-offers' element={<CatalogOffersList/>}></Route>
                <Route path='catalog-extras' element={<CatalogExtrasList/>}></Route>
                <Route path='orders' element={<OrdersList/>}></Route>
                <Route path='orders/:passedOrderId' element={<OrdersList/>}></Route>  
                <Route path='coupons' element={<CouponsList/>}></Route>              
                <Route path='notifications' element={<NotificationsListPage/>}></Route>              
              </Routes>
            </div>
         
        </Box>
      </Box>
  );
}

export default Home;


