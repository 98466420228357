import React from 'react';
import { useDispatch, useSelector } from "react-redux";
import { TextField, IconButton, Button } from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import { DatePicker, TimePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';

const WorkingHoursDay = ({ day, regularPeriods, exceptions, onPeriodChange, onExceptionChange }) => {

  const settingsData = useSelector(({ settings }) => settings.settingsData);
  const trans = settingsData.trans;

  // Handler to add a new period
  const handleAddPeriod = () => {
    const newPeriod = { start: new Date(), end: new Date() };
    onPeriodChange([...regularPeriods, newPeriod]);
  };

  // Handler to remove a specified period
  const handleRemovePeriod = (index) => {
    const updatedPeriods = regularPeriods.filter((_, i) => i !== index);
    onPeriodChange(updatedPeriods);
  };

  // Handler to add a new exception
  const handleAddException = () => {
    const newException = { date: new Date(), start: new Date(), end: new Date() };
    onExceptionChange([...exceptions, newException]);
  };

  // Handler to remove a specified exception
  const handleRemoveException = (index) => {
    const updatedExceptions = exceptions.filter((_, i) => i !== index);
    onExceptionChange(updatedExceptions);
  };

  return (
    <div>
      <h3>{day}</h3>
      <div>
        <h5 style={{marginBottom:'16px'}}>{trans.working_hours_regular_hours}</h5>
        {regularPeriods.map((period, index) => (
          <div key={index} style={{ display: 'flex', alignItems: 'center', gap: '8px',marginBottom:'10px' }}>
            <LocalizationProvider dateAdapter={AdapterDateFns}>
              <TimePicker
                label={trans.working_hours_start_time}
                value={period.start}
                onChange={(newValue) => {
                  const updatedPeriods = [...regularPeriods];
                  updatedPeriods[index] = { ...updatedPeriods[index], start: newValue };
                  onPeriodChange(updatedPeriods);
                }}
                renderInput={(params) => <TextField {...params} />}
              />
              <TimePicker
                label={trans.working_hours_end_time}
                value={period.end}
                onChange={(newValue) => {
                  const updatedPeriods = [...regularPeriods];
                  updatedPeriods[index] = { ...updatedPeriods[index], end: newValue };
                  onPeriodChange(updatedPeriods);
                }}
                renderInput={(params) => <TextField {...params} />}
              />
            </LocalizationProvider>
            <IconButton onClick={() => handleRemovePeriod(index)} size="large">
              <DeleteIcon />
            </IconButton>
          </div>
        ))}
        <Button variant="outlined" onClick={handleAddPeriod}>{trans.working_hours_add_hours}</Button>
      </div>
      <br/>
      <div>
      <h5 style={{marginBottom:'16px'}}>{trans.working_hours_exceptions_hours}</h5>
        {exceptions.map((exception, index) => (
          <div key={index} style={{ display: 'flex', alignItems: 'center', gap: '8px' ,marginBottom:'10px'  }}>
            <LocalizationProvider dateAdapter={AdapterDateFns}>
              <DatePicker
                label={trans.working_hours_exception_date}
                value={exception.date}
                onChange={(newValue) => {
                  const updatedExceptions = [...exceptions];
                  updatedExceptions[index] = { ...updatedExceptions[index], date: newValue };
                  onExceptionChange(updatedExceptions);
                }}
                renderInput={(params) => <TextField {...params} />}
              />
              <TimePicker
                label={trans.working_hours_start_time}
                value={exception.start}
                onChange={(newValue) => {
                  const updatedExceptions = [...exceptions];
                  updatedExceptions[index] = { ...updatedExceptions[index], start: newValue };
                  onExceptionChange(updatedExceptions);
                }}
                renderInput={(params) => <TextField {...params} />}
              />
              <TimePicker
                label={trans.working_hours_end_time}
                value={exception.end}
                onChange={(newValue) => {
                  const updatedExceptions = [...exceptions];
                  updatedExceptions[index] = { ...updatedExceptions[index], end: newValue };
                  onExceptionChange(updatedExceptions);
                }}
                renderInput={(params) => <TextField {...params} />}
              />
            </LocalizationProvider>
            <IconButton onClick={() => handleRemoveException(index)} size="large">
              <DeleteIcon />
            </IconButton>
          </div>
        ))}
        <Button variant="outlined" onClick={handleAddException}>{trans.working_hours_add_exception}</Button>
      </div>
    </div>
  );
};

export default WorkingHoursDay;
