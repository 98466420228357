import React, { useState,useEffect } from 'react';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import Container from '@mui/material/Container';
import ImportExportIcon from '@mui/icons-material/ImportExport';
import { useDispatch, useSelector } from "react-redux";
import PageTitleDesc from '../global/pageTitleDesc/PageTitleDesc';
import { postCatalogImportData } from '../../redux/scripts/action';
import { CATALOG_IMPORT_DATA } from '../../redux/actionTypes';
import { toast } from 'react-toastify';
import HashLoader from "react-spinners/HashLoader";
import StoresPicker from '../stores/StoresPicker';


const CatalogImport = () => {
  const settingsData = useSelector(({settings}) => settings.settingsData);
  const catalogImportData = useSelector(({scripts}) => scripts.catalogImportData);
  const dispatch = useDispatch();
  const trans = settingsData.trans;


  const [textData, setTextData] = useState("");
  const [isTextPasted, setIsTextPasted] = useState(false);

  const [isImportingData, setIsImportingData] = useState(false);
  const [storeItems, setStoreItems] = useState(null);

  const handlePaste = async () => {
    try {
      const text = await navigator.clipboard.readText();
      setTextData(text);
      setIsTextPasted(true);
    } catch (error) {
      console.error('Error reading from clipboard', error);
      setIsTextPasted(false);
    }
  };

  useEffect(() => {
    if (catalogImportData != null) {
      if (catalogImportData.status) {
        toast.dismiss();
        toast.success(catalogImportData.message, {
          position: toast.POSITION.TOP_RIGHT
        });
      } else {
        toast.dismiss();
        toast.error(catalogImportData.message, {
          position: toast.POSITION.TOP_RIGHT
        });
      }
      setIsImportingData(false);
      dispatch({ type: CATALOG_IMPORT_DATA, payload: null });
    }
  }, [catalogImportData, dispatch]);

  const onCatalogImport = (e) => {
    e.preventDefault();
    if (textData && storeItems != null) {

      const escapedHtmlString = JSON.stringify(textData.replace(/"/g, "'"));
      setIsImportingData(true);

      var storesIds = [storeItems];


      var postData = {
        selectedStores: storesIds,
        scrapeData: escapedHtmlString
      };
      
      
      dispatch(postCatalogImportData(postData));
    }
  };

  return (
    <Container maxWidth="xl" sx={{ mt: 4, mb: 4 }}>
      <Grid container spacing={1}>
        <Grid item xs={12}>
          <Paper sx={{ p: 2, display: 'flex', flexDirection: 'column' }}>
            <PageTitleDesc title={trans.menu_catalog_import} desc={trans.menu_catalog_import_desc}/>
          </Paper>
        </Grid>
        <Grid item xs={12}>
          <Paper sx={{ p: 2, display: 'flex', flexDirection: 'column' }}>
            <PageTitleDesc title={trans.menu_catalog_efood_import_title} desc={trans.menu_catalog_efood_import_desc} hasGuide={false}/>
           
           <br/>

            <Grid item xs={12}>
              <StoresPicker onStoreSelect={(selectedStores) => {
                setStoreItems(selectedStores)
              }} multiple={false} />
            </Grid>
          
            <br/>
            <br/>
           
            <Grid container spacing={1} alignItems="center">

             
              <Grid item>
                <div>
                  <br/>
                  {isTextPasted ? <div>{trans.menu_catalog_efood_text_pasted}</div> : 
                  
                  <Button onClick={handlePaste} variant="outlined">Paste from Clipboard</Button>}
                    <br/>
                </div>
              
              </Grid>
              {isTextPasted ? <Grid item md={12} sx={{ display: 'flex', justifyContent: 'center' }}>
               {isImportingData ?  <div className='_flex_column'> 
           <div>{trans.catalog_import_wait}</div> 
           <br/>
          <HashLoader
                  color={settingsData.initData.app_color}
                  loading={isImportingData}
                  size={50}
                  aria-label="Loading Spinner"
                  data-testid="loader"
                />
          </div>  : <Button  startIcon={<ImportExportIcon />} onClick={onCatalogImport} variant="contained">    {trans.import_catalog_button}</Button> }

              </Grid> : null}
              <br/>
            </Grid>
          </Paper>
        </Grid>
      </Grid>
    </Container>
  );
};

export default CatalogImport;
