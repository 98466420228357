import {
  POST_UPLOAD_IMAGE,
  POST_UPLOAD_FILE
  } from '../actionTypes';
  import axios from '../../helpers/Api'
  import * as General from '../../helpers/General'
  import * as Store from '../../helpers/Store';
  import * as Const from '../../constants';
import { DEBUG_MODE } from '../../constants';
  
 
  
    
export const postUploadImage = (formData) => async dispatch => {

  const customHeaders = {
    ...axios.defaults.headers.common,
    'Content-Type': 'application/x-www-form-urlencoded',
  };

  //Get user locale of the machine he is on
  const userLocale = General.getLocale();
  formData.append('langLoc', userLocale);

  console.log( formData.get("image_file"));
  const response = await axios.post(`upload/image`,formData,{headers: customHeaders});

  if (DEBUG_MODE){
    console.log(response.data);
  }

  dispatch({type: POST_UPLOAD_IMAGE, payload: response.data});
};

export const postUploadSingleFile = (formData) => async dispatch => {

  const customHeaders = {
    ...axios.defaults.headers.common,
    'Content-Type': 'application/x-www-form-urlencoded',
  };

  //Get user locale of the machine he is on
  const userLocale = General.getLocale();
  formData.append('langLoc', userLocale);

  const response = await axios.post(`upload/single-file`,formData,{headers: customHeaders});

  if (DEBUG_MODE){
    console.log(response.data);
  }

  dispatch({type: POST_UPLOAD_FILE, payload: response.data});
};
