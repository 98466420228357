import {
  POST_COUPONS_LIST_ALL,
  POST_COUPONS_ADD_EDIT,
  POST_COUPONS_DELETE,
  POST_COUPONS_SINGLE
  } from '../actionTypes';
  import axios from '../../helpers/Api'
  import * as General from '../../helpers/General'
import { DEBUG_MODE } from '../../constants';
  
export const postCouponsListAll = (postData) => async dispatch => {
  //Get user locale of the machine he is on
  const userLocale = General.getLocale();
  postData.langLoc = userLocale;
  postData.fromAdmin = true;

  const response = await axios.post(`coupon/list`,postData);

  if (DEBUG_MODE){
    console.log(response.data);
  }

  dispatch({type: POST_COUPONS_LIST_ALL, payload: response.data});
};


export const postCouponsListAddEdit = (postData) => async dispatch => {
  //Get user locale of the machine he is on
  const userLocale = General.getLocale();
  postData.langLoc = userLocale;
  postData.fromAdmin = true;

  const response = await axios.post(`coupon/addEdit`,postData);

  if (DEBUG_MODE){
    console.log(response.data);
  }

  dispatch({type: POST_COUPONS_ADD_EDIT, payload: response.data});
};

export const postCouponsSingle = (postData) => async dispatch => {
  //Get user locale of the machine he is on
  const userLocale = General.getLocale();
  postData.langLoc = userLocale;
  postData.fromAdmin = true;

  const response = await axios.post(`coupon/single`,postData);

  if (DEBUG_MODE){
    console.log(response.data);
  }

  dispatch({type: POST_COUPONS_SINGLE, payload: response.data});
};


export const postCouponsDelete = (postData) => async dispatch => {
  //Get user locale of the machine he is on
  const userLocale = General.getLocale();
  postData.langLoc = userLocale;
  postData.fromAdmin = true;

  const response = await axios.post(`coupon/delete`,postData);

  if (DEBUG_MODE){
    console.log(response.data);
  }

  dispatch({type: POST_COUPONS_DELETE, payload: response.data});
};
