import {
  POST_ORDERS_ALL,
  POST_ORDERS_SINGLE,
  POST_ORDERS_ANSWER,
} from "../actionTypes";

const INIT_STATE = {
  ordersListData: null,
  singleOrderData: null,
  orderAnswerData: null,
};

const reducer = (state = INIT_STATE, action) => {
  switch (action.type) {
    case POST_ORDERS_ALL:
      return {
        ...state,
        ordersListData: action.payload,
      };
    case POST_ORDERS_SINGLE:
      return {
        ...state,
        singleOrderData: action.payload,
      };
    case POST_ORDERS_ANSWER:
      return {
        ...state,
        orderAnswerData: action.payload,
      };
    default:
      return state;
  }
};

export default reducer;
