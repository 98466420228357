export const getOrderStateText = (orderStates,trans, orderStateType) => {
    if (orderStateType == orderStates.PENDING) {
      return trans.order_state_pending ?? "";
    } else if (orderStateType == orderStates.CONFIRMED) {
      return trans.order_state_confirmed ?? "";
    } else if (orderStateType == orderStates.PREPARED) {
      return trans.order_state_prepared ?? "";
    } else if (orderStateType == orderStates.DELIVERED) {
      return trans.order_state_delivered ?? "";
    } else if (orderStateType == orderStates.CANCELLED) {
      return trans.order_state_cancelled ?? "";
    }
  
    // Optionally, handle the case where orderStateType doesn't match any known state
    // You could return a default message or just an empty string
    return "Unknown State";  // Or return "" if you prefer not to show any message
}
  
export const getPaymentMethodString = (paymentOptions,trans, paymentOption) => {
    if (paymentOption == paymentOptions.CASH) {
        return trans.payment_option_cash;
      } else if (paymentOption == paymentOptions.ONLINE ||
          paymentOption == paymentOptions.STRIPE) {
        return trans.payment_option_online;
      } else if (paymentOption == paymentOptions.POS) {
        return trans.payment_option_pos;
      } else if (paymentOption == paymentOptions.POS_IN_HOUSE) {
        return trans.payment_option_pos_in_house;
      }
    
      return null;
}

export const getEuroSymbol = () => {
    return "€";
  }
  

export const  getOrderMethodName = (orderMethodsList,orderDeliveryMethod) => {
  var textName = "";
  orderMethodsList?.forEach((element)  => {
    if (element.order_method_type == orderDeliveryMethod) {
      textName = element.method_text ?? "";
    }
  });
  return textName;
}  