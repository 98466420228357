import "./langPicker.css";
import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import { getDefaultLanguage } from "../../utils/languages";

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

const LangPicker = (props) => {
  //SELECTORS
  const settingsData = useSelector(({ settings }) => settings.settingsData);
  //VARIABLES
  const dispatch = useDispatch();
  const trans = settingsData.trans;

  //HOOKS VARIABLES
  const [langArr, setLangArr] = useState([]);
  const [selectedLang, setSelectedLang] = useState(
    getDefaultLanguage(settingsData.app_languages)
  );

  //HOOKS
  useEffect(() => {
    var langs = [];

    settingsData.app_languages.forEach(element => {
      if (element.selected) {
        langs.push(element);
      }
    });

    setLangArr(langs);

    //ONLOAD
  }, []);

  //FUNCTIONS
  const handleLanguageChange = (item) => {
    setSelectedLang(item);
    props.onLangSelectedChanged(item);
  };

  return langArr.length > 1 ? (
    <div>
      <h4>{trans.select_language}</h4>
      <div className="_lang_picker_container">
        {langArr.map((lang) => {
          return (
            <div
            key={`lang_picker_item_${lang.value}_${lang.id}`}
              onClick={() => {
                handleLanguageChange(lang);
              }}
              className={`_lang_picker_item ${
                selectedLang.id == lang.id ? "active" : ""
              }`}
            >
              <img
                src={lang.icon}
                alt={lang.name}
                width={25}
                height={25}
                className="_lang_picker_icon"
              />
              <span className="_lang_picker_text">{lang.name}</span>
            </div>
          );
        })}
      </div>
    </div>
  ) : null;
};

export default LangPicker;
