import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import Grid from '@mui/material/Grid';
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
import Button from '@mui/material/Button';
import { toast } from 'react-toastify';
import { POST_USER_CHANGE_ACCESS, POST_USER_EDIT_USER_TYPES, POST_USER_SINGLE } from '../../redux/actionTypes';
import { getUserStoreIds, postChangeUserAccess, postChangeUserTypes, postUserSingle } from '../../redux/auth/action';
import StoresPicker from '../stores/StoresPicker';
import { showResponseMessage } from '../../utils/alerts/MyToastUtil';
import { USER_TYPES } from '../../constants';

const EditUserTypes = (props) => {
  const settingsData = useSelector(({ settings }) => settings.settingsData);
  const changeUserTypesData = useSelector(({ auth }) => auth.changeUserTypesData);
  const userSingleData = useSelector(({ auth }) => auth.userSingleData);

  const { editItem } = props;

  const [isRequesting, setIsRequesting] = useState(false);
  const [userItem, setUserItem] = useState(null);
  const [userTypes, setUserTypes] = useState([]);
  const [selectedRoles, setSelectedRoles] = useState([]);
  const [storeItems, setStoreItems] = useState([]);

  const dispatch = useDispatch();
  const trans = settingsData.trans;

  useEffect(() => {
    if (editItem != null) {
      setUserItem(editItem);
      setSelectedRoles(editItem.usertypes.map((ut) => ut.type_id));

      var preselectdstores = getUserStoreIds(editItem);

      setStoreItems(preselectdstores);
    }
  }, [editItem]);

  useEffect(() => {
    if (settingsData != null) {
      setUserTypes(settingsData.userTypesList);
    }
  }, [settingsData]);

  useEffect(() => {
    if (changeUserTypesData != null) {
      showResponseMessage(changeUserTypesData);
      props.onTypesUpdated();
      dispatch({type: POST_USER_EDIT_USER_TYPES, payload: null});
    }
  }, [changeUserTypesData]);

  

  const handleRoleChange = (event) => {
    const role = event.target.name;
    const checked = event.target.checked;

    if (checked) {
      setSelectedRoles([...selectedRoles, role]);
    } else {
      setSelectedRoles(selectedRoles.filter((r) => r !== role));
    }
  };

  const isStoreUserSelected = selectedRoles.includes("STORE_USER");


  const onSubmit = (e) => {
    e.preventDefault();

    if (isRequesting) {
      return;
    }

    // setIsRequesting(true);

    const postData = {
      userId: userItem.id,
      selectedRoles,
      storeItems
    }

    console.log(JSON.stringify(postData));

    dispatch(postChangeUserTypes(postData));
  };

  return (
    <div>
      <Grid container spacing={2} alignItems="center">
        <Grid item xs={12}>
          {userTypes.map((userType) => (
            <FormControlLabel
              key={userType.user_option_type}
              control={
                <Checkbox
                  checked={selectedRoles.includes(userType.user_option_type)}
                  onChange={handleRoleChange}
                  name={userType.user_option_type}
                />
              }
              label={userType.user_option_value}
            />
          ))}
        </Grid>

        {isStoreUserSelected && (
          <Grid item xs={12}>
            <StoresPicker
            preSelectedStoreIds={storeItems}
              onStoreSelect={(selectedStores) => {
                setStoreItems(selectedStores);
              }}
              multiple={false}
            />
          </Grid>
        )}

        <Grid container justifyContent="center">
          <Grid item>
            <Button
              style={{ marginTop: '10px', marginBottom: '10px' }}
              variant="contained"
              disabled={isRequesting}
              onClick={onSubmit}
            >
              {trans.submit_btn}
            </Button>
          </Grid>
        </Grid>
      </Grid>
    </div>
  );
};

export default EditUserTypes;
