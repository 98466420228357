import React, { useState,useEffect } from 'react';
import TextField from '@mui/material/TextField';
import {useSelector,useDispatch} from "react-redux";
import Grid from '@mui/material/Grid';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import Autocomplete, {
  createFilterOptions
} from '@mui/material/Autocomplete';
import { Checkbox } from '@mui/material';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import { postUsersAll } from '../../redux/users/action';
const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;
const filter = createFilterOptions();


const EditNotificationItem =(props)=> {

    //SELECTORS
    const settingsData = useSelector(({settings}) => settings.settingsData);
    const usersData = useSelector(({users}) => users.usersData);


    const {editItem} = props;
    
    //HOOKS VARIABLES
    const [title,setTitle] = useState("");
    const [body,setBody] = useState("");
    const [sendToAll,setSendToAll] = useState(true);
    const [usersArr,setUsersArr] = useState([]);
    const [usersFilter, setUsersFilter] = useState([]);
    const [searchUser,setSearchUser] = useState(null);



    //VARIABLES
    const dispatch = useDispatch();
    const trans = settingsData.trans;


 
    //HOOKS
    useEffect(() => {

      getUserData();

    }, []);

    const getUserData = () => {
      let postData = {
        hasPaginate: false,
        searchKeyword: searchUser,
      };

      dispatch(postUsersAll(postData)); 
    }

    useEffect(() => {
      if (props.toGetData){

          let postData = makePostItem();

          if (editItem != null){
            postData.itemId = editItem.id;
          }

          props.onSubmitData(postData);
        
      }
    }, [props.toGetData]);

    useEffect(() => {
      if (usersData != null){
         setUsersArr(usersData.users);
        
      } 
    },[usersData]);

    const makePostItem = () => {
      
      var push_type = "push_simple";
      var push_type_id = "";
      var for_all = sendToAll;

      let usersIds = [];
      if (props.from != null && (props.from == "contracts" || props.from == "offers") ){
        if (props.fromUserId != null && props.fromUserId != ""){
          usersIds.push(props.fromUserId);
          for_all = false;
        } 
        push_type = props.from == "contracts" ? "push_contract" : "push_offer";
        push_type_id = props.fromItemId;
      } else {
        usersFilter.forEach(element => {
          usersIds.push(element.id);
        });
      }
 
      let postData = {
        "title": title,
        "desc": body,
        "users": usersIds,
        "push_type": push_type,
        "push_type_id":push_type_id,
        "for_all": for_all
      };

      console.log(postData);

       return postData;
    }


    useEffect(() => {
      if (editItem != null){
          setTitle(editItem.title);
          setBody(editItem.body);
      } else {
        clearData();
      }
    },[editItem]);


    useEffect(() => {
        if (props.toClearData){
            clearData();
        }
    }, [props.toClearData]);

    //FUNCTIONS
    const clearData = () => {
        setTitle("");
        setSendToAll(true);
        setBody("");

        if (props.from != null && props.from != "" && (props.from == "contracts" || props.from == "offers") ){
          setTitle(props.fromTitle);
          setBody(props.fromDesc);
        }
    }

    const handleUsersChange = (e,selectedValues) => {
      setUsersFilter(selectedValues);
    }

    const handleSearchInput = (value) => {
      setSearchUser(value);
    }
  
  
    useEffect(() => {
      if (searchUser != null){
        getUserData();
      } 
    },[searchUser]);
  


    //UI
    return (
      <div>
        <br/>
        <Grid container spacing={3} alignItems="center">

      

            <Grid item xs={12}>
              <TextField
                    margin="dense"
                    id="title"
                    label={trans.table_head_push_title}
                    value={title}
                    type="text"
                    onChange={(e)=> {setTitle(e.target.value)}}
                    fullWidth
                    variant="standard" />
            </Grid>

            <br/>

  
            <Grid item xs={12}>
              <TextField
                    margin="dense"
                    id="body"
                    label={trans.table_head_push_body}
                    value={body}
                    type="text"
                    onChange={(e)=> {setBody(e.target.value)}}
                    fullWidth
                    variant="standard" />
            </Grid>

            <br/>


        {props.from != null && props.from == "contracts" ? null : 
            <Grid item xs={12} >
              <FormControl fullWidth>
                  <InputLabel id="visible_state">{trans.table_head_send_to_all}</InputLabel>
                  <Select
                          labelId="visible_state"
                          id="visible"
                          value={sendToAll}
                          label={trans.table_head_send_to_all}
                          onChange={(e) => setSendToAll(e.target.value)}>
                          <MenuItem key={`table_head_send_to_all_true`} value={true}>{trans.status_true}</MenuItem>
                          <MenuItem key={`table_head_send_to_all_false`} value={false}>{trans.status_false}</MenuItem>
                  </Select>
              </FormControl>
            </Grid> }

            <br/>

        
        {!sendToAll ? 
            <Grid item xs={12} >
              <Autocomplete
                    multiple
                    fullWidth
                    id="checkboxes-users"
                    options={usersArr}
                    disableCloseOnSelect
                    value={usersFilter}
                    onChange={(e,value) => handleUsersChange(e,value)}
                    getOptionLabel={(option) => option.fullname}
                    onInputChange={(event, newInputValue) => {
                      handleSearchInput(newInputValue);
                    }}
                    isOptionEqualToValue={(option, value) => option.id == value.id}
                    renderOption={(props, option, { selected }) => (
                      <li  {...props} key={option.id}>
                        <Checkbox
                          icon={icon}
                          checkedIcon={checkedIcon}
                          style={{ marginRight: 8 }}
                          checked={selected}
                        />
                        {option.fullname}
                      </li>
                    )}
                    
                    renderInput={(params) => (
                      <TextField {...params} label={trans.users_label} placeholder={trans.add_user}  />
                    )}
              />
            </Grid> : null }

        
        </Grid>
      </div>
    );
}

export default EditNotificationItem;
