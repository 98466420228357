import {
  POST_STORES_ALL,
  POST_STORES_ADD,
  POST_STORES_EDIT,
  POST_STORES_DELETE,
  POST_STORES_UPDATE_LOGO,
  POST_STORES_ZONE_LIST,
  POST_STORES_ZONE_ADD,
  POST_STORES_ZONE_EDIT,
  POST_STORES_ZONE_DELETE,
  POST_STORES_UPDATE_ORDER_DETAILS,
  POST_STORES_LIST_WORKING_HOURS,
  POST_STORES_UPDATE_WORKING_HOURS,
  POST_STORES_CONTACT_OPTIONS_LIST,
  POST_STORES_CONTACT_OPTIONS_ADD_EDIT,
  POST_STORES_CONTACT_OPTIONS_DELETE,
} from "../actionTypes";

const INIT_STATE = {
  storesData: null,
  addStoreData: null,
  editStoreData: null,
  deleteStoreData: null,
  updateStoreLogoData: null,
  zoneListData: null,
  addZoneData: null,
  editZoneData: null,
  deleteZoneData: null,
  updateOrderDetailsData: null,
  workingHoursData: null,
  updateWorkingHoursData: null,
  contactOptionsData: null,
  addEditContactOptionsData: null,
  deleteContactOptionsData: null,
};

const reducer = (state = INIT_STATE, action) => {
  switch (action.type) {
    case POST_STORES_ALL:
      return {
        ...state,
        storesData: action.payload,
      };
    case POST_STORES_ADD:
      return {
        ...state,
        addStoreData: action.payload,
      };
    case POST_STORES_EDIT:
      return {
        ...state,
        editStoreData: action.payload,
      };
    case POST_STORES_DELETE:
      return {
        ...state,
        deleteStoreData: action.payload,
      };
    case POST_STORES_UPDATE_LOGO:
      return {
        ...state,
        updateStoreLogoData: action.payload,
      };
    case POST_STORES_ZONE_LIST:
      return {
        ...state,
        zoneListData: action.payload,
      };
    case POST_STORES_ZONE_ADD:
      return {
        ...state,
        addZoneData: action.payload,
      };
    case POST_STORES_ZONE_EDIT:
      return {
        ...state,
        editZoneData: action.payload,
      };
    case POST_STORES_ZONE_DELETE:
      return {
        ...state,
        deleteZoneData: action.payload,
      };
    case POST_STORES_UPDATE_ORDER_DETAILS:
      return {
        ...state,
        updateOrderDetailsData: action.payload,
      };
    case POST_STORES_LIST_WORKING_HOURS:
      return {
        ...state,
        workingHoursData: action.payload,
      };
    case POST_STORES_UPDATE_WORKING_HOURS:
      return {
        ...state,
        updateWorkingHoursData: action.payload,
      };
    case POST_STORES_CONTACT_OPTIONS_LIST:
      return {
        ...state,
        contactOptionsData: action.payload,
      };
    case POST_STORES_CONTACT_OPTIONS_ADD_EDIT:
      return {
        ...state,
        addEditContactOptionsData: action.payload,
      };
    case POST_STORES_CONTACT_OPTIONS_DELETE:
      return {
        ...state,
        deleteContactOptionsData: action.payload,
      };

    default:
      return state;
  }
};

export default reducer;
