import React from 'react';
import './NoAppIdComponent.css'; // Import the CSS for styling
import { appiko_logo_black } from '../../../images';
import CopyRightWidget from '../copyrightwidget/CopyrightWidget';


const NoAppIdComponent = () => {
  return (
    <div className="full-screen-container">

        <div className='_flex_column'>
            <img src={appiko_logo_black} width="200px" alt="logo" style={{margin:"10px"}}/>
            <div className="message fontBook">Δεν βρέθηκε εφαρμογή. Χρησιμοποιήστε τη σωστή διαδρομή.</div>
            <br/>
            <CopyRightWidget/>
        </div>

 

    </div>
  );
};

export default NoAppIdComponent;
