import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Dialog,
  DialogContent,
  DialogTitle,
  Button,
  List,
  ListItem,
  ListItemText,
  Grid,
  IconButton,
  TextField,
  InputLabel,
  MenuItem,
  FormControl,
  Select,
  Chip,
  OutlinedInput,
} from "@mui/material";

import CloseIcon from "@mui/icons-material/Close";
import { toast } from "react-toastify";
import { getDefaultLanguage } from "../../utils/languages";
import LangPicker from "../global/LangPicker";
import MultiLangTextField from "../global/textFields/MultilanguageTextField";
import StoresPicker from "../stores/StoresPicker";
import { postCatalogCategoryAddEdit, postCatalogCategorySingle } from "../../redux/catalog/action";
import CatalogCategoriesPicker from "./categories/CatalogCategoriesPicker";
import MyStatusPicker from "../global/statusPicker/MyStatusPicker";
import DatePickerComponent from "../global/datePicker/DatePickerComponent";
import { POST_CATALOG_CATEGORY_ADD_EDIT } from "../../redux/actionTypes";
import { showResponseMessage } from "../../utils/alerts/MyToastUtil";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

const AddCatalogCategory = (props) => {
  const settingsData = useSelector(({ settings }) => settings.settingsData);
  const addEditCatalogCategoryData = useSelector(({ catalog }) => catalog.addEditCatalogCategoryData);
  const singleCatalogCategoryData = useSelector(({ catalog }) => catalog.singleCatalogCategoryData);
  
 // const updateOrderDetailsData = useSelector(({ stores }) => stores.updateOrderDetailsData);

  //Variables
  const dispatch = useDispatch();
  const trans = settingsData.trans;


  //Hooks
  const [categoryItem, setCategoryItem] = useState(null);
  const [names, setNames] = useState([]);
  const [descriptions, setDescriptions] = useState([]);
  const [categoryStores, setCategoryStores] = useState([]);
  const [images, setImages] = useState([]);
  const [master_categories, setMasterCategories] = useState([]);
  const [featured, setFeatured] = useState(false);
  
  const [status, setStatus] = useState(true);
  const [disabled, setDisabled] = useState(false);
  const [disabled_until, setDisabledUntil] = useState(null);
  const [offer_type, setOfferType] = useState(false);
  const [offer_type_until, setOfferTypeUntil] = useState(null);
  const [selectedLang, setSelectedLang] = useState(getDefaultLanguage(settingsData.app_languages));

  useEffect(() => {
    if (props.editCategoryItem != null) {
      getCatalogCategory(props.editCategoryItem.id);
    }
  }, [props.editCategoryItem]);

  useEffect(() => {
    if (singleCatalogCategoryData != null) {
     setNames(singleCatalogCategoryData.names);
     setDescriptions(singleCatalogCategoryData.descriptions);

     var storesIds = [];

     singleCatalogCategoryData.category_stores.forEach(stroreElement => {
      storesIds.push(stroreElement.id);
     });

     setCategoryStores(storesIds);

     var masterCategoriesIds = [];

     if (singleCatalogCategoryData.master_categories != null){
      singleCatalogCategoryData.master_categories.forEach(master_category => {
        masterCategoriesIds.push(master_category.master_id);
       });
       setMasterCategories(masterCategoriesIds);
     }
    

     setFeatured(singleCatalogCategoryData.featured);
     
     setStatus(singleCatalogCategoryData.status.value);

     setDisabled(singleCatalogCategoryData.disabled);

     setDisabledUntil(singleCatalogCategoryData.disabled_until);

     setOfferType(singleCatalogCategoryData.offer_type);

     setOfferTypeUntil(singleCatalogCategoryData.offer_type_until);
    }
  }, [singleCatalogCategoryData]);

  useEffect(() => {
    if (addEditCatalogCategoryData != null) {

      showResponseMessage(addEditCatalogCategoryData);

     if (addEditCatalogCategoryData.status == true){
      if (props.editCategoryItem != null){
        getCatalogCategory(props.editCategoryItem.id);
      } else {
        handleClose();
      }
   
      props.onDataUpdated()
     }
     dispatch({ type: POST_CATALOG_CATEGORY_ADD_EDIT, payload: null });
     
     
    }
  }, [addEditCatalogCategoryData]);
  

  const getCatalogCategory = (catId) => {
    let postData = {
      id: catId
    };

    dispatch(postCatalogCategorySingle(postData))
  }

  const changeLanguage = (lang) => {
    setSelectedLang(lang);
  };

  const onSaveCatalogCategory = (e) => {
    e.preventDefault();
    var postData = {
        "names": names,
        "descriptions": descriptions,
        "stores": categoryStores,
        "master_categories": master_categories.map((cat) => cat.id),
        "featured": featured,
        "status": {
            "value": status
        },
        "disabled": disabled,
        "disabled_until": disabled_until,
        "offer_type": offer_type,
        "offer_type_until": offer_type_until,
    };

    if (props.editCategoryItem != null){
      postData.catalogCategoryId = props.editCategoryItem.id;
    }

    console.log(postData);

    dispatch(postCatalogCategoryAddEdit(postData));
  }

  const handleClose = () => {
    clearData();
  }

  const clearData = () => {
    setNames([]);
    setDescriptions([]);
    setCategoryStores([]);
    setMasterCategories([]);
    setFeatured(false);
    setStatus(true);
    setDisabled(false);
    setDisabledUntil(null);
    setOfferType(false);
    setOfferTypeUntil(null);
    setSelectedLang(getDefaultLanguage(settingsData.app_languages));
    props.onClose();
  }

  return (
    <Dialog
      open={props.open}
      onClose={() => {handleClose()}}
      fullWidth
      maxWidth="md">
      <DialogTitle>
        <div className="_flex_row_space">
          <div>{props.editCategoryItem != null ? settingsData.trans.catalog_menu_category_edit : settingsData.trans.catalog_menu_category_add}</div>
          <IconButton onClick={() => {handleClose()}}>
            <CloseIcon />
          </IconButton>
        </div>
      </DialogTitle>
      <DialogContent>
       
        <Grid container spacing={2}>
          
          <Grid item xs={12}>
              <LangPicker onLangSelectedChanged={changeLanguage} />
          </Grid>

          <Grid item xs={12}>
            <MultiLangTextField
              label={trans.table_head_category_name}
              currentLang={selectedLang}
              texts={names}
              onTextsChange={(texts) => setNames(texts)}
            />
          </Grid>

          {/* <Grid item xs={12}>
            <MultiLangTextField
              label={trans.table_head_store_description}
              currentLang={selectedLang}
              texts={descriptions}
              onTextsChange={(texts) => setDescriptions(texts)}
            />
          </Grid> */}

          <Grid item xs={12}>
            <StoresPicker
                  onStoreSelect={(selectedStores) => {
                    setCategoryStores(selectedStores);
                  }}
                  multiple={true}
                  autoSelectFirst={false}
                  preSelectedStoreIds={categoryStores}
                  style={{ width: "100%" }} 
            />
          </Grid>

          {/* <Grid item xs={12}>
            <CatalogCategoriesPicker
              onCatalogCategorySelect={(selectedCategories) => {
                setMasterCategories(selectedCategories);
              }}
              multiple={true}
              autoSelectFirst={false}
              preSelectedCatalogCategoryIds={master_categories}
              style={{ width: "100%" }}
              selectedStores={categoryStores}
            />
          </Grid> */}

          <Grid item xs={6}>
            <MyStatusPicker
              label={trans.catalog_category_featured_field}
              status={featured}
              onChange={(value) => setFeatured(value)}
            />
          </Grid>

          <Grid item xs={6}>
            <MyStatusPicker
              label={trans.catalog_category_status_field}
              status={status}
              onChange={(value) => setStatus(value)}
            />
          </Grid>
          
          <Grid item xs={9}>
            <MyStatusPicker
              label={trans.catalog_category_disabled_field}
              status={disabled}
              onChange={(value) => setDisabled(value)}
            />
          </Grid>

          <Grid item xs={3}>
            <DatePickerComponent
              label={trans.catalog_category_disabled_until_field}
              value={disabled_until}
              onChange={(newDate) => {setDisabledUntil(newDate)}}
            />
          </Grid>

          <Grid item xs={9}>
            <MyStatusPicker
              label={trans.catalog_category_offer_type_field}
              status={offer_type}
              onChange={(value) => setOfferType(value)}
            />
          </Grid>

          <Grid item xs={3}>
            <DatePickerComponent
              label={trans.catalog_category_offer_type_until_field}
              value={offer_type_until}
              onChange={(newDate) => {setOfferTypeUntil(newDate)}}
            />
          </Grid>

          <Grid container justifyContent="center" style={{ marginTop: '20px' }}>
            <Grid item xs={12} style={{ display: 'flex', justifyContent: 'center' }}>
              <Button variant="contained" color="primary" onClick={(e) => onSaveCatalogCategory(e)}>
                {trans.button_save_category}
              </Button>
            </Grid>
          </Grid>


        </Grid>
       
      </DialogContent>
    </Dialog>
  );
};

export default AddCatalogCategory;
