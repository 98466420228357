import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Container from "@mui/material/Container";
import {
  Divider,
  Grid,
  Button,
  Paper,
  List,
  ListItem,
  ListItemText,
  ListItemSecondaryAction,
  IconButton,
  Switch,
  Box,
  TextField,
} from "@mui/material";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";

import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import AddIcon from "@mui/icons-material/Add";
import PageTitleDesc from "../global/pageTitleDesc/PageTitleDesc";

import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline"; // Importing Add Icon
import { postCatalogOffersListAll } from "../../redux/catalog-offers/action";
import StoresPicker from "../stores/StoresPicker";
import { getLocalDate } from "../../helpers/General";
import AddEditCatalogOffer from "./AddEditCatalogOffer";
import EditCatalogOfferProducts from "./EditCatalogOfferProducts";
import InventoryIcon from "@mui/icons-material/Inventory";

const CatalogOffersList = () => {
  //Selectors
  const settingsData = useSelector(({ settings }) => settings.settingsData);
  const offersListData = useSelector(({ offers }) => offers.offersListData);

  //Variables
  const dispatch = useDispatch();
  const trans = settingsData.trans;

  //Hooks
  const [storeItems, setStoreItems] = useState([]);
  const [offersList, setOffersList] = useState([]);

  const [isAddEditDialogOpen, setIsAddEditDialogOpen] = useState(false);
  const [isProductsEditDialogOpen, setIsProductsEditDialogOpen] =
    useState(false);

  const [addEditOfferId, setAddEditOfferIdd] = useState(null);

  useEffect(() => {
    if (storeItems){
      reloadData();
    }
 
  }, [storeItems]);

  useEffect(() => {
    if (offersListData) {
      setOffersList(offersListData.product_offers);
    }
  }, [offersListData]);

  const reloadData = () => {
    dispatch(postCatalogOffersListAll({storeItems}));
  };

  const handleAddOffer = () => {
    setIsAddEditDialogOpen(true);
    setAddEditOfferIdd(null);
  };

  // Handle edit offer
  const handleEditOffer = (offer) => {
    setIsAddEditDialogOpen(true);
    setAddEditOfferIdd(offer.id);
  };

  // Handle delete offer
  const handleDeleteOffer = (offer) => {
    console.log("Delete offer:", offer);
  };

  // Add a method to close the dialog
  const handleCloseDialog = () => {
    setIsAddEditDialogOpen(false);
    setAddEditOfferIdd(null);
  };

  // Add a method to close the dialog
  const handleCloseDialogEditProcuts = () => {
    setIsProductsEditDialogOpen(false);
    setAddEditOfferIdd(null);
  };

  const handleEditProducts = (offer) => {
    setIsProductsEditDialogOpen(true);
    setAddEditOfferIdd(offer.id);
  };

  return (
    <Container maxWidth="xl" sx={{ mt: 4, mb: 4 }}>
      <Grid container spacing={1}>
        <Grid item xs={12}>
          <Paper
            sx={{
              p: 2,
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              width: "100%",
            }}
          >
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                  }}
                >
                  <Box sx={{ display: "flex", alignItems: "center" }}>
                    <PageTitleDesc
                      title={settingsData.trans.menu_catalog_offers}
                      desc={settingsData.trans.menu_catalog_offers_desc}
                    />
                  </Box>
                  <Button
                    startIcon={<AddCircleOutlineIcon />}
                    onClick={handleAddOffer}
                    color="primary"
                    variant="contained"
                    sx={{ minWidth: 160 }} // You can adjust this value as needed
                  >
                    {trans.add_offer_button}
                  </Button>
                </div>
              </Grid>
              <Grid item xs={12}>
                <StoresPicker
                  onStoreSelect={(selectedStores) => {
                    setStoreItems(selectedStores);
                  }}
                  multiple={false}
                  autoSelectFirst={true}
                />
              </Grid>
            </Grid>
          </Paper>

          <br />

          <Grid item xs={12}>
            <Paper sx={{ p: 2 }}>
              <TableContainer>
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell>{trans.table_offer_image}</TableCell>
                      <TableCell>{trans.table_offer_name}</TableCell>
                      <TableCell>{trans.table_offer_description}</TableCell>
                      <TableCell>{trans.table_offer_discount}</TableCell>
                      <TableCell>{trans.table_offer_start_date}</TableCell>
                      <TableCell>{trans.table_offer_end_date}</TableCell>
                      <TableCell>{trans.table_offer_offer_price}</TableCell>
                      <TableCell>{trans.table_offer_start_price}</TableCell>
                      <TableCell>{trans.table_offer_actions}</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {offersList.map((offer) => (
                      <TableRow key={offer.id}>
                        <TableCell>
                          {offer.offer_image_thumb ? (
                            <img
                              src={offer.offer_image_thumb}
                              alt={offer.name}
                              style={{ width: "50px", height: "50px" }}
                            />
                          ) : (
                            "N/A"
                          )}
                        </TableCell>
                        <TableCell>{offer.name}</TableCell>
                        <TableCell>{offer.description}</TableCell>
                        <TableCell>{offer.discount_percentage}</TableCell>
                        <TableCell>
                          {offer.end_date != null ?getLocalDate(
                            new Date(offer.start_date),
                            "yyyy-MM-dd"
                          ): ""}
                        </TableCell>
                        <TableCell>
                          {offer.end_date != null ? getLocalDate(new Date(offer.end_date), "yyyy-MM-dd"): ""}
                        </TableCell>
                        <TableCell>{offer.offer_price}</TableCell>
                        <TableCell>{offer.start_price}</TableCell>
                        <TableCell>
                          <Box sx={{ display: "flex", gap: 1 }}>
                            <IconButton
                              onClick={() => handleEditProducts(offer)}
                            >
                              <InventoryIcon />
                            </IconButton>
                            <IconButton onClick={() => handleEditOffer(offer)}>
                              <EditIcon />
                            </IconButton>
                            <IconButton
                              onClick={() => handleDeleteOffer(offer)}
                            >
                              <DeleteIcon />
                            </IconButton>
                          </Box>
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </Paper>
          </Grid>
        </Grid>
      </Grid>

      <AddEditCatalogOffer
        itemId={addEditOfferId}
        isOpen={isAddEditDialogOpen}
        onClose={handleCloseDialog}
        onItemUpdate={() => {
          reloadData();
        }}
        storeItems={storeItems}
      />

      <EditCatalogOfferProducts
        itemId={addEditOfferId}
        isOpen={isProductsEditDialogOpen}
        onClose={handleCloseDialogEditProcuts}
        onItemUpdate={() => {
          reloadData();
        }}
        storeItems={storeItems}
      />
    </Container>
  );
};

export default CatalogOffersList;
