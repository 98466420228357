import {
  POST_USER_REVIEW_ALL
  } from '../actionTypes';
  import axios from '../../helpers/Api'
  import * as General from '../../helpers/General'
import { DEBUG_MODE } from '../../constants';
  
export const postReviewssAll = (postData) => async dispatch => {
  //Get user locale of the machine he is on
  const userLocale = General.getLocale();
  postData.langLoc = userLocale;
  postData.fromAdmin = true;

  const response = await axios.post(`user-review/list`,postData);

  if (DEBUG_MODE){
    console.log(response.data);
  }

  dispatch({type: POST_USER_REVIEW_ALL, payload: response.data});
};