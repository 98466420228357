import './chats.css';
import React, { useState, useEffect, useRef } from 'react';
import {useDispatch, useSelector} from "react-redux";

import {
  AppBar,
  Box,
  Container,
  Dialog,
  IconButton,
  List,
  ListItem,
  TextField,
  Toolbar,
  Typography,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import SendIcon from '@mui/icons-material/Send';
import Linkify from 'linkify-react';

import { createChatRoom,fetchInitialMessages, sendChatMessage, deleteMessages } from './chatApi';

import * as Store from '../../helpers/Store';
import { USER_ID_SET } from '../../redux/actionTypes';
import { httpHtml, validURL } from '../../helpers/General';

import openSocket from 'socket.io-client';
import { DATE_TIME_FORMAT, PRIMARY_COLOR, SOCKET_URL } from '../../constants';

import { animateScroll } from "react-scroll";
import Moment from 'react-moment';

import parse from 'html-react-parser';

import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';

import SweetAlert from 'sweetalert2';
// Import your API and service functions here

const ChatComponent = ({ chatUserId }) => {
  const [messages, setMessages] = useState([]);
  const [renderedMessages, setRenderedMessages] = useState([]);


  const [isLoading, setIsLoading] = useState(true);
  const [loadingMore, setLoadingMore] = useState(false);
  const [page, setPage] = useState(1);
  const [maxPages, setMaxPages] = useState(1);
  const [limit, setLimit] = useState(0);

  const [messageInput, setMessageInput] = useState('');
  const scrollRef = useRef(null);


    
  let currentUserId = Store.getData(USER_ID_SET);
  const [chatRoom, setChatRoom] = useState(null);

  const socket = openSocket(SOCKET_URL);

  const [messagesLoading, setMessagesLoading] = useState(false);

  const settingsData = useSelector(({settings}) => settings.settingsData);

  const trans = settingsData.trans;

  useEffect(() => {
    if (chatUserId != null && chatUserId != ""){
        createRoomWithUsers();
    }
   
  }, [chatUserId]);

  useEffect(() => {
    
    return () => {
      // Disconnect socket.io connection here
      if (socket) {
        socket.disconnect();
      }
    };
  }, []);

  useEffect(() => {
    if (page != 1){
      getChatMessages(page);
    }
  }, [page]);


  useEffect(() => {
    if (maxPages > 1){
      setPaginationScrollMethod();
    }
  }, [maxPages]);

  const setPaginationScrollMethod = () => {
    if (chatScroll != null && chatScroll.current != null){
      chatScroll.current.addEventListener("scroll", () => {

        if (chatScroll && chatScroll.current.scrollTop <= 10) {
          // console.log("hey bro maxPages",maxPages);
          // console.log("hey bro page",page);
          if (maxPages > page){
            setPage(page + 1);
          }
           
        }
       });
      if (chatScroll.current) {
          window.scrollTo({
              behavior: "instant",
              top: chatScroll.current.offsetTop
          });
       }
      }
  }
  
  const initData = async () => {
    try {
        if (chatRoom != null){
            setChatSockets();
            // Fetch initial messages
            getChatMessages(1);
             
        }
    } catch (error) {
      console.error('Error fetching initial messages:', error);
    }
  };

  const getChatMessages = async (currentPage) => {
      let postData = {
          "langLoc": "el",
          "page":currentPage,
          "roomId": chatRoom.id
      }; 
      
      const response = await fetchInitialMessages(postData);
     // console.log("MESSAGES RESPONSE",response);
      if (response) {
        if (response.cur_page == 1){
          setMessagesLoading(false);
          setMaxPages(response.pages);
          setMessages(response.chat_messages);
        } else {
          var oldMessages = [];
          messages.forEach(element => {
            oldMessages.push(element);
          });
          response.chat_messages.forEach(element => {
            oldMessages.push(element);
          });
          setMessages(oldMessages);
        }
   
      }
  }

  useEffect(() => {
    // Reverse the messages for rendering
    setRenderedMessages([...messages].reverse());
  }, [messages]);

  useEffect(() => {
    if (chatRoom != null){
        initData();
    }
  }, [chatRoom]);

  useEffect(() => {
    scrollToBottom();
  }, [messages]);

  const createRoomWithUsers = async () => {
    let postData = {
        "title": "",
        "room_users": [
            currentUserId,
            chatUserId
        ],
        "room_id": ""
    };
   // console.log("postDatapostDatapostDatapostDatapostData",postData);
    const response = await createChatRoom(postData);
    setChatRoom(response);
  }

  const setChatSockets = () => {
    if (socket && chatRoom != null) {
      // Listen for new messages
      socket.on(`room_${chatRoom.id}`, (data) => {
        addNewMessage(data);
      });

    //   // Join the chat room
    //   socket.emit('joinRoom', { roomId: chatRoomId });
    }
  };


  const addNewMessage = (data) => {
    // Handle new messages from the socket
    const newMessage = data; // Replace with your data handling logic
    //console.log('Received new message:', newMessage);

    // Update the original order
    setMessages((prevMessages) => [newMessage, ...prevMessages]);
  };

  const handleOpenPopupChat = () => {
    // Implement opening the chat popup
  };

  const handleClosePopupChat = () => {
    // Implement closing the chat popup
  };

  const sendMessageToServer = async () => {
    try {
      if (messageInput.trim() === '' || chatRoom == null) {
        return;
      }
      let postData = {
        "sender_id": currentUserId,
        "lang_loc": "en",
        "room_id":chatRoom.id,
        "message":messageInput,
        "message_type": "TEXT"
      };
      // Send the message to the server
      const response = await sendChatMessage(postData);
      if (response && response.status === true) {
        // Clear the input field on successful send
        setMessageInput('');
      }
    } catch (error) {
      console.error('Error sending message:', error);
    }
  };

  const openToDeleteMessages = (message) => {
    // Implement opening the delete messages dialog
    SweetAlert.fire({
      theme: "dark",
      title:trans.delete_title,
      text:trans.delete_descr,
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: PRIMARY_COLOR,
      confirmButtonText:trans.delete_title,
      cancelButtonText: trans.cancel_btn,
      reverseButtons: true
    }).then((result) => {
      if (result.value) {
          //OK
          deleteChatRoomMessage(message);
      }
    })
  };

  const deleteChatRoomMessage = async (message) => {
    try {
      // Implement deleting chat room messages
      let postData = {
        "messageId": message.id,
        "userId": currentUserId,
        "langLoc": "el"
      };
      const response = await deleteMessages(postData);
      //console.log("DELETEMESSAGE",response);
      if (response && response.status === true) {
        // Clear the messages
       setPage(1);
       setMaxPages(1);
       getChatMessages(1);
      }
    } catch (error) {
      console.error('Error deleting messages:', error);
    }
  };

  

  function noMessagesUI() {
    return (<div>
        <h4>{trans.chat_no_messages}</h4>
    </div>)
  }

  const chatScroll = useRef(null);
  const scrollToBottom = () => {
     if (chatScroll != null && chatScroll.current  != null){ 
     //chatScroll.current.scrollIntoView({ behavior: "smooth" });
       animateScroll.scrollToBottom({
         containerId: "main_chat_box"
       });
     }
   };
   useEffect(scrollToBottom, [chatScroll,renderedMessages]);

  

  return (
    <div>
          <Box
            ref={scrollRef}
            style={{minHeight: '300px', maxHeight: '400px', overflowY: 'auto' }}
          >
            <div style={{display:`${!messagesLoading && renderedMessages.length == 0 ? "block" : 'none'}`}}>
                {noMessagesUI()}
            </div>
        
            <div style={{display:`${!messagesLoading && renderedMessages.length != 0 ? "block" : 'none'}`}}>
                <ul  ref={chatScroll} id="main_chat_box">
                {renderedMessages.map((item,index) => {
                    let isMe = item.sender.id == currentUserId;
                    if (isMe) {
                    return (<li key={item.id} class="me">
                    <div class="entete">
                        <h3> <Moment className="message-data-time"  format={DATE_TIME_FORMAT} unix>{item.timestamp / 1000}</Moment></h3>
                        <h2 style={{paddingLeft:'5px'}}>{item.sender.fullname}</h2>
                        <span class="status grey"></span>
                    </div>
                    <div style={{display:'flex',flexDirection:'row',justifyContent:'end'}}>
                      <div style={{marginTop:'5px',marginRight:'16px',cursor:'pointer'}} onClick={() => {openToDeleteMessages(item)}}>
                        <DeleteOutlineIcon color='action'/>
                      </div>
          
                      <div class="message">
                          {parse(httpHtml(item.text))}
                      </div>
                    </div>
                    </li>)
                    } else {
                    return ( <li class="you">
                    <div class="entete">
                        <span class="status red"></span>
                        <h2 style={{paddingRight:'5px'}}>{item.sender.fullname}</h2>
                        <h3 > <Moment className="message-data-time"  format={DATE_TIME_FORMAT} unix>{item.timestamp / 1000}</Moment></h3>
                    </div>
                    <div>
                      <div class="message">
                          {parse(httpHtml(item.text))}
                      </div>
                    </div>
                 
                    </li>)
                    }
                })}
                </ul>
            </div>
      
          </Box>
          <div style={{display: 'flex',flexDirection:'row',justifyContent:'space-evenly',marginTop:'20px'}}>
            <TextField
                value={messageInput}
                onChange={(e) => setMessageInput(e.target.value)}
                fullWidth
                placeholder={trans.chat_type_your_message}
                onKeyPress={(e) => {
                  if (e.key === 'Enter') {
                    sendMessageToServer();
                  }
                }}
                variant="outlined"
                />
                <div style={{width:'16px'}}></div>
                <IconButton onClick={sendMessageToServer}>
                <SendIcon />
                </IconButton>
          </div>
    </div>
  );
};

export default ChatComponent;
