import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Dialog,
  DialogContent,
  DialogTitle,
  Button,
  List,
  ListItem,
  ListItemText,
  Grid,
  IconButton,
  TextField,
  InputLabel,
  MenuItem,
  FormControl,
  Select,
  Chip,
  OutlinedInput,
} from "@mui/material";

import CloseIcon from "@mui/icons-material/Close";
import { postStoreUpdateOrderDetails } from "../../redux/stores/action";
import { POST_STORES_UPDATE_ORDER_DETAILS } from "../../redux/actionTypes";
import { toast } from "react-toastify";
import AddIcon from "@mui/icons-material/Add";
import DeleteIcon from "@mui/icons-material/Delete";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

const StoreOrderDetailsDialog = (props) => {
  const settingsData = useSelector(({ settings }) => settings.settingsData);
  const updateOrderDetailsData = useSelector(({ stores }) => stores.updateOrderDetailsData);

  //Variables
  const dispatch = useDispatch();
  const trans = settingsData.trans;

  // Local state for selections and Stripe key
  const [orderMethodsList, setOrderMethodsList] = useState([]);
  const [paymentOptionsList, setPaymentTypesList] = useState([]);
  const [orderMethods, setOrderMethods] = useState([]);
  const [paymentTypes, setPaymentTypes] = useState([]);
  const [stripeKey, setStripeKey] = useState("");
  const [cardlink_mid, setCardLinkMid] = useState("");
  const [cardlink_key, setCardlinkKey] = useState("");
  const [cardlink_url, setCardLinkUrl] = useState("");
  const [orderEmails, setOrderEmails] = useState([]);
  const [newEmail, setNewEmail] = useState("");

  
  useEffect(() => {
    if (updateOrderDetailsData != null) {
      if (updateOrderDetailsData.status) {
        toast.dismiss();
        toast.success(updateOrderDetailsData.message, {
            position: toast.POSITION.TOP_RIGHT
            });
            props.onDataUpdated();
      } else {
        toast.dismiss();
        toast.error(updateOrderDetailsData.message, {
            position: toast.POSITION.TOP_RIGHT
            });
      }
      dispatch({ type: POST_STORES_UPDATE_ORDER_DETAILS, payload: null });
    }
  }, [updateOrderDetailsData]);

  useEffect(() => {
    if (settingsData != null){
      setOrderMethodsList(settingsData.orderMethodsList);
      var paymentOptionsFixed = [];
      settingsData.paymentOptionsList.forEach(element => {
        if (element.payment_option_type == "STRIPE"){
          var elementItem = element;
          elementItem.method_text = `${element.method_text} (Stripe)`
          paymentOptionsFixed.push(elementItem);
        }else {
          paymentOptionsFixed.push(element);
        }
      });
      setPaymentTypesList(paymentOptionsFixed);
    }
  }, [settingsData]);

  useEffect(() => {
    if (props.storeItem) {
      setPaymentTypes(props.storeItem.catalog_payment_types);
      setOrderMethods(props.storeItem.order_methods);
      setStripeKey(props.storeItem.stripe_key);
      setCardLinkMid(props.storeItem.cardlink_mid);
      setCardlinkKey(props.storeItem.cardlink_key);
      setCardLinkUrl(props.storeItem.cardlink_url);
      setOrderEmails(props.storeItem.order_emails || []);
    }
  }, [props.storeItem,orderMethodsList,paymentOptionsList]);

  // Handling order method change
  const handleOrderMethodChange = (event) => {
    const {
      target: { value },
    } = event;
    setOrderMethods(typeof value === "string" ? value.split(",") : value);
  };

  // Handling payment type change
  const handlePaymentTypeChange = (event) => {
    const {
      target: { value },
    } = event;
    setPaymentTypes(typeof value === "string" ? value.split(",") : value);
  };

  // Handling Stripe key change
  const handleStripeKeyChange = (event) => {
    setStripeKey(event.target.value);
  };

  const handleAddEmail = () => {
    if (newEmail.trim() !== "") {
      setOrderEmails([...orderEmails, newEmail]);
      setNewEmail("");
    }
  };

  const handleRemoveEmail = (index) => {
    const updatedEmails = orderEmails.filter((_, i) => i !== index);
    setOrderEmails(updatedEmails);
  };

  const handleEmailChange = (index, value) => {
    const updatedEmails = orderEmails.map((email, i) => i === index ? value : email);
    setOrderEmails(updatedEmails);
  };


  const onSaveOrderDetails = (e) => {
    e.preventDefault();
    var postData = {
      "storeId": props.storeItem.id,
      "catalog_payment_types": paymentTypes,
      "order_methods":orderMethods ,
      "stripe_key": stripeKey,
      "cardlink_mid": cardlink_mid,
      "cardlink_key": cardlink_key,
      "cardlink_url": cardlink_url,
      "order_emails": orderEmails
    };
    
    dispatch(postStoreUpdateOrderDetails(postData));
  }

  return (
    <Dialog
      open={props.open}
      onClose={props.onClosePaymentMethods}
      fullWidth
      maxWidth="md"
    >
      <DialogTitle>
        <div className="_flex_row_space">
          <div>{settingsData.trans.edit_store_payment_methods}</div>
          <IconButton onClick={props.onClosePaymentMethods}>
            <CloseIcon />
          </IconButton>
        </div>
      </DialogTitle>
      <DialogContent>
        <div>
          <br/>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <FormControl fullWidth>
              <InputLabel id="order-methods-label">
                {trans.order_detais_order_methods_field}
              </InputLabel>
              <Select
                labelId="order-methods-label"
                multiple
                value={orderMethods}
                onChange={handleOrderMethodChange}
                input={
                  <OutlinedInput
                    id="select-multiple-chip"
                    label={trans.order_detais_order_methods_field}
                  />
                }
                renderValue={(selected) => (
                  <div>
                    {selected.map((value) => (
                      <Chip
                        key={value}
                        style={{marginRight:'2px'}}
                        label={
                          orderMethodsList.find(
                            (method) => method.order_method_type === value
                          ).method_text
                        }
                      />
                    ))}
                  </div>
                )}
                MenuProps={MenuProps}
              >
                {orderMethodsList.map((method) => (
                  <MenuItem
                    key={method.order_method_type}
                    value={method.order_method_type}
                  >
                    {method.method_text}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={12}>
            <FormControl fullWidth>
              <InputLabel id="payment-types-label">{trans.order_detais_payment_types_field}</InputLabel>
              <Select
                labelId="payment-types-label"
                multiple
                value={paymentTypes}
                onChange={handlePaymentTypeChange}
                input={
                  <OutlinedInput
                    id="select-multiple-chip"
                    label="Payment Types"
                  />
                }
                renderValue={(selected) => (
                  <div>
                    {selected.map((value) => (
                      <Chip
                        key={value}
                        style={{marginRight:'2px'}}
                        label={
                          paymentOptionsList.find(
                            (option) => option.payment_option_type === value
                          ).method_text
                        }
                      />
                    ))}
                  </div>
                )}
                MenuProps={MenuProps}
              >
                {paymentOptionsList.map((option) => (
                  <MenuItem
                    key={option.payment_option_type}
                    value={option.payment_option_type}
                  >
                    {option.method_text}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
          {/* <Grid item xs={12}>
            <TextField
              fullWidth
              label={trans.order_detais_stripe_key_field}
              variant="outlined"
              value={stripeKey}
              onChange={handleStripeKeyChange}
            />
          </Grid> */}
          <Grid item xs={6}>
            <TextField
              fullWidth
              label={trans.order_detais_cardlink_mid_field}
              variant="outlined"
              value={cardlink_mid}
              onChange={(e) => {setCardLinkMid(e.target.value)}}
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              fullWidth
              label={trans.order_detais_cardlink_key_field}
              variant="outlined"
              value={cardlink_key}
              onChange={(e) => {setCardlinkKey(e.target.value)}}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              fullWidth
              label={trans.order_detais_cardlink_url_field}
              variant="outlined"
              value={cardlink_url}
              onChange={(e) => {setCardLinkUrl(e.target.value)}}
            />
          </Grid>

          <Grid item xs={12}>
            <List>
              {orderEmails.map((email, index) => (
                <ListItem key={index}>
                  <TextField
                    fullWidth
                    label={`Email ${index + 1}`}
                    variant="outlined"
                    value={email}
                    onChange={(e) => handleEmailChange(index, e.target.value)}
                    style={{ marginRight: 8 }}
                  />
                  <IconButton onClick={() => handleRemoveEmail(index)}>
                    <DeleteIcon />
                  </IconButton>
                </ListItem>
              ))}
              <ListItem>
                <TextField
                  fullWidth
                  label={trans.order_detais_add_email_field}
                  variant="outlined"
                  value={newEmail}
                  onChange={(e) => setNewEmail(e.target.value)}
                  style={{ marginRight: 8 }}
                />
                <IconButton onClick={handleAddEmail}>
                  <AddIcon />
                </IconButton>
              </ListItem>
            </List>
          </Grid>

          <Grid item xs={12}>
            <Button variant="contained" color="primary" onClick={(e) => {onSaveOrderDetails(e)}}>
              {trans.order_detais_save_changes_button}
            </Button>
          </Grid>
        </Grid>
        </div>
      </DialogContent>
    </Dialog>
  );
};

export default StoreOrderDetailsDialog;
