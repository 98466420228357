import React from 'react';
import { preview_image } from '../../../images';

function ListImage({ url, width = '50px' }) { // width is now a prop with a default value
  const style = {
    width, // Use the width prop
    height: 'auto',
    objectFit: 'cover',
  };

  return <img src={url != null && url !== "" && url !== " " ? url : preview_image} alt="" style={style} />;
}

export default ListImage;
