import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Autocomplete from "@mui/material/Autocomplete";
import TextField from "@mui/material/TextField";
import Grid from "@mui/material/Grid";
import CircularProgress from "@mui/material/CircularProgress";
import { postCatalogProductList } from "../../redux/catalog/action";

const ProductsPicker = ({
  onProductSelect,
  multiple = false,
  autoSelectFirst = false,
  preSelectedProductIds = [],
  storeItems = [],
  clearPreSelectedProducts // We will no longer call this automatically on selection
}) => {
  const dispatch = useDispatch();
  const listCatalogProductData = useSelector(({ catalog }) => catalog.listCatalogProductData);

  const [productsList, setProductsList] = useState([]);
  const [selectedProducts, setSelectedProducts] = useState(multiple ? [] : null);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setLoading(true);
    dispatch(postCatalogProductList({ queryStores: storeItems }));
  }, [dispatch, storeItems]);

  useEffect(() => {
    if (listCatalogProductData) {
      setLoading(false);
      setProductsList(listCatalogProductData.products);

      if (preSelectedProductIds.length > 0 && selectedProducts.length === 0) {
        const preSelectedProducts = listCatalogProductData.products.filter((product) =>
          preSelectedProductIds.includes(product.id)
        );
        setSelectedProducts(multiple ? preSelectedProducts : preSelectedProducts[0]);
        onProductSelect(multiple ? preSelectedProducts.map(p => p.id) : preSelectedProducts[0]?.id);
      } else if (autoSelectFirst && listCatalogProductData.products.length > 0 && !selectedProducts) {
        const firstProduct = listCatalogProductData.products[0];
        setSelectedProducts(firstProduct);
        onProductSelect(firstProduct.id);
      }
    }
  }, [listCatalogProductData, preSelectedProductIds, multiple, autoSelectFirst, selectedProducts, onProductSelect]);

  // Ensure pre-selected product list is updated properly
  useEffect(() => {
    if (preSelectedProductIds.length === 0 && selectedProducts.length !== 0) {
      setSelectedProducts(multiple ? [] : null);
    }
  }, [preSelectedProductIds, multiple]);

  const handleSelectProduct = (event, value) => {
    if (multiple) {
      setSelectedProducts(value);
      const productIds = value.map((product) => product.id);
      onProductSelect(productIds);
    } else {
      setSelectedProducts(value);
      onProductSelect(value ? value.id : null);
    }
    // Remove clearPreSelectedProducts call here
  };

  return (
    <Grid item xs={12}>
      <Autocomplete
        multiple={multiple}
        id="products-autocomplete"
        options={productsList}
        getOptionLabel={(option) => (option ? option.name : "")}
        value={selectedProducts}
        onChange={handleSelectProduct}
        loading={loading}
        renderOption={(props, option) => (
          <li {...props} key={option.id}>
            {option.name}
          </li>
        )}
        renderInput={(params) => (
          <TextField
            {...params}
            label="Select Products"
            variant="outlined"
            InputProps={{
              ...params.InputProps,
              endAdornment: (
                <React.Fragment>
                  {loading ? <CircularProgress color="inherit" size={20} /> : null}
                  {params.InputProps.endAdornment}
                </React.Fragment>
              ),
            }}
          />
        )}
        isOptionEqualToValue={(option, value) => option.id === value?.id}
      />
    </Grid>
  );
};

export default ProductsPicker;
