import React, { useState, useEffect } from "react";
import TextField from "@mui/material/TextField";

const MultiLangTextField = ({ label, currentLang, texts, onTextsChange }) => {

  const [currentValue, setCurrentValue] = useState("");
  useEffect(() => {
    // Find the text for the current language or set it to an empty string
    const textForCurrentLang =
      texts.find((text) => text.lang === currentLang.value) || "";
    setCurrentValue(textForCurrentLang.value || "");
  }, [currentLang, texts]);
  const handleChange = (event) => {
    const newValue = event.target.value;
    setCurrentValue(newValue);
    // Update the texts array with the new value for the current language
    const updatedTexts = texts.filter(
      (text) => text.lang !== currentLang.value
    );
    updatedTexts.push({ lang: currentLang.value, value: newValue });
    onTextsChange(updatedTexts);
  };
  return (
    <TextField
      margin="dense"
      label={label}
      value={currentValue}
      onChange={handleChange}
      fullWidth
      variant="standard"
    />
  );
};

export default MultiLangTextField;
