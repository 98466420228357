import {
  POST_SIGN_IN,
  POST_SIGN_OUT,
  POST_USER_INFO,
  POST_CREATE_ACCOUNTS,
  POST_ACCOUNT_CONTACT_OTPION_ADD,
  POST_ACCOUNT_CONTACT_OTPION_DELETE,
  POST_USER_SINGLE,
  POST_USER_CHANGE_PASSWORD,
  POST_USER_TYPE_ADMIN,
  POST_USER_EDIT_USER_TYPES,
  POST_USER_TYPE_MANAGER,
  POST_USER_TYPE_STORE_USER,
} from "../actionTypes";

const INIT_STATE = {
  signInData: null,
  logoutData: null,
  userInfoData: null,
  createAccountsData: null,
  addContactOptionData: null,
  deleteContactOptionData: null,
  userSingleData: null,
  changePasswordData: null,
  changeUserTypesData: null,
  userTypeAdmin: false,
  userTypeManager: false,
  userTypeStoreUser: false,
};

const reducer = (state = INIT_STATE, action) => {
  switch (action.type) {
    case POST_SIGN_IN:
      return {
        ...state,
        signInData: action.payload,
      };
    case POST_SIGN_OUT:
      return {
        ...state,
        logoutData: action.payload,
      };
    case POST_USER_INFO:
      return {
        ...state,
        userInfoData: action.payload,
      };
    case POST_USER_SINGLE:
      return {
        ...state,
        userSingleData: action.payload,
      };
    case POST_USER_EDIT_USER_TYPES:
      return {
        ...state,
        changeUserTypesData: action.payload,
      };
    case POST_CREATE_ACCOUNTS:
      return {
        ...state,
        createAccountsData: action.payload,
      };
    case POST_ACCOUNT_CONTACT_OTPION_ADD:
      return {
        ...state,
        addContactOptionData: action.payload,
      };
    case POST_ACCOUNT_CONTACT_OTPION_DELETE:
      return {
        ...state,
        deleteContactOptionData: action.payload,
      };
    case POST_USER_CHANGE_PASSWORD:
      return {
        ...state,
        changePasswordData: action.payload,
      };
    case POST_USER_TYPE_ADMIN:
      return {
        ...state,
        userTypeAdmin: action.payload,
      };
    case POST_USER_TYPE_MANAGER:
      return {
        ...state,
        userTypeManager: action.payload,
      };
    case POST_USER_TYPE_STORE_USER:
      return {
        ...state,
        userTypeStoreUser: action.payload,
      };
    default:
      return state;
  }
};

export default reducer;
