import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Grid,
  TextField,
  Box,
} from "@mui/material";
import { showResponseMessage } from "../../utils/alerts/MyToastUtil";
import { POST_CATALOG_EXTRAS_ADD_EDIT } from "../../redux/actionTypes";
import {
  postCatalogExtrasListAddEdit,
  postCatalogExtrasSingle,
} from "../../redux/catalog-extras/action";
import LangPicker from "../global/LangPicker";
import MultiLangTextField from "../global/textFields/MultilanguageTextField";
import MyStatusPicker from "../global/statusPicker/MyStatusPicker";
import MyListPicker from "../global/listPicker/MyListPicker";
import { getDefaultLanguage } from "../../utils/languages";

const AddEditCatalogExtras = (props) => {
  const settingsData = useSelector(({ settings }) => settings.settingsData);
  const singleCatalogExtrasData = useSelector(
    ({ catalogExtras }) => catalogExtras.singleCatalogExtrasData
  );
  const addEditCatalogExtrasData = useSelector(
    ({ catalogExtras }) => catalogExtras.addEditCatalogExtrasData
  );

  const dispatch = useDispatch();
  const trans = settingsData.trans;

  const [extra, setExtra] = useState(null);
  const [selectedLang, setSelectedLang] = useState(
    getDefaultLanguage(settingsData.app_languages)
  );
  const [names, setNames] = useState([]);
  const [descriptions, setDescriptions] = useState([]);

  const [allItems, setAllItems] = useState(false);
  const [status, setStatus] = useState(true);

  const extraStates = [
    {
      value: "REQUIRED",
      text: trans.catalog_extra_state_required,
    },
    {
      value: "OPTIONAL",
      text: trans.catalog_extra_state_optional,
    },
  ];

  const [state, setState] = useState(extraStates[0].value);

  useEffect(() => {
    if (props.isOpen && props.itemId != null) {
      reloadData();
    }

    if (props.isOpen && props.itemId == null) {
      setNames([]);
      setDescriptions([]);
      setAllItems(false);
      setStatus(true);
    }
  }, [props.isOpen, props.itemId]);

  useEffect(() => {
    if (extra != null) {
      setNames(extra.names);
      setDescriptions(extra.descriptions);
      setAllItems(extra.allitems);
      setStatus(extra.status.value);
    }
  }, [extra]);

  useEffect(() => {
    if (singleCatalogExtrasData != null) {
      setExtra(singleCatalogExtrasData);
    }
  }, [singleCatalogExtrasData]);

  useEffect(() => {
    if (addEditCatalogExtrasData != null) {
      showResponseMessage(addEditCatalogExtrasData);
      dispatch({ type: POST_CATALOG_EXTRAS_ADD_EDIT, payload: null });
      props.onItemUpdate();
      if (props.onClose) props.onClose();
    }
  }, [addEditCatalogExtrasData]);

  const reloadData = () => {
    if (props.itemId != null) {
      let postData = { itemId: props.itemId };
      dispatch(postCatalogExtrasSingle(postData));
    }
  };

  const handleSave = () => {
    const postData = {
      names,
      descriptions,
      state,
      allitems: allItems,
      status: status,
    };

    if (props.itemId != null && props.itemId != "") {
      postData.catalogExtraId = props.itemId;
    }

    // Call your redux action or API call function here
    //console.log("postData", postData); // For demonstration
    dispatch(postCatalogExtrasListAddEdit(postData));
  };

  const changeLanguage = (lang) => {
    setSelectedLang(lang);
  };

  const handleClose = () => {
    clearData();
  };

  const clearData = () => {
    setNames([]);
    setDescriptions([]);
    setSelectedLang(getDefaultLanguage(settingsData.app_languages));
    setAllItems(false);
    setStatus(true);
    setState(extraStates[0].value);
    props.onClose();
  };

  return (
    <Dialog open={props.isOpen} onClose={handleClose} maxWidth="xl">
      <DialogTitle>
        {extra ? trans.catalog_extra_edit_title : trans.catalog_extra_add_title}
      </DialogTitle>
      <DialogContent>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <LangPicker onLangSelectedChanged={changeLanguage} />
          </Grid>

          <Grid item xs={12}>
            <MultiLangTextField
              label={trans.catalog_extra_add_extra_name}
              currentLang={selectedLang}
              texts={names}
              onTextsChange={setNames}
            />
          </Grid>

          <Grid item xs={12}>
            <MultiLangTextField
              label={trans.catalog_extra_add_extra_description}
              currentLang={selectedLang}
              texts={descriptions}
              onTextsChange={setDescriptions}
            />
          </Grid>

          <Grid item xs={6}>
            <MyStatusPicker
              label={trans.catalog_extra_add_extra_status}
              status={status}
              onChange={(value) => setStatus(value)}
            />
          </Grid>

          <Grid item xs={6}>
            <MyListPicker
              labelId={"state"}
              label={trans.catalog_extra_add_extra_state}
              selected={state}
              onChange={(value) => setState(value)}
              list={extraStates}
            />
          </Grid>

          <Grid item xs={12}>
            <MyStatusPicker
              label={trans.catalog_extra_add_extra_all_items}
              status={allItems}
              onChange={(value) => setAllItems(value)}
            />
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button onClick={props.onClose}>
          {trans.catalog_extra_state_cancel_button}
        </Button>
        <Button onClick={handleSave}>
          {trans.catalog_extra_state_save_button}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default AddEditCatalogExtras;
