import "./AddressAutocomplete.css";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import TextField from "@mui/material/TextField";
import { usePlacesWidget } from "react-google-autocomplete";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";

const AddressAutocomplete = (props) => {
  const settingsData = useSelector(({ settings }) => settings.settingsData);
  const trans = settingsData.trans;

  const [address, setAddress] = useState("");
  const [streetNumber, setStreetNumber] = useState("");
  const [area, setArea] = useState("");
  const [postalCode, setPostalCode] = useState("");
  const [lat, setLat] = useState("");
  const [lng, setLng] = useState("");

  useEffect(() => {
    if (props.initialAddressItem && props.initialAddressItem.address != null) {
      setAddress(props.initialAddressItem.address);
      setStreetNumber(props.initialAddressItem.street_number || "");
      setArea(props.initialAddressItem.area || "");
      setPostalCode(props.initialAddressItem.postalCode || "");
      setLat(props.initialAddressItem.lat || "");
      setLng(props.initialAddressItem.lng || "");
    }
  }, [props.initialAddressItem]);

  const handleChange = (updatedValues = {}) => {
    const updatedAddressObject = {
      address: updatedValues.address || address,
      street_number: updatedValues.streetNumber || streetNumber,
      area: updatedValues.area || area,
      postalCode: updatedValues.postalCode || postalCode,
      lat: updatedValues.lat || lat,
      lng: updatedValues.lng || lng,
    };

    setAddress(updatedAddressObject.address);
    setStreetNumber(updatedAddressObject.street_number);
    setArea(updatedAddressObject.area);
    setPostalCode(updatedAddressObject.postalCode);
    setLat(updatedAddressObject.lat);
    setLng(updatedAddressObject.lng);

    props.onAddressSelect(updatedAddressObject);
  };

  const { ref: materialRef } = usePlacesWidget({
    apiKey: settingsData.initData.maps_key,
    onPlaceSelected: (place) => handleChange({
      address: place.address_components.find((component) => component.types.includes("route"))?.long_name || "",
      streetNumber: place.address_components.find((component) => component.types.includes("street_number"))?.long_name || "",
      area: place.address_components.find((component) => component.types.includes("locality"))?.long_name || "",
      postalCode: place.address_components.find((component) => component.types.includes("postal_code"))?.long_name || "",
      lat: place.geometry.location.lat(),
      lng: place.geometry.location.lng(),
    }),
    options: {
      types: ["address"],
    },
  });

  return (
    <div className="my-autocomplete-container">
      {settingsData.initData.maps_key ? (
        <TextField
          label={trans.enter_location_field}
          placeholder={trans.enter_location_field}
          fullWidth
          color="secondary"
          variant="standard"
          value={address}
          onChange={(e) => handleChange({ address: e.target.value })}
          inputRef={materialRef}
        />
      ) : (
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Typography variant="h6">{trans.enter_location_manually}</Typography>
          </Grid>
          <Grid item xs={6}>
            <TextField
              label={trans.address_field}
              placeholder={trans.address_field}
              fullWidth
              color="secondary"
              variant="outlined"
              value={address}
              onChange={(e) => handleChange({ address: e.target.value })}
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              label={trans.street_number_field}
              placeholder={trans.street_number_field}
              fullWidth
              color="secondary"
              variant="outlined"
              value={streetNumber}
              onChange={(e) => handleChange({ streetNumber: e.target.value })}
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              label={trans.area_field}
              placeholder={trans.area_field}
              fullWidth
              color="secondary"
              variant="outlined"
              value={area}
              onChange={(e) => handleChange({ area: e.target.value })}
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              label={trans.postal_code_field}
              placeholder={trans.postal_code_field}
              fullWidth
              color="secondary"
              variant="outlined"
              value={postalCode}
              onChange={(e) => handleChange({ postalCode: e.target.value })}
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              label={trans.latitude_field}
              placeholder={trans.latitude_field}
              fullWidth
              color="secondary"
              variant="outlined"
              value={lat}
              onChange={(e) => handleChange({ lat: e.target.value })}
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              label={trans.longitude_field}
              placeholder={trans.longitude_field}
              fullWidth
              color="secondary"
              variant="outlined"
              value={lng}
              onChange={(e) => handleChange({ lng: e.target.value })}
            />
          </Grid>
        </Grid>
      )}
    </div>
  );
};

export default AddressAutocomplete;
