import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";


const OrderInfoItemWidget = (props) => {
 
  return (<div>
    <div className="fontMedium">{props.title}</div>
    <div>{props.text}</div>
  </div>);
};

export default OrderInfoItemWidget;
