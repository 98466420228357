import React from "react";
import TextField from "@mui/material/TextField";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { elGR } from "@mui/x-date-pickers";

const DatePickerComponent = ({ label, value, onChange }) => {
  return (
    <LocalizationProvider dateAdapter={AdapterDateFns} locale={elGR}>
      <DatePicker
        label={label}
        value={new Date(value)}
        onChange={onChange}
        fullWidth={true}
        format="dd/MM/yyyy"
        renderInput={(params) => (
          <TextField {...params} fullWidth={true}  />
        )}
      />
    </LocalizationProvider>
  );
};

export default DatePickerComponent;
