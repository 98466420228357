import {
  POST_COUPONS_LIST_ALL,
  POST_COUPONS_ADD_EDIT,
  POST_COUPONS_DELETE,
  POST_COUPONS_SINGLE
} from "../actionTypes";

const INIT_STATE = {
  couponsListData: null,
  addEditCouponData: null,
  singleCouponData: null,
  deleteCouponData: null
};

const reducer = (state = INIT_STATE, action) => {
  switch (action.type) {
    case POST_COUPONS_LIST_ALL:
      return {
        ...state,
        couponsListData: action.payload,
      };
    case POST_COUPONS_ADD_EDIT:
      return {
        ...state,
        addEditCouponData: action.payload,
      };
    case POST_COUPONS_SINGLE:
      return {
        ...state,
        singleCouponData: action.payload,
      };
    case POST_COUPONS_DELETE:
      return {
        ...state,
        deleteCouponData: action.payload,
      };
    default:
      return state;
  }
};

export default reducer;
