import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Container from "@mui/material/Container";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Button from "@mui/material/Button";
import { Grid, Paper } from "@mui/material";
import PageTitleDesc from "../global/pageTitleDesc/PageTitleDesc";
import ListImage from "../global/listWidgets/ListImage";
import { getStatusText } from "../../utils/tables/tableUtils";
import AddEditSideMenu from "./AddEditSideMenu";
import FormDialog from "../global/formDialog";
import { toast } from "react-toastify";
import { POST_SIDE_MENU_ADD, POST_SIDE_MENU_DELETE } from "../../redux/actionTypes";
import {
  postSideMenuReorder,
  postSideMenusAdd,
  postSideMenusAll,
  postSideMenusDelete,
} from "../../redux/sideMenu/action";
import TableBodySortable from "../global/TableBodySortable";
import DragHandler from "../global/DragHandler";
import SortableRow from "../global/SortableRow";
import {
  SortableContainer,
  SortableHandle,
  SortableElement,
  arrayMove,
} from "react-sortable-hoc";
import SweetAlert from "sweetalert2";

const SideMenuList = () => {
  //Selectors
  const settingsData = useSelector(({ settings }) => settings.settingsData);
  const sideMenusData = useSelector(({ sideMenu }) => sideMenu.sideMenusData);
  const addSideMenuData = useSelector(
    ({ sideMenu }) => sideMenu.addSideMenuData
  );
  const deleteSideMenuData = useSelector(
    ({ sideMenu }) => sideMenu.deleteSideMenuData
  );

  //Variables
  const dispatch = useDispatch();
  const trans = settingsData.trans;

  //Hooks
  const [sideMenusList, setSideMenuList] = useState([]);
  const [addModal, setAddModal] = useState(false);
  const [toGetData, setToGetData] = useState(false);
  const [toClearData, setToClearData] = useState(false);
  const [editItem, setEditItem] = useState(null);
  const [page_types_options, setPageTypesOptions] = useState([]);


  useEffect(() => {
    reloadData();

    var pageTypesOptions = [];
    settingsData.pageTypesItems.forEach((element) => {
      pageTypesOptions.push({
        value: element.slug_type,
        text: element.name,
      });
    });
    setPageTypesOptions(pageTypesOptions);
  
  }, []);

  useEffect(() => {
    if (sideMenusData) {
      setSideMenuList(sideMenusData.side_menu);
    }
  }, [sideMenusData]);

  const getData = () => {
    dispatch(postSideMenusAll({ fromAdmin: true }));
  };

  const reloadData = () => {
    getData();
  };

  useEffect(() => {
    //ADD DATA
    if (addSideMenuData != null) {
      if (addSideMenuData.status) {
        toast.dismiss();
        toast.success(addSideMenuData.message, {
          position: toast.POSITION.TOP_RIGHT,
        });
        setToClearData(true);
        reloadData();
      } else {
        toast.dismiss();
        toast.error(addSideMenuData.message, {
          position: toast.POSITION.TOP_RIGHT,
        });
      }
      dispatch({ type: POST_SIDE_MENU_ADD, payload: null });
    }
  }, [addSideMenuData]);

  useEffect(() => {
    if (deleteSideMenuData != null) {
      if (deleteSideMenuData.status) {
        toast.dismiss();
        toast.success(deleteSideMenuData.message, {
          position: toast.POSITION.TOP_RIGHT,
        });
        reloadData();
      } else {
        toast.dismiss();
        toast.error(deleteSideMenuData.message, {
          position: toast.POSITION.TOP_RIGHT,
        });
      }
      dispatch({ type: POST_SIDE_MENU_DELETE, payload: null });
    }
  }, [deleteSideMenuData]);
  

  const handleEdit = (e, item) => {
    e.preventDefault();
    setToClearData(false);
    setEditItem(item);
    setAddModal(true);
  };

  const onAddNew = (e) => {
    e.preventDefault();
    //OPEN THE ADD NEW MODAL
    setEditItem(null);
    setAddModal(true);
  };

  const onDialogClose = () => {
    setAddModal(false);
  };

  const onDialogSubmit = () => {
    setToGetData(true);
  };

  const onSubmitData = (data) => {
    if (data != null) {
      let postData = data;
      setEditItem(null);
      dispatch(postSideMenusAdd(postData));
      setToGetData(false);
      setToClearData(false);
      onDialogClose();
    } else {
      setToGetData(false);
    }
  };

  const handleAddStore = (e) => {
    e.preventDefault();
    setEditItem(null);
    setAddModal(true);
  };

  const onSortEnd = ({ oldIndex, newIndex }) => {
    const updatedItems = arrayMove(sideMenusList, oldIndex, newIndex);
    const updatedItemsWithOrder = updatedItems.map((item, index) => {
      return { ...item, order: index + 1 };
    });
    setSideMenuList(updatedItemsWithOrder);
    dispatch(
      postSideMenuReorder({
        orderedItems: updatedItemsWithOrder,
      })
    );
  };

  const handleDelete = (e,item) => {
    e.preventDefault();
    SweetAlert.fire({
      theme: "dark",
      title: trans.delete_record_title,
      text: trans.delete_record_desc,
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: settingsData.initData.app_color,
      confirmButtonText: trans.delete_record_button,
      cancelButtonText: trans.cancel_btn,
      reverseButtons: true,
    }).then((result) => {
      if (result.value) {
        dispatch(postSideMenusDelete({id: item.id}));
      }
    });
  };

  return (
    <Container maxWidth="xl" sx={{ mt: 4, mb: 4 }}>
      <Grid container spacing={1}>
        <Grid item xs={12}>
          <Paper
            sx={{
              p: 2,
              display: "flex",
              flexDirection: { xs: "column", md: "row" },
              alignItems: "center",
              justifyContent: "space-between",
            }}
          >
            <div style={{ flexGrow: 1 }}>
              <PageTitleDesc
                title={settingsData.trans.menu_side_menu}
                desc={settingsData.trans.menu_side_menu_desc}
              />
            </div>
            <Button
              variant="contained"
              onClick={handleAddStore}
              sx={{ mt: { xs: 2, md: 0 } }} // Conditional top margin
            >
              {trans.add_side_menu_button}
            </Button>
          </Paper>
        </Grid>

        <Grid item xs={12}>
          <Paper sx={{ p: 2, display: "flex", flexDirection: "column" }}>
            <TableContainer>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell></TableCell>
                    <TableCell>{trans.table_head_image}</TableCell>
                    <TableCell>{trans.table_head_side_menu_name}</TableCell>
                    <TableCell>{trans.table_head_status}</TableCell>
                    <TableCell>{trans.table_head_page_type}</TableCell>
                    <TableCell>{trans.table_head_actions}</TableCell>
                  </TableRow>
                </TableHead>
                <TableBodySortable
                  onSortEnd={(sortEnd) => onSortEnd(sortEnd)}
                  useDragHandle
                >
                  {sideMenusList.map((sideMenuItem, index) => (
                    <SortableRow index={index} key={sideMenuItem.id}>
                      <TableCell>
                        <DragHandler />
                      </TableCell>
                      <TableCell component="th" scope="row">
                        <ListImage url={sideMenuItem.menu_icon} width="30px" />
                      </TableCell>
                      <TableCell
                        component="th"
                        scope="row"
                        className="fontBold"
                      >
                        {sideMenuItem.name}
                      </TableCell>
                      <TableCell component="th" scope="row">
                        {getStatusText(sideMenuItem.active, trans)}
                      </TableCell>
                      <TableCell component="th" scope="row">
                        {page_types_options.find((element) => element.value == sideMenuItem.menu_page_type).text }
                      </TableCell>
                      <TableCell>
                        <div className="flex_row">
                          <Button
                            variant="contained"
                            onClick={(e) => handleEdit(e, sideMenuItem)}
                          >
                            {trans.table_row_edit_button}
                          </Button>
                          <Button
                            style={{ marginLeft: "16px" }}
                            variant="outlined"
                            onClick={(e) => handleDelete(e, sideMenuItem)}
                          >
                            {trans.table_row_delete_button}
                          </Button>
                        </div>
                      </TableCell>
                    </SortableRow>
                  ))}
                </TableBodySortable>
              </Table>
            </TableContainer>
          </Paper>
        </Grid>
      </Grid>

      <FormDialog
        open={addModal}
        onClose={onDialogClose}
        title={`${
          editItem == null ? trans.add_store_title : trans.edit_store_title
        }`}
        body={
          <AddEditSideMenu
            toGetData={toGetData}
            toClearData={toClearData}
            onSubmitData={onSubmitData}
            realoadData={() => {
              reloadData();
            }}
            editItem={editItem}
          />
        }
        onSubmit={onDialogSubmit}
        maxWidth={"md"}
      />
    </Container>
  );
};

export default SideMenuList;
