import {
  POST_NOTIFICATION_ALL,
  POST_NOTIFICATION_ADD
  } from '../actionTypes';
  import axios from '../../helpers/Api'
  import * as General from '../../helpers/General'
import { DEBUG_MODE } from '../../constants';
  
 
  
    
export const postNotificationsAll = (postData) => async dispatch => {
  //Get user locale of the machine he is on
  const userLocale = General.getLocale();
  postData.langLoc = userLocale;

  const response = await axios.post(`userpush/all`,postData);

  if (DEBUG_MODE){
    console.log(response.data);
  }

  dispatch({type: POST_NOTIFICATION_ALL, payload: response.data});
};

    
export const postNotificationsAdd = (postData) => async dispatch => {
  //Get user locale of the machine he is on
  const userLocale = General.getLocale();
  postData.langLoc = userLocale;

  const response = await axios.post(`userpush/send-push-to`,postData);

  if (DEBUG_MODE){
    console.log(response.data);
  }

  dispatch({type: POST_NOTIFICATION_ADD, payload: response.data});
};
