import React from 'react';
import { useSelector } from "react-redux";
import { DialogTitle, DialogContent, DialogActions, Button, Grid, Rating, Typography } from '@mui/material';

const UserReviewDetails = ({ review, onClose }) => {
  const settingsData = useSelector(({ settings }) => settings.settingsData);
  const trans = settingsData.trans;

  if (!review) return null;

  const { reviewUser, reviewStore, avgStarRating, starRatings, likes, reviewText } = review;

  const getRatingTitleAndDesc = (type) => {
    const rating = settingsData.reviews_ratings.find(r => r.review_rating_type === type);
    return rating ? { title: rating.review_rating_title, desc: rating.review_rating_desc } : { title: type, desc: '' };
  };

  const getLikeTitleAndDesc = (type) => {
    const like = settingsData.reviews_likes.find(l => l.review_like_type === type);
    return like ? { title: like.review_like_title, desc: like.review_like_desc } : { title: type, desc: '' };
  };

  return (
    <>
      <DialogTitle>{trans.user_review_details_title}</DialogTitle>
      <DialogContent dividers>
        <Grid container spacing={2}>
          <Grid item xs={12} md={6}>
            <div style={{ marginBottom: '20px' }}>
              <h2>{trans.user_review_user_details_title}</h2>
              <p><strong>{trans.user_review_nickname}:</strong> {reviewUser.nickname}</p>
              <p><strong>{trans.user_review_email}:</strong> {reviewUser.email}</p>
              <p><strong>{trans.user_review_full_name}:</strong> {reviewUser.firstname} {reviewUser.lastname}</p>
            </div>
          </Grid>
          <Grid item xs={12} md={6}>
            <div style={{ marginBottom: '20px' }}>
              <h2>{trans.user_review_store_details_title}</h2>
              <p><strong>{trans.user_review_store_name}:</strong> {reviewStore.name}</p>
              <p><strong>{trans.user_review_description}:</strong> {reviewStore.description}</p>
              <p><strong>{trans.user_review_address}:</strong> {reviewStore.address}, {reviewStore.postalCode}</p>
            </div>
          </Grid>
        </Grid>

        <Grid container spacing={2}>
          <Grid item xs={12} md={6}>
            <div style={{ marginBottom: '20px' }}>
              <h2>{trans.user_review_star_ratings_title}</h2>
              {starRatings.map((rating, index) => {
                const { title, desc } = getRatingTitleAndDesc(rating.rate_type);
                return (
                  <div key={index} style={{ marginBottom: '10px' }}>
                    <div><strong>{title}:</strong></div>
                    <div><Typography variant="body2" color="textSecondary">{desc}</Typography></div>
                    <div style={{ display: 'flex', alignItems: 'center', marginTop: '5px' }}>
                      <Rating value={rating.value} precision={0.5} readOnly style={{ marginRight: '10px' }} />
                      <Typography variant="body2">{rating.value}</Typography>
                    </div>
                  </div>
                );
              })}
            </div>
          </Grid>
          <Grid item xs={12} md={6}>
            <div style={{ marginBottom: '20px' }}>
              <h2>{trans.user_review_likes_title}</h2>
              {likes.map((like, index) => {
                const { title, desc } = getLikeTitleAndDesc(like.rate_type);
                return (
                  <div key={index} style={{ marginBottom: '10px' }}>
                    <div><strong>{title}:</strong></div>
                    <div> <Typography variant="body2" color="textSecondary">{desc}</Typography></div>
                    <div> <Typography variant="body2" style={{ marginTop: '5px' }}>{like.value ? trans.user_review_like_yes : trans.user_review_like_no}</Typography></div>
                  </div>
                );
              })}
            </div>
          </Grid>
        </Grid>
        
        <div style={{ marginBottom: '20px' }}>
          <h2>{trans.user_review_details_section_title}</h2>
          <div style={{ display: 'flex', alignItems: 'center', marginBottom: '10px' }}>
            <strong>{trans.user_review_average_rating}:</strong>
            <Rating value={avgStarRating} precision={0.5} readOnly style={{ marginLeft: '10px', marginRight: '10px' }} />
            <Typography variant="body2">{avgStarRating.toFixed(1)}</Typography>
          </div>
          <p><strong>{trans.user_review_review_text}:</strong> {reviewText}</p>
        </div>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="primary">{trans.user_review_close}</Button>
      </DialogActions>
    </>
  );
};

export default UserReviewDetails;
