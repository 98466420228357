import React, { useState,useEffect } from 'react';
import { Navigate, Outlet,Redirect } from 'react-router-dom';
import {useDispatch, useSelector} from "react-redux";
import { POST_USER_INFO, USER_ID_SET, USER_TOKEN_SET } from '../redux/actionTypes';
import { getSettings } from '../redux/settings/action';
import * as General from '../helpers/General'
import * as Store from '../helpers/Store';
import { postUserInfo } from '../redux/auth/action';
import axios from '../helpers/Api'
import { useAppId } from '../providers/AppIdProvider';
import LoadingDataWidget from './global/loading/LoadingDataWidget';



const PrivateRoute = (props) => {


    const dispatch = useDispatch();

    //States
    const [settingsLoaded,setSettingsLoaded] = useState(false)
    const [userDataLoaded,setUserDataLoaded] = useState(false);
    const [userAuth,setUserAuth] = useState(false);

    //Selectors
    const userInfoData = useSelector(({auth}) => auth.userInfoData);
    const settingsData = useSelector(({settings}) => settings.settingsData);

    //VARIABLES
    const { appId } = useAppId(); // Access the appId from context

    useEffect(() => { 
      //Get the app settings
      if (appId != null && appId != ""){
        setSettingsLoaded(false);
        setUserDataLoaded(false);
        dispatch(getSettings(appId)); 
      }

    }, [appId]);

    useEffect(() => { // Settings loaded
        if (settingsData !== null) {
          if (General.isLoggedIn()){
              setSettingsLoaded(true);
              getUserData();
          }  else {
              setSettingsLoaded(true);
          }
        } 
    }, [settingsData]);

    useEffect(() => { // User data loaded
      if (userInfoData !== null) {
        if (userInfoData.status){
           setUserAuth(true);
           if (props.path == `/login`) {
            window.location.replace(`/${appId}/app`);
           }
        } else {
           General.logout();
        }
        setUserDataLoaded(true); 
      } 
    }, [userInfoData]);

    const getUserData = () => {
      //Request user data
      let userId = Store.getData(USER_ID_SET);
      let postData = {userId: userId};
      axios.defaults.headers.common['Authorization'] = "Bearer " + Store.getData(USER_TOKEN_SET);
      dispatch(postUserInfo(postData));
    }
    
    if (!settingsLoaded) {
      return <LoadingDataWidget />;
    } else {
      if (General.isLoggedIn()) {
        if (userDataLoaded && !userAuth) {
          return <Navigate to={`/login`} />;
        } else if (props.path === `/login`) {
          return <LoadingDataWidget />;
        } else {
          if (!userDataLoaded) {
            return <LoadingDataWidget />;
          }
          return props.children;
        }
      } else {
        if (props.path !== `/login`) {
          return <Navigate to={`/${appId}/login`} />;
        } else {
          return props.children;
        }
      }
  }
   
}

export default PrivateRoute;