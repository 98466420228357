import {
  POST_STORES_ALL,
  POST_STORES_ADD,
  POST_STORES_EDIT,
  POST_STORES_DELETE,
  POST_STORES_UPDATE_LOGO,
  POST_STORES_ZONE_LIST,
  POST_STORES_ZONE_ADD,
  POST_STORES_ZONE_EDIT,
  POST_STORES_ZONE_DELETE,
  POST_STORES_ZONE_ORDER,
  POST_STORES_UPDATE_ORDER_DETAILS,
  POST_STORES_LIST_WORKING_HOURS,
  POST_STORES_UPDATE_WORKING_HOURS,
  POST_STORES_CONTACT_OPTIONS_LIST,
  POST_STORES_CONTACT_OPTIONS_ADD_EDIT,
  POST_STORES_CONTACT_OPTIONS_DELETE
  } from '../actionTypes';
  import axios from '../../helpers/Api'
  import * as General from '../../helpers/General'
import { DEBUG_MODE } from '../../constants';

  
export const postStoresAll = (postData) => async dispatch => {
  //Get user locale of the machine he is on
  const userLocale = General.getLocale();
  postData.langLoc = userLocale;
  postData.fromAdmin = true;

  const response = await axios.post(`store/list`,postData);

  if (DEBUG_MODE){
    console.log(response.data);
  }

  dispatch({type: POST_STORES_ALL, payload: response.data});
};

    
export const postStoresAdd = (postData) => async dispatch => {
  //Get user locale of the machine he is on
  const userLocale = General.getLocale();
  postData.langLoc = userLocale;
  postData.user_id = General.getUserId();

  const response = await axios.post(`store/addEditStore`,postData);

  if (DEBUG_MODE){
    console.log(response.data);
  }

  dispatch({type: POST_STORES_ADD, payload: response.data});
};

export const postStoresEdit = (postData) => async dispatch => {
  //Get user locale of the machine he is on
  const userLocale = General.getLocale();
  postData.langLoc = userLocale;

  const response = await axios.post(`store/edit`,postData);

  if (DEBUG_MODE){
    console.log(response.data);
  }

  dispatch({type: POST_STORES_EDIT, payload: response.data});
};

export const postStoresDelete = (postData) => async dispatch => {
  //Get user locale of the machine he is on
  const userLocale = General.getLocale();
  postData.langLoc = userLocale;

  const response = await axios.post(`store/delete`,postData);

  if (DEBUG_MODE){
    console.log(response.data);
  }

  dispatch({type: POST_STORES_DELETE, payload: response.data});
};


export const postStoresUpdateLogo = (postData) => async dispatch => {
  //Get user locale of the machine he is on
  const userLocale = General.getLocale();
  postData.langLoc = userLocale;

  const response = await axios.post(`store/updateStoreLogo`,postData);

  if (DEBUG_MODE){
    console.log(response.data);
  }

  dispatch({type: POST_STORES_UPDATE_LOGO, payload: response.data});
};


export const postStoreZonesList = (postData) => async dispatch => {
  //Get user locale of the machine he is on
  const userLocale = General.getLocale();
  postData.langLoc = userLocale;

  const response = await axios.post(`store/zones-list`,postData);

  if (DEBUG_MODE){
    console.log(response.data);
  }

  dispatch({type: POST_STORES_ZONE_LIST, payload: response.data});
};

export const postStoreZonesAdd = (postData) => async dispatch => {
  //Get user locale of the machine he is on
  const userLocale = General.getLocale();
  postData.langLoc = userLocale;

  console.log(postData);

  const response = await axios.post(`store/zones-add`,postData);

  if (DEBUG_MODE){
    console.log(response.data);
  }

  dispatch({type: POST_STORES_ZONE_ADD, payload: response.data});
};

export const postStoreZonesEdit = (postData) => async dispatch => {
  //Get user locale of the machine he is on
  const userLocale = General.getLocale();
  postData.langLoc = userLocale;

  const response = await axios.post(`store/zones-edit`,postData);

  if (DEBUG_MODE){
    console.log(response.data);
  }

  dispatch({type: POST_STORES_ZONE_EDIT, payload: response.data});
};


export const postStoreZonesDelete = (postData) => async dispatch => {
  //Get user locale of the machine he is on
  const userLocale = General.getLocale();
  postData.langLoc = userLocale;

  const response = await axios.post(`store/zones-delete`,postData);

  if (DEBUG_MODE){
    console.log(response.data);
  }

  dispatch({type: POST_STORES_ZONE_DELETE, payload: response.data});
};


export const postStoreZonesReorder = (postData) => async dispatch => {
  //Get user locale of the machine he is on
  const userLocale = General.getLocale();
  postData.langLoc = userLocale;

  const response = await axios.post(`store/zone-change-order`,postData);

  if (DEBUG_MODE){
    console.log(response.data);
  }

  dispatch({type: POST_STORES_ZONE_ORDER, payload: response.data});
};

export const postStoreUpdateOrderDetails = (postData) => async dispatch => {
  //Get user locale of the machine he is on
  const userLocale = General.getLocale();
  postData.langLoc = userLocale;

  const response = await axios.post(`store/update-order-details`,postData);

  if (DEBUG_MODE){
    console.log(response.data);
  }

  dispatch({type: POST_STORES_UPDATE_ORDER_DETAILS, payload: response.data});
};


export const postStoreWorkingHoursList = (postData) => async dispatch => {
  //Get user locale of the machine he is on
  const userLocale = General.getLocale();
  postData.langLoc = userLocale;

  const response = await axios.post(`store/working-hours-list`,postData);

  if (DEBUG_MODE){
    console.log(response.data);
  }

  dispatch({type: POST_STORES_LIST_WORKING_HOURS, payload: response.data});
};


export const postStoreWorkingHoursUpdate = (postData) => async dispatch => {
  //Get user locale of the machine he is on
  const userLocale = General.getLocale();
  postData.langLoc = userLocale;

  const response = await axios.post(`store/working-hours-update`,postData);

  if (DEBUG_MODE){
    console.log(response.data);
  }

  dispatch({type: POST_STORES_UPDATE_WORKING_HOURS, payload: response.data});
};


export const postStoresContactOptionsAll = (postData) => async dispatch => {
  //Get user locale of the machine he is on
  const userLocale = General.getLocale();
  postData.langLoc = userLocale;
  postData.fromAdmin = true;

  const response = await axios.post(`store/contact-options/list`,postData);

  if (DEBUG_MODE){
    console.log(response.data);
  }

  dispatch({type: POST_STORES_CONTACT_OPTIONS_LIST, payload: response.data});
};

export const postStoresContactOptionsAddEdit = (postData) => async dispatch => {
  //Get user locale of the machine he is on
  const userLocale = General.getLocale();
  postData.langLoc = userLocale;
  postData.fromAdmin = true;

  const response = await axios.post(`store/contact-options/add-edit`,postData);

  if (DEBUG_MODE){
    console.log(response.data);
  }

  dispatch({type: POST_STORES_CONTACT_OPTIONS_ADD_EDIT, payload: response.data});
};

export const postStoresContactOptionsDelete = (postData) => async dispatch => {
  //Get user locale of the machine he is on
  const userLocale = General.getLocale();
  postData.langLoc = userLocale;
  postData.fromAdmin = true;

  const response = await axios.post(`store/contact-options/delete`,postData);

  if (DEBUG_MODE){
    console.log(response.data);
  }

  dispatch({type: POST_STORES_CONTACT_OPTIONS_DELETE, payload: response.data});
};