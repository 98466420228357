import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Dialog,
  DialogContent,
  DialogTitle,
  Button,
  List,
  ListItem,
  ListItemText,
  IconButton,
  TextField,
  FormControlLabel,
  Checkbox,
  Grid,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { toast } from "react-toastify";
import {
  postStoresContactOptionsAll,
  postStoresContactOptionsAddEdit,
  postStoresContactOptionsDelete,
} from "../../redux/stores/action";
import MyListPicker from "../global/listPicker/MyListPicker";
import CountryCodePicker from "../global/countries/CountryCodePicker";
import {
  POST_STORES_CONTACT_OPTIONS_ADD_EDIT,
  POST_STORES_CONTACT_OPTIONS_DELETE,
} from "../../redux/actionTypes";

const StoreContactOptionsDialog = (props) => {
  const dispatch = useDispatch();
  const settingsData = useSelector(({ settings }) => settings.settingsData);
  const contactOptionsData = useSelector(
    ({ stores }) => stores.contactOptionsData
  );
  const addEditContactOptionsData = useSelector(
    ({ stores }) => stores.addEditContactOptionsData
  );
  const deleteContactOptionsData = useSelector(
    ({ stores }) => stores.deleteContactOptionsData
  );
  const trans = settingsData.trans;

  const [contactOptions, setContactOptions] = useState([]);
  const [newContactOption, setNewContactOption] = useState({
    contact_type: "",
    value: "",
    mobileCode: "",
    verified: false,
  });

  const contactTypes = [
    { value: "PHONE", text: trans.contact_type_phone },
    { value: "MOBILE", text: trans.contact_type_mobile },
    { value: "EMAIL", text: trans.contact_type_email },
    { value: "WEBSITE", text: trans.contact_type_website },
  ];

  useEffect(() => {
    if (props.storeItem && props.open) {
      reloadData();
    }
  }, [dispatch, props.storeItem, props.open]);

  useEffect(() => {
    console.log(newContactOption);
  }, [newContactOption]);

  useEffect(() => {
    if (contactOptionsData) {
      setContactOptions(contactOptionsData.contact_options);
    }
  }, [contactOptionsData]);

  useEffect(() => {
    if (addEditContactOptionsData && props.storeItem != null) {
      if (addEditContactOptionsData.status) {
        toast.success(addEditContactOptionsData.message);
        clearData();
        reloadData();
        dispatch({ type: POST_STORES_CONTACT_OPTIONS_ADD_EDIT, payload: null });
      } else {
        toast.error(addEditContactOptionsData.message);
      }
    }
  }, [addEditContactOptionsData, dispatch, props.storeItem]);

  useEffect(() => {
    if (deleteContactOptionsData) {
      if (deleteContactOptionsData.status) {
        toast.success(deleteContactOptionsData.message);
        reloadData();
        dispatch({ type: POST_STORES_CONTACT_OPTIONS_DELETE, payload: null });
      } else {
        toast.error(deleteContactOptionsData.message);
      }
    }
  }, [deleteContactOptionsData, dispatch, props.storeItem]);

  const clearData = () => {
    setNewContactOption({
      contact_type: "",
      value: "",
      mobileCode: "",
      verified: false,
    });
  };

  const reloadData = () => {
    dispatch(postStoresContactOptionsAll({ storeId: props.storeItem.id }));
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setNewContactOption({ ...newContactOption, [name]: value });
  };

  const handleAddEditContactOption = () => {
    if (!isButtonEnabled()) {
      return;
    }

    dispatch(
      postStoresContactOptionsAddEdit({
        ...newContactOption,
        storeId: props.storeItem.id,
      })
    );
  };

  const handleDeleteContactOption = (contactId) => {
    dispatch(
      postStoresContactOptionsDelete({ storeId: props.storeItem.id, contactId })
    );
  };

  const isButtonEnabled = () => {
    if (newContactOption == null) {
      return false;
    }

    if (
      newContactOption.contact_type == null ||
      newContactOption.contact_type == ""
    ) {
      return false;
    }

    if (newContactOption.value == null || newContactOption.value == "") {
      return false;
    }

    if (
      newContactOption.contact_type == "PHONE" ||
      newContactOption.contact_type == "MOBILE"
    ) {
      if (
        newContactOption.mobileCode == null ||
        newContactOption.mobileCode == ""
      ) {
        return false;
      }
    }

    return true;
  };

  return (
    <Dialog open={props.open} onClose={props.onClose} fullWidth maxWidth="md">
      <DialogTitle>
        <div className="_flex_row_space">
          <div>{trans.manage_contact_options}</div>
          <IconButton onClick={props.onClose}>
            <CloseIcon />
          </IconButton>
        </div>
      </DialogTitle>

      <DialogContent>
        <div style={{ fontWeight: "bold" }}>{trans.contact_options_list}</div>
        <List>
          {contactOptions.map((option) => (
            <ListItem key={option.id}>
              <ListItemText
                primary={option.contact_type == "PHONE" || option.contact_type == "PHONE"  ? `${option.mobileCode}${option.value}`  :option.value}
                secondary={option.contact_type}
              />
              <IconButton onClick={() => handleDeleteContactOption(option.id)}>
                <CloseIcon />
              </IconButton>
            </ListItem>
          ))}
        </List>

        <br />
        <hr />
        <br />
        <div style={{ fontWeight: "bold" }}>{trans.contact_options_create}</div>
        <br />

        <MyListPicker
          labelId={"contact_type"}
          label={trans.contact_type}
          selected={newContactOption.contact_type}
          onChange={(value) =>
            setNewContactOption({ ...newContactOption, contact_type: value })
          }
          list={contactTypes}
        />

        {["PHONE", "MOBILE"].includes(newContactOption.contact_type) && (
          <Grid container spacing={2} alignItems="center">
            <Grid item xs={4}>
              <CountryCodePicker
                value={newContactOption.mobileCode}
                onChange={(value) =>
                  setNewContactOption({
                    ...newContactOption,
                    mobileCode: value,
                  })
                }
              />
            </Grid>
            <Grid item xs={8}>
              <TextField
                label={trans.contact_value}
                name="value"
                value={newContactOption.value}
                onChange={handleInputChange}
                fullWidth
                margin="normal"
              />
            </Grid>
          </Grid>
        )}
        {!["PHONE", "MOBILE"].includes(newContactOption.contact_type) && (
          <TextField
            label={trans.contact_value}
            name="value"
            value={newContactOption.value}
            onChange={handleInputChange}
            fullWidth
            margin="normal"
          />
        )}

        <br />
        <br />

        <div style={{ display: "flex", justifyContent: "flex-end" }}>
          <Button
            variant="contained"
            color="primary"
            onClick={handleAddEditContactOption}
            disabled={!isButtonEnabled()}
          >
            {trans.save_contact_option}
          </Button>
        </div>
      </DialogContent>
    </Dialog>
  );
};

export default StoreContactOptionsDialog;
