import {
  POST_CATALOG_LIST_ALL,
  POST_CATALOG_CATEGORY_ADD_EDIT,
  POST_CATALOG_CATEGORY_SINGLE,
  POST_CATALOG_CATEGORY_DELETE,
  POST_CATALOG_CATEGORY_LIST,
  POST_CATALOG_CATEGORY_REORDER,
  POST_CATALOG_PRODUCT_REORDER,
  POST_CATALOG_PRODUCT_SINGLE,
  POST_CATALOG_PRODUCT_ADD_EDIT,
  POST_CATALOG_PRODUCT_DELETE,
  POST_CATALOG_PRODUCT_LIST,
  POST_CATALOG_PRODUCT_ADD_IMAGE,
  POST_CATALOG_PRODUCT_DELETE_IMAGE,
  POST_CATALOG_PRODUCT_REORDER_IMAGE,
} from "../actionTypes";

const INIT_STATE = {
  catalogListData: null,
  addEditCatalogCategoryData: null,
  singleCatalogCategoryData: null,
  deleteCatalogCategoryData: null,
  listCatalogCategoryData: null,
  reorderCatalogCategoryData: null,
  reorderCatalogProductData: null,
  addEditCatalogProductData: null,
  singleCatalogProductData: null,
  listCatalogProductData: null,
  updateProductImageData: null,
  deleteProductImageData: null,
  reorderProductImageData: null,
  productDeleteData: null,
};

const reducer = (state = INIT_STATE, action) => {
  switch (action.type) {
    case POST_CATALOG_LIST_ALL:
      return {
        ...state,
        catalogListData: action.payload,
      };
    case POST_CATALOG_CATEGORY_ADD_EDIT:
      return {
        ...state,
        addEditCatalogCategoryData: action.payload,
      };
    case POST_CATALOG_CATEGORY_SINGLE:
      return {
        ...state,
        singleCatalogCategoryData: action.payload,
      };
      case POST_CATALOG_CATEGORY_DELETE:
        return {
          ...state,
          deleteCatalogCategoryData: action.payload,
        };
    case POST_CATALOG_CATEGORY_LIST:
      return {
        ...state,
        listCatalogCategoryData: action.payload,
      };
    case POST_CATALOG_CATEGORY_REORDER:
      return {
        ...state,
        reorderCatalogCategoryData: action.payload,
      };
    case POST_CATALOG_PRODUCT_REORDER:
      return {
        ...state,
        reorderCatalogProductData: action.payload,
      };
    case POST_CATALOG_PRODUCT_ADD_EDIT:
      return {
        ...state,
        addEditCatalogProductData: action.payload,
      };
    case POST_CATALOG_PRODUCT_SINGLE:
      return {
        ...state,
        singleCatalogProductData: action.payload,
      };
    case POST_CATALOG_PRODUCT_LIST:
      return {
        ...state,
        listCatalogProductData: action.payload,
      };
    case POST_CATALOG_PRODUCT_ADD_IMAGE:
      return {
        ...state,
        updateProductImageData: action.payload,
      };
    case POST_CATALOG_PRODUCT_DELETE_IMAGE:
      return {
        ...state,
        deleteProductImageData: action.payload,
      };
    case POST_CATALOG_PRODUCT_REORDER_IMAGE:
      return {
        ...state,
        reorderProductImageData: action.payload,
      };
    case POST_CATALOG_PRODUCT_DELETE:
      return {
        ...state,
        productDeleteData: action.payload,
      };
    default:
      return state;
  }
};

export default reducer;
