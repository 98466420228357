import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Container from "@mui/material/Container";
import {
  Divider,
  Grid,
  Button,
  Paper,
  List,
  ListItem,
  ListItemText,
  ListItemSecondaryAction,
  IconButton,
  Switch,
  Box,
  TextField,
} from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import AddIcon from "@mui/icons-material/Add";
import PageTitleDesc from "../global/pageTitleDesc/PageTitleDesc";
import {
  postCatalogExtrasDelete,
  postCatalogExtrasDeleteExtraItem,
  postCatalogExtrasListAll,
} from "../../redux/catalog-extras/action";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline"; // Importing Add Icon
import { showResponseMessage } from "../../utils/alerts/MyToastUtil";
// Import the AddEditCatalogExtras component
import AddEditCatalogExtras from "./AddEditCatalogExtras"; // Adjust the import path as needed
import SweetAlert from "sweetalert2";
import {
  POST_CATALOG_EXTRAS_DELETE,
  POST_CATALOG_EXTRAS_DELETE_EXTRA_ITEM,
} from "../../redux/actionTypes";
import AddEditCatalogExtrasItem from "./AddEditCatalogExtrasItem";
import { PRIMARY_COLOR } from "../../constants";

const CatalogExtrasList = () => {
  const settingsData = useSelector(({ settings }) => settings.settingsData);
  const catalogExtrasListData = useSelector(
    ({ catalogExtras }) => catalogExtras.catalogExtrasListData
  );
  const deleteCatalogExtrasData = useSelector(
    ({ catalogExtras }) => catalogExtras.deleteCatalogExtrasData
  );

  const deleteEditCatalogExtraItemData = useSelector(
    ({ catalogExtras }) => catalogExtras.deleteEditCatalogExtraItemData
  );

  //Variables
  const dispatch = useDispatch();
  const trans = settingsData.trans;

  const [extrasList, setExtrasList] = useState([]);
  const [isAddEditDialogOpen, setIsAddEditDialogOpen] = useState(false);

  const [addEditExtraId, setAddEditExtraId] = useState(null);
  const [isAddEditExtraItemDialogOpen, setIsAddEditExtraItemDialogOpen] =
    useState(false);
  const [addEditExtraItemId, setAddEditExtraItemId] = useState(null);

  const [searchQuery, setSearchQuery] = useState("");

  useEffect(() => {
    reloadData();
  }, []);

  useEffect(() => {
    if (catalogExtrasListData != null) {
      setExtrasList(catalogExtrasListData.catalog_extras);
    }
  }, [catalogExtrasListData]);

  useEffect(() => {
    if (deleteCatalogExtrasData != null) {
      showResponseMessage(deleteCatalogExtrasData);
      dispatch({ type: POST_CATALOG_EXTRAS_DELETE, payload: null });
      reloadData();
    }
  }, [deleteCatalogExtrasData]);

  useEffect(() => {
    if (deleteEditCatalogExtraItemData != null) {
      showResponseMessage(deleteEditCatalogExtraItemData);
      dispatch({ type: POST_CATALOG_EXTRAS_DELETE_EXTRA_ITEM, payload: null });
      reloadData();
    }
  }, [deleteEditCatalogExtraItemData]);

  const handleSearchChange = (event) => {
    setSearchQuery(event.target.value.toLowerCase());
  };

  const filteredExtrasList = extrasList.filter(
    (extra) =>
      extra.name.toLowerCase().includes(searchQuery) ||
      extra.description.toLowerCase().includes(searchQuery)
  );

  const reloadData = () => {
    let postData = {};
    dispatch(postCatalogExtrasListAll(postData));
  };

  const handleEdit = (e, id) => {
    e.preventDefault();
    setIsAddEditDialogOpen(true);
    setAddEditExtraId(id);
  };

  const handleDelete = (e, id) => {
    e.preventDefault();
    SweetAlert.fire({
      theme: "dark",
      title: trans.delete_record_title,
      text: trans.delete_record_desc,
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: PRIMARY_COLOR,
      confirmButtonText: trans.delete_record_button,
      cancelButtonText: trans.cancel_btn,
      reverseButtons: true,
    }).then((result) => {
      if (result.value) {
        dispatch(postCatalogExtrasDelete({ id: id }));
      }
    });
  };

  const handleToggle = (id, currentStatus) => {
    console.log("Toggle", id, !currentStatus);
    // Implement enable/disable functionality
  };

  const handleAddItem = (e, extraId) => {
    e.preventDefault();
    setIsAddEditExtraItemDialogOpen(true);
    setAddEditExtraId(extraId);
    setAddEditExtraItemId(null);
  };

  const handleAddExtra = () => {
    // Set the dialog to be visible
    setIsAddEditDialogOpen(true);
    setAddEditExtraId(null);
  };

  // Add a method to close the dialog
  const handleCloseDialog = () => {
    setIsAddEditDialogOpen(false);
    setAddEditExtraId(null);
  };

  const handleCloseExtraItemDialog = () => {
    setIsAddEditExtraItemDialogOpen(false);
    setAddEditExtraItemId(null);
  };

  const handleEditExtra = (e, extraId, extraItemId) => {
    e.preventDefault();
    setIsAddEditExtraItemDialogOpen(true);
    setAddEditExtraId(extraId);
    setAddEditExtraItemId(extraItemId);
  };

  const handleDeleteExtra = (e, extraId, extraItemId) => {
    e.preventDefault();
    SweetAlert.fire({
      theme: "dark",
      title: trans.delete_record_title,
      text: trans.delete_record_desc,
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: settingsData.initData.app_color,
      confirmButtonText: trans.delete_record_button,
      cancelButtonText: trans.cancel_btn,
      reverseButtons: true,
    }).then((result) => {
      if (result.value) {
        dispatch(
          postCatalogExtrasDeleteExtraItem({ extraId: extraId, extraItemId })
        );
      }
    });
  };

  return (
    <Container maxWidth="xl" sx={{ mt: 4, mb: 4 }}>
      <Grid container spacing={1}>
        <Grid item xs={12}>
          <Paper
            sx={{
              p: 2,
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              width: "100%",
            }}
          >
            <Box sx={{ display: "flex", alignItems: "center" }}>
              <PageTitleDesc
                title={settingsData.trans.menu_catalog_extras}
                desc={settingsData.trans.menu_catalog_extras_desc}
              />
            </Box>
            <Button
              startIcon={<AddCircleOutlineIcon />}
              onClick={handleAddExtra}
              color="primary"
              variant="contained"
              sx={{ minWidth: 160 }} // You can adjust this value as needed
            >
              {trans.menu_catalog_extras_add_extra_button}
            </Button>
          </Paper>

          <br />

          <Paper sx={{ p: 2, marginBottom: 3 }}>
            <TextField
              fullWidth
              label={trans.search_extras_field}
              variant="outlined"
              value={searchQuery}
              onChange={handleSearchChange}
              sx={{ marginBottom: 2 }}
            />
          </Paper>

          <Grid container spacing={2}>
            {filteredExtrasList.map((extra) => (
              <Grid item xs={12} sm={6} md={4} key={extra.id}>
                <Paper elevation={3} sx={{ padding: 2, minHeight: 200 }}>
                  <List>
                    <ListItem>
                      <ListItemText
                        primary={extra.name}
                        secondary={extra.description}
                      />
                    </ListItem>
                    <Button
                      startIcon={<AddIcon />}
                      onClick={(e) => handleAddItem(e, extra.id)}
                      sx={{ mx: 1 }}
                      color="inherit" // Set the button color to inherit
                    >
                      {trans.menu_catalog_extras_add_extra_item_button}
                    </Button>
                    <Button
                      startIcon={<EditIcon />}
                      onClick={(e) => handleEdit(e, extra.id)}
                      sx={{ mx: 1 }}
                      color="inherit" // Set the button color to inherit
                    >
                      {trans.menu_catalog_extras_edit_extra_item_button}
                    </Button>

                    <Button
                      style={{ color: "#f44336" }}
                      startIcon={<DeleteIcon />}
                      onClick={(e) => handleDelete(e, extra.id)}
                      sx={{ mx: 1 }}
                    >
                      {trans.menu_catalog_extras_delete_extra_item_button}
                    </Button>
                    <Divider />
                    {extra.extra_items.map((item) => (
                      <ListItem key={item.extra_id} sx={{ pl: 2 }}>
                        <ListItemText
                          primary={item.extra_extraname}
                          secondary={<div>
<div>{trans.catalog_extra_item_price}: ${item.extra_price}</div>
<div>{item.extra_fixed_price != null && item.extra_fixed_price != 0 && item.extra_fixed_price != 0.0 ?   <div
                            className="fontBook"
                            style={{ fontSize: "12px" ,color:"#EF5350" }}
                          >
                            {trans.catalog_extra_item_fixed_price}:{" "}
                            {item.extra_fixed_price}€
                          </div> : null}</div>
                          </div>}
                        
                        />
                        <ListItemSecondaryAction>
                          <IconButton
                            style={{ marginRight: "5px" }}
                            edge="end"
                            aria-label="edit"
                            onClick={(e) =>
                              handleEditExtra(e, extra.id, item.extra_id)
                            }
                          >
                            <EditIcon />
                          </IconButton>
                          <IconButton
                            style={{ color: "#f44336" }}
                            edge="end"
                            aria-label="delete"
                            onClick={(e) =>
                              handleDeleteExtra(e, extra.id, item.extra_id)
                            }
                          >
                            <DeleteIcon />
                          </IconButton>
                        </ListItemSecondaryAction>
                      </ListItem>
                    ))}
                  </List>
                </Paper>
              </Grid>
            ))}
          </Grid>

       
        </Grid>
      </Grid>

      <AddEditCatalogExtras
        itemId={addEditExtraId}
        isOpen={isAddEditDialogOpen}
        onClose={handleCloseDialog}
        onItemUpdate={() => {
          reloadData();
        }}
        // Pass other necessary props to the dialog as needed
      />

      <AddEditCatalogExtrasItem
        extraId={addEditExtraId}
        extraItemId={addEditExtraItemId}
        isOpen={isAddEditExtraItemDialogOpen}
        onClose={handleCloseExtraItemDialog}
        onItemUpdate={() => {
          reloadData();
        }}
        // Pass other necessary props to the dialog as needed
      />
    </Container>
  );
};

export default CatalogExtrasList;
