import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Autocomplete, TextField } from "@mui/material";

const CountryCodePicker = ({ value, onChange }) => {
  const settingsData = useSelector(({ settings }) => settings.settingsData);

  const [countryCodes, setCountryCodes] = useState([]);

  useEffect(() => {
    if (settingsData != null) {
      setCountryCodes(
        settingsData.country_items
      );
    }
  }, [settingsData]);

  return (
    <Autocomplete
      options={countryCodes}
      getOptionLabel={(option) => `${option.phone_code}`}
      renderOption={(props, option) => (
        <li {...props}>
          <img
            src={option.icon}
            alt=""
            style={{
              marginRight: "10px",
              width: 20,
              height: 15,
            }}
          />
          {`${option.phone_code}`}
        </li>
      )}
      value={countryCodes.find((option) => option.phone_code === value) || null}
      onChange={(event, newValue) => {
        onChange(newValue ? newValue.phone_code : "");
      }}
      renderInput={(params) => (
        <TextField {...params} label="Mobile Code" variant="outlined"      margin="normal" />
      )}
    />
  );
};

export default CountryCodePicker;
