// ADMIN ARGUMENTS

// APP SETTINGS
export const APP_ID = "APP_ID";
export const USER_LOCALE = "USER_LOCALE";

// ADMIN ARGUMENTS

//SETTINGS
export const GET_SETTINGS = "GET_SETTINGS";
export const ADD_EDIT_INIT_DATA = "ADD_EDIT_INIT_DATA";
export const GET_COUNTRIES = "GET_COUNTRIES";
export const BULK_EDIT_INIT_DATA = "BULK_EDIT_INIT_DATA";

//SCRIPTS
export const CATALOG_IMPORT_DATA = "CATALOG_IMPORT_DATA";
export const PRODUCT_EXTRA_IMPORT_DATA = "PRODUCT_EXTRA_IMPORT_DATA";

//USER
export const USER_TOKEN_SET = "USER_TOKEN_SET";
export const USER_ID_SET = "USER_ID_SET";
export const POST_SIGN_IN = "POST_SIGN_IN";
export const POST_SIGN_OUT = "POST_SIGN_OUT";
export const POST_USER_INFO = "POST_USER_INFO";
export const POST_USER_SINGLE = "POST_USER_SINGLE";
export const POST_CREATE_ACCOUNTS = "POST_CREATE_ACCOUNTS";
export const POST_USER_CHANGE_PASSWORD = "POST_USER_CHANGE_PASSWORD";
export const POST_USER_EDIT_USER_TYPES = "POST_USER_EDIT_USER_TYPES";
export const POST_USER_TYPE_ADMIN = "POST_USER_TYPE_ADMIN";
export const POST_USER_TYPE_MANAGER = "POST_USER_TYPE_MANAGER";
export const POST_USER_TYPE_STORE_USER = "POST_USER_TYPE_STORE_USER";

//CATALOG
export const POST_CATALOG_LIST_ALL = "POST_CATALOG_LIST_ALL";
export const POST_CATALOG_CATEGORY_SINGLE = "POST_CATALOG_CATEGORY_SINGLE";
export const POST_CATALOG_CATEGORY_ADD_EDIT = "POST_CATALOG_CATEGORY_ADD_EDIT";
export const POST_CATALOG_CATEGORY_DELETE = "POST_CATALOG_CATEGORY_DELETE";
export const POST_CATALOG_CATEGORY_LIST = "POST_CATALOG_CATEGORY_LIST";
export const POST_CATALOG_CATEGORY_REORDER = "POST_CATALOG_CATEGORY_REORDER";
export const POST_CATALOG_PRODUCT_REORDER = "POST_CATALOG_PRODUCT_REORDER";
export const POST_CATALOG_PRODUCT_SINGLE = "POST_CATALOG_PRODUCT_SINGLE";
export const POST_CATALOG_PRODUCT_ADD_EDIT = "POST_CATALOG_PRODUCT_ADD_EDIT";
export const POST_CATALOG_PRODUCT_DELETE = "POST_CATALOG_PRODUCT_DELETE";
export const POST_CATALOG_PRODUCT_LIST = "POST_CATALOG_PRODUCT_LIST";
export const POST_CATALOG_PRODUCT_ADD_IMAGE = "POST_CATALOG_PRODUCT_ADD_IMAGE";
export const POST_CATALOG_PRODUCT_DELETE_IMAGE =
  "POST_CATALOG_PRODUCT_DELETE_IMAGE";
export const POST_CATALOG_PRODUCT_REORDER_IMAGE =
  "POST_CATALOG_PRODUCT_REORDER_IMAGE";

//CATALOG EXTRA
export const POST_CATALOG_EXTRAS_LIST_ALL = "POST_CATALOG_EXTRAS_LIST_ALL";
export const POST_CATALOG_EXTRAS_ADD_EDIT = "POST_CATALOG_EXTRAS_ADD_EDIT";
export const POST_CATALOG_EXTRAS_SINGLE = "POST_CATALOG_EXTRAS_SINGLE";
export const POST_CATALOG_EXTRAS_DELETE = "POST_CATALOG_EXTRAS_DELETE";
export const POST_CATALOG_EXTRAS_ADD_EXTRA_ITEM =
  "POST_CATALOG_EXTRAS_ADD_EXTRA_ITEM";
export const POST_CATALOG_EXTRAS_DELETE_EXTRA_ITEM =
  "POST_CATALOG_EXTRAS_DELETE_EXTRA_ITEM";
export const POST_ADD_EDIT_PRODUCT_EXTRA = "POST_ADD_EDIT_PRODUCT_EXTRA";
export const POST_BULK_EDIT_PRODUCT_EXTRA = "POST_BULK_EDIT_PRODUCT_EXTRA";

//CATALOG OFFERS
export const POST_CATALOG_OFFERS_LIST_ALL = "POST_CATALOG_OFFERS_LIST_ALL";
export const POST_CATALOG_OFFERS_ADD_EDIT = "POST_CATALOG_OFFERS_ADD_EDIT";
export const POST_CATALOG_OFFERS_DELETE = "POST_CATALOG_OFFERS_DELETE";
export const POST_CATALOG_OFFERS_SINGLE = "POST_CATALOG_OFFERS_SINGLE";
export const POST_CATALOG_OFFERS_EDIT_PRODUCTS =
  "POST_CATALOG_OFFERS_EDIT_PRODUCTS";

//STORES
export const POST_STORES_ALL = "POST_STORES_ALL";
export const POST_STORES_ADD = "POST_STORES_ADD";
export const POST_STORES_EDIT = "POST_STORES_EDIT";
export const POST_STORES_DELETE = "POST_STORES_DELETE";
export const POST_STORES_UPDATE_LOGO = "POST_STORES_UPDATE_LOGO";
export const POST_STORES_ZONE_LIST = "POST_STORES_ZONE_LIST";
export const POST_STORES_ZONE_ADD = "POST_STORES_ZONE_ADD";
export const POST_STORES_ZONE_EDIT = "POST_STORES_ZONE_EDIT";
export const POST_STORES_ZONE_DELETE = "POST_STORES_ZONE_DELETE";
export const POST_STORES_ZONE_ORDER = "POST_STORES_ZONE_ORDER";
export const POST_STORES_UPDATE_ORDER_DETAILS =
  "POST_STORES_UPDATE_ORDER_DETAILS";
export const POST_STORES_LIST_WORKING_HOURS = "POST_STORES_LIST_WORKING_HOURS";
export const POST_STORES_UPDATE_WORKING_HOURS =
  "POST_STORES_UPDATE_WORKING_HOURS";
export const POST_STORES_CONTACT_OPTIONS_LIST =
  "POST_STORES_CONTACT_OPTIONS_LIST";
export const POST_STORES_CONTACT_OPTIONS_ADD_EDIT =
  "POST_STORES_CONTACT_OPTIONS_ADD_EDIT";
export const POST_STORES_CONTACT_OPTIONS_DELETE =
  "POST_STORES_CONTACT_OPTIONS_ADD_EDIT";

//SIDE-MENU
export const POST_SIDE_MENU_ALL = "POST_SIDE_MENU_ALL";
export const POST_SIDE_MENU_ADD = "POST_SIDE_MENU_ADD";
export const POST_SIDE_MENU_EDIT = "POST_SIDE_MENU_EDIT";
export const POST_SIDE_MENU_DELETE = "POST_SIDE_MENU_DELETE";
export const POST_SIDE_MENU_REORDER = "POST_SIDE_MENU_REORDER";

//ORDERS
export const POST_ORDERS_ALL = "POST_ORDERS_ALL";
export const POST_ORDERS_SINGLE = "POST_ORDERS_SINGLE";
export const POST_ORDERS_ANSWER = "POST_ORDERS_ANSWER";
// export const POST_ORDERS_DELETE = "POST_ORDERS_DELETE";
//export const POST_ORDERS_REORDER = "POST_ORDERS_REORDER";

//COUPONS
export const POST_COUPONS_LIST_ALL = "POST_COUPONS_LIST_ALL";
export const POST_COUPONS_ADD_EDIT = "POST_COUPONS_ADD_EDIT";
export const POST_COUPONS_DELETE = "POST_COUPONS_DELETE";
export const POST_COUPONS_SINGLE = "POST_COUPONS_SINGLE";

//USERS
export const POST_USERS_ALL = "POST_USERS_ALL";
export const POST_USERS_ADD = "POST_USERS_ADD";
export const POST_USERS_EDIT = "POST_USERS_EDIT";
export const POST_USERS_CHANGE_APPROVED = "POST_USERS_CHANGE_APPROVED";
export const POST_USERS_CHANGE_ACCESS_PACKAGES =
  "POST_USERS_CHANGE_ACCESS_PACKAGES";
export const POST_USERS_CHANGE_COMPANIES = "POST_USERS_CHANGE_COMPANIES";
export const POST_USERS_CHANGE_ROUTES = "POST_USERS_CHANGE_ROUTES";

//CATEGORIES
export const POST_CATEGORIES_ALL = "POST_CATEGORIES_ALL";
export const POST_CATEGORIES_ADD = "POST_CATEGORIES_ADD";
export const POST_CATEGORIES_EDIT = "POST_CATEGORIES_EDIT";
export const POST_CATEGORIES_DELETE = "POST_CATEGORIES_DELETE";

//UPLOAD
export const POST_UPLOAD_IMAGE = "POST_UPLOAD_IMAGE";
export const POST_UPLOAD_FILE = "POST_UPLOAD_FILE";

//BANNERS
export const POST_BANNER_ALL = "POST_BANNER_ALL";
export const POST_BANNER_ADD = "POST_BANNER_ADD";
export const POST_BANNER_EDIT = "POST_BANNER_EDIT";
export const POST_BANNER_DELETE = "POST_BANNER_DELETE";
export const POST_BANNER_EDIT_ORDER = "POST_BANNER_EDIT_ORDER";
export const POST_BANNER_DELETE_IMAGE = "POST_BANNER_DELETE_IMAGE";
export const POST_BANNER_SINGLE = "POST_BANNER_SINGLE";

//NOTIFICATIONS
export const POST_NOTIFICATION_ALL = "POST_NOTIFICATION_ALL";
export const POST_NOTIFICATION_ADD = "POST_NOTIFICATION_ADD";

//BANNERS
export const POST_COUNTRY_ALL = "POST_COUNTRY_ALL";
export const POST_COUNTRY_EDIT_FUEL = "POST_COUNTRY_EDIT_FUEL";

//CONTACT
export const POST_CONTACT_ALL = "POST_CONTACT_ALL";
export const POST_ACCOUNT_CONTACT_OTPION_ADD =
  "POST_ACCOUNT_CONTACT_OTPION_ADD";
export const POST_ACCOUNT_CONTACT_OTPION_DELETE =
  "POST_ACCOUNT_CONTACT_OTPION_DELETE";


//ADMIN
export const POST_EXPIRED_USERS = "POST_EXPIRED_USERS";
export const POST_DASHBOARD_MAIN = "POST_DASHBOARD_MAIN";

//USER REVIEWS
export const POST_USER_REVIEW_ALL = "POST_USER_REVIEW_ALL";
