export const getDefaultLanguage = (languagesList) => {
    var languageItem = null;

    languagesList.forEach(element => {
        if (element.is_default == true){
            languageItem = element;
        }
    });
    
    return languageItem;
}