import React, { useRef, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Container from "@mui/material/Container";
import {
  Divider,
  Grid,
  Button,
  Paper,
  List,
  ListItem,
  ListItemText,
  TextField,
} from "@mui/material";
import PageTitleDesc from "../global/pageTitleDesc/PageTitleDesc";
import StoresPicker from "../stores/StoresPicker";
import AddCatalogCategory from "./AddCatalogCategory";
import SweetAlert from "sweetalert2";
import { SortableGroupedList } from "./catalogItems/SortableGroupedList";
import {
  postCatalogCategoryDelete,
  postCatalogCategoryReorder,
  postCatalogMenuListAll,
  postCatalogProductAddImage,
  postCatalogProductDelete,
  postCatalogProductDeleteImage,
  postCatalogProductReorder,
  postCatalogProductReorderImage,
  postCatalogProductSingle,
} from "../../redux/catalog/action";
import {
  SortableContainer,
  SortableElement,
  arrayMove,
} from "react-sortable-hoc";
import {
  POST_CATALOG_CATEGORY_DELETE,
  POST_CATALOG_CATEGORY_REORDER,
  POST_CATALOG_PRODUCT_ADD_IMAGE,
  POST_CATALOG_PRODUCT_DELETE,
  POST_CATALOG_PRODUCT_DELETE_IMAGE,
  POST_CATALOG_PRODUCT_REORDER_IMAGE,
} from "../../redux/actionTypes";
import AddCatalogProduct from "./AddCatalogProduct";
import {
  EDIT_PRODUCT_TYPE_DELETE,
  EDIT_PRODUCT_TYPE_EDIT,
  EDIT_PRODUCT_TYPE_EXTRAS,
} from "./catalogItems/SortableProduct";
import ProductImagesDialog from "./ProductImagesDialog";
import { showResponseMessage } from "../../utils/alerts/MyToastUtil";
import ProductExtrasDialog from "./ProductExtrasDialog";
import { PRIMARY_COLOR } from "../../constants";

const CatalogMenuList = () => {
  //Selectors
  const settingsData = useSelector(({ settings }) => settings.settingsData);
  const catalogListData = useSelector(({ catalog }) => catalog.catalogListData);
  const reorderCatalogCategoryData = useSelector(
    ({ catalog }) => catalog.reorderCatalogCategoryData
  );
  const reorderCatalogProductData = useSelector(
    ({ catalog }) => catalog.reorderCatalogProductData
  );
  const deleteProductImageData = useSelector(
    ({ catalog }) => catalog.deleteProductImageData
  );
  const updateProductImageData = useSelector(
    ({ catalog }) => catalog.updateProductImageData
  );
  const singleCatalogProductData = useSelector(
    ({ catalog }) => catalog.singleCatalogProductData
  );
  const reorderProductImageData = useSelector(
    ({ catalog }) => catalog.reorderProductImageData
  );
  const productDeleteData = useSelector(
    ({ catalog }) => catalog.productDeleteData
  );
  const userTypeStoreUser = useSelector(({ auth }) => auth.userTypeStoreUser);
 
  const deleteCatalogCategoryData = useSelector(
    ({ catalog }) => catalog.deleteCatalogCategoryData
  );

  //Variables
  const dispatch = useDispatch();
  const trans = settingsData.trans;

  //Hooks
  const [groupedProductsList, setGroupedProductsList] = useState([]);
  const [filteredProductsList, setFilteredProductsList] = useState([]);
  const [selectedStore, setSelectedStore] = useState(null);
  const [addCategoryModal, setAddCategoryModal] = useState(false);
  const [categoryEditItem, setCategoryEditItem] = useState(null);

  const [addProductModal, setAddProductModal] = useState(false);
  const [productImagesModal, setProductImagesModal] = useState(false);
  const [productExtrasModal, setProductExtrasModal] = useState(false);
  const [productEditItem, setProductEditItem] = useState(null);
  const [productAddCategory, setProductAddCategory] = useState(null);

  const [searchTerm, setSearchTerm] = useState("");

  // Create a ref for ProductImagesDialog
  const productImagesDialogRef = useRef();

  useEffect(() => {
    if (selectedStore != null) {
      reloadData();
    }
  }, [selectedStore]);

  useEffect(() => {
    if (catalogListData) {
      setGroupedProductsList(catalogListData.groupedProducts);
      setFilteredProductsList(catalogListData.groupedProducts);
    }
  }, [catalogListData]);

  useEffect(() => {
    if (reorderCatalogCategoryData != null) {
      dispatch({ type: POST_CATALOG_CATEGORY_REORDER, payload: null });
    }
  }, [reorderCatalogCategoryData]);

  useEffect(() => {
    if (deleteProductImageData != null) {
      showResponseMessage(deleteProductImageData);
      dispatch({ type: POST_CATALOG_PRODUCT_DELETE_IMAGE, payload: null });
      reloadData();
    }
  }, [deleteProductImageData]);

  useEffect(() => {
    if (updateProductImageData != null) {
      showResponseMessage(updateProductImageData);
      if (updateProductImageData.status) {
        // Here you call onImageAdded via the ref
        productImagesDialogRef.current.onImageAdded();
        reloadProductData();
      }
      dispatch({ type: POST_CATALOG_PRODUCT_ADD_IMAGE, payload: null });
    }
  }, [updateProductImageData]);

  useEffect(() => {
    if (reorderProductImageData != null) {
      if (reorderProductImageData.status) {
        reloadProductData();
      }
      dispatch({ type: POST_CATALOG_PRODUCT_REORDER_IMAGE, payload: null });
    }
  }, [reorderProductImageData]);

  useEffect(() => {
    if (productDeleteData != null) {
      if (productDeleteData.status) {
        showResponseMessage(productDeleteData);
        reloadData();
      }
      dispatch({ type: POST_CATALOG_PRODUCT_DELETE, payload: null });
    }
  }, [productDeleteData]);


  useEffect(() => {
    if (deleteCatalogCategoryData != null) {
      if (deleteCatalogCategoryData.status) {
        showResponseMessage(deleteCatalogCategoryData);
        reloadData();
      }
      dispatch({ type: POST_CATALOG_CATEGORY_DELETE, payload: null });
    }
  }, [deleteCatalogCategoryData]);

  

  useEffect(() => {
    if (productEditItem != null) {
      if (singleCatalogProductData != null) {
        setProductEditItem(singleCatalogProductData);
      }
    }
  }, [singleCatalogProductData]);

  useEffect(() => {
    const lowercasedFilter = searchTerm.toLowerCase();
    const filteredData = groupedProductsList.filter((group) => {
      const categoryMatch = group.group_category.name
        .toLowerCase()
        .includes(lowercasedFilter);
      const productMatch = group.group_products.some((product) =>
        product.name.toLowerCase().includes(lowercasedFilter)
      );
      return categoryMatch || productMatch;
    });
    setFilteredProductsList(filteredData);
  }, [searchTerm, groupedProductsList]);

  const reloadProductData = () => {
    if (productEditItem != null) {
      reloadData();
      let postData = {
        id: productEditItem.id,
      };

      dispatch(postCatalogProductSingle(postData));
    }
  };

  const getData = () => {
    dispatch(
      postCatalogMenuListAll({ fromAdmin: true, storeId: selectedStore })
    );
  };

  const reloadData = () => {
    getData();
  };

  const onCategoryEdit = (e, groupItem) => {
    e.preventDefault();
    setCategoryEditItem(groupItem.group_category);
    setAddCategoryModal(true);
  };

  const onAddCatalogCategoryClose = () => {
    setAddCategoryModal(false);
    setCategoryEditItem(null);
  };

  const onCategoryAdd = (e) => {
    e.preventDefault();
    setCategoryEditItem(null);
    setAddCategoryModal(true);
  };

  const onSortEndCategories = ({ oldIndex, newIndex }) => {
    const newList = arrayMove(groupedProductsList, oldIndex, newIndex);
    setGroupedProductsList(newList);
    setFilteredProductsList(newList);

    var orderedCategories = [];
    newList.forEach((element, index) => {
      orderedCategories.push({
        id: element.group_category.id,
        order: index + 1,
      });
    });

    let postData = {
      orderedCategories,
    };

    dispatch(postCatalogCategoryReorder(postData));
  };

  const onSortEndProducts = (groupIndex, { oldIndex, newIndex }) => {
    const newGroups = [...groupedProductsList];
    const newProducts = arrayMove(
      newGroups[groupIndex].group_products,
      oldIndex,
      newIndex
    );
    newGroups[groupIndex].group_products = newProducts;
    setGroupedProductsList(newGroups);
    setFilteredProductsList(newGroups);
    // Here, implement any updates to your backend or store

    var orderProducts = [];
    newProducts.forEach((element, index) => {
      orderProducts.push({
        id: element.id,
        order: index + 1,
      });
    });

    let postData = {
      orderProducts,
    };

    dispatch(postCatalogProductReorder(postData));
  };

  const onEditProduct = (productItem, editType) => {
    setProductAddCategory(null);
    if (editType == EDIT_PRODUCT_TYPE_EDIT) {
      setProductEditItem(productItem);
      setAddProductModal(true);
    } else if (editType == EDIT_PRODUCT_TYPE_EXTRAS) {
      setProductEditItem(productItem);
      setProductExtrasModal(true);
    } else if (editType == EDIT_PRODUCT_TYPE_DELETE) {
      openDeleteDialog(productItem);
    } else {
      setProductEditItem(productItem);
      setProductImagesModal(true);
    }
  };

  const onAddCatalogProductClose = () => {
    setProductEditItem(null);
    setProductAddCategory(null);
    setAddProductModal(false);
  };

  const onProductAddToCategory = (e, group) => {
    e.preventDefault();
    setProductEditItem(null);
    setProductAddCategory(group.group_category);
    setAddProductModal(true);
  };

  const onProductImageClose = () => {
    setProductEditItem(null);
    setProductAddCategory(null);
    setProductImagesModal(false);
  };

  const addProductImage = (postData) => {
    dispatch(postCatalogProductAddImage(postData));
  };

  const deleteProductImage = (image, productId) => {
    dispatch(postCatalogProductDeleteImage({ productId, imageId: image.id }));
  };

  const reorderProductImage = (orderedImages, itemId) => {
    dispatch(
      postCatalogProductReorderImage({
        reorderedImages: orderedImages,
        productId: itemId,
      })
    );
  };

  const onProductExtrasClose = () => {
    setProductEditItem(null);
    setProductAddCategory(null);
    setProductExtrasModal(false);
  };

  const openDeleteDialog = (productItem) => {
    SweetAlert.fire({
      theme: "dark",
      title: trans.product_delete_title,
      text: trans.product_delete_desc,
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: PRIMARY_COLOR,
      confirmButtonText: trans.product_delete_confirm,
      cancelButtonText: trans.product_delete_cancel,
      reverseButtons: true,
    }).then((result) => {
      if (result.value) {
        dispatch(postCatalogProductDelete({ id: productItem.id }));
      }
    });
  };

  const onCategoryDelete = (e, groupItem) => {
    e.preventDefault();
    if (groupItem != null && groupItem.group_category != null) {
      SweetAlert.fire({
        theme: "dark",
        title: trans.category_delete_title,
        text: trans.category_delete_desc,
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: PRIMARY_COLOR,
        confirmButtonText: trans.category_delete_confirm,
        cancelButtonText: trans.category_delete_cancel,
        reverseButtons: true,
      }).then((result) => {
        if (result.value) {
          dispatch(
            postCatalogCategoryDelete({ id: groupItem.group_category.id })
          );
        }
      });
    }
  };
  

  return (
    <Container maxWidth="xl" sx={{ mt: 4, mb: 4 }}>
      <Grid container spacing={1}>
        <Grid item xs={12}>
          <Paper
            sx={{
              p: 2,
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              width: "100%", // Ensure the Paper takes full width
            }}
          >
            <div style={{ width: "100%" }}>
              {" "}
              {/* Make sure this div also takes full width */}
              <PageTitleDesc
                title={settingsData.trans.menu_catalog}
                desc={settingsData.trans.menu_catalog_desc}
              />
            </div>

            <div
              style={{ width: "100%", marginTop: "16px", marginBottom: "16px" }}
            >
              <StoresPicker
                onStoreSelect={(selectedStores) => {
                  setSelectedStore(selectedStores);
                }}
                multiple={false}
                autoSelectFirst={true}
                showOnlyUserStores={userTypeStoreUser == true}
                style={{ width: "100%" }} // Style the StoresPicker to take full width
              />
            </div>
          </Paper>
        </Grid>

        <Grid item xs={12}>
          <Paper
            sx={{
              p: 2,
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              width: "100%",
            }}
          >
            <Grid
              container
              spacing={1}
              justifyContent={"space-evenly"}
              alignItems={"center"}
              justifyItems={"space-evenly"}
            >
              <Grid item xs={10}>
                <TextField
                  fullWidth
                  label={trans.search_categories_products}
                  variant="outlined"
                  value={searchTerm}
                  onChange={(e) => setSearchTerm(e.target.value)}
                />
              </Grid>
              <Grid item xs={2}>
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <Button
                    onClick={(e) => {
                      onCategoryAdd(e);
                    }}
                    color="primary"
                    variant="contained"
                  >
                    {trans.catalog_menu_category_add}
                  </Button>
                </div>
              </Grid>
            </Grid>
          </Paper>
        </Grid>

        <SortableGroupedList
          items={filteredProductsList}
          onSortEnd={onSortEndCategories}
          onCategoryEdit={onCategoryEdit}
          onProductAddToCategory={onProductAddToCategory}
          trans={trans}
          useDragHandle={false}
          onSortEndProducts={onSortEndProducts}
          onEditProduct={onEditProduct}
          onCategoryDelete={onCategoryDelete}
        />
      </Grid>

      <AddCatalogCategory
        open={addCategoryModal}
        editCategoryItem={categoryEditItem}
        onClose={() => {
          onAddCatalogCategoryClose();
        }}
        onDataUpdated={() => {
          reloadData();
        }}
      />

      <AddCatalogProduct
        open={addProductModal}
        editProductItem={productEditItem}
        onClose={() => {
          onAddCatalogProductClose();
        }}
        onDataUpdated={() => {
          reloadData();
        }}
        selectedStores={selectedStore != null ? [selectedStore.id] : []}
        productAddCategory={productAddCategory}
      />

      <ProductImagesDialog
        itemId={productEditItem != null ? productEditItem.id : null}
        ref={productImagesDialogRef}
        open={productImagesModal}
        imagesData={
          productEditItem != null ? productEditItem.catalog_images : []
        }
        productTitle={productEditItem != null ? productEditItem.name : ""}
        onClose={() => {
          onProductImageClose();
        }}
        onDataUpdated={() => {
          reloadData();
        }}
        deleteItemImage={(image, productId) => {
          deleteProductImage(image, productId);
        }}
        onItemsReorder={(orderImages, productId) => {
          reorderProductImage(orderImages, productId);
        }}
        onAddImage={(postData) => {
          addProductImage(postData);
        }}
      />

      <ProductExtrasDialog
        itemId={productEditItem != null ? productEditItem.id : null}
        productItem={productEditItem}
        open={productExtrasModal}
        onClose={() => {
          onProductExtrasClose();
        }}
        onDataUpdated={() => {
          reloadData();
        }}
      />
    </Container>
  );
};

export default CatalogMenuList;
