import {
  POST_CATALOG_EXTRAS_LIST_ALL,
  POST_CATALOG_EXTRAS_ADD_EDIT,
  POST_CATALOG_EXTRAS_DELETE,
  POST_CATALOG_EXTRAS_SINGLE,
  POST_CATALOG_EXTRAS_ADD_EXTRA_ITEM,
  POST_CATALOG_EXTRAS_DELETE_EXTRA_ITEM,
  POST_ADD_EDIT_PRODUCT_EXTRA,
  POST_BULK_EDIT_PRODUCT_EXTRA
  } from '../actionTypes';
  import axios from '../../helpers/Api'
  import * as General from '../../helpers/General'
import { DEBUG_MODE } from '../../constants';
  
export const postCatalogExtrasListAll = (postData) => async dispatch => {
  //Get user locale of the machine he is on
  const userLocale = General.getLocale();
  postData.langLoc = userLocale;
  postData.fromAdmin = true;

  const response = await axios.post(`catalog-extra/list`,postData);

  if (DEBUG_MODE){
    console.log(response.data);
  }

  dispatch({type: POST_CATALOG_EXTRAS_LIST_ALL, payload: response.data});
};


export const postCatalogExtrasListAddEdit = (postData) => async dispatch => {
  //Get user locale of the machine he is on
  const userLocale = General.getLocale();
  postData.langLoc = userLocale;
  postData.fromAdmin = true;

  const response = await axios.post(`catalog-extra/addEdit`,postData);

  if (DEBUG_MODE){
    console.log(response.data);
  }

  dispatch({type: POST_CATALOG_EXTRAS_ADD_EDIT, payload: response.data});
};

export const postCatalogExtrasSingle = (postData) => async dispatch => {
  //Get user locale of the machine he is on
  const userLocale = General.getLocale();
  postData.langLoc = userLocale;
  postData.fromAdmin = true;

  const response = await axios.post(`catalog-extra/single`,postData);

  if (DEBUG_MODE){
    console.log(response.data);
  }

  dispatch({type: POST_CATALOG_EXTRAS_SINGLE, payload: response.data});
};


export const postCatalogExtrasDelete = (postData) => async dispatch => {
  //Get user locale of the machine he is on
  const userLocale = General.getLocale();
  postData.langLoc = userLocale;
  postData.fromAdmin = true;

  const response = await axios.post(`catalog-extra/delete`,postData);

  if (DEBUG_MODE){
    console.log(response.data);
  }

  dispatch({type: POST_CATALOG_EXTRAS_DELETE, payload: response.data});
};


export const postCatalogExtrasAddExtraItem = (postData) => async dispatch => {
  //Get user locale of the machine he is on
  const userLocale = General.getLocale();
  postData.langLoc = userLocale;
  postData.fromAdmin = true;

  const response = await axios.post(`catalog-extra/add-or-edit-extra-item`,postData);

  if (DEBUG_MODE){
    console.log(response.data);
  }

  dispatch({type: POST_CATALOG_EXTRAS_ADD_EXTRA_ITEM, payload: response.data});
};


export const postCatalogExtrasDeleteExtraItem = (postData) => async dispatch => {
  //Get user locale of the machine he is on
  const userLocale = General.getLocale();
  postData.langLoc = userLocale;
  postData.fromAdmin = true;

  const response = await axios.post(`catalog-extra/delete-extra-item`,postData);

  if (DEBUG_MODE){
    console.log(response.data);
  }

  dispatch({type: POST_CATALOG_EXTRAS_DELETE_EXTRA_ITEM, payload: response.data});
};

export const postAddEditProductExtra = (postData) => async dispatch => {
  //Get user locale of the machine he is on
  const userLocale = General.getLocale();
  postData.langLoc = userLocale;
  postData.fromAdmin = true;

  const response = await axios.post(`product/update-extra-features`,postData);

  if (DEBUG_MODE){
    console.log(response.data);
  }

  dispatch({type: POST_ADD_EDIT_PRODUCT_EXTRA, payload: response.data});
};

export const postBulkEditProductExtra = (postData) => async dispatch => {
  //Get user locale of the machine he is on
  const userLocale = General.getLocale();
  postData.langLoc = userLocale;
  postData.fromAdmin = true;

  const response = await axios.post(`product/bulk-update-extra-features`,postData);

  if (DEBUG_MODE){
    console.log(response.data);
  }

  dispatch({type: POST_BULK_EDIT_PRODUCT_EXTRA, payload: response.data});
};





// export const postCatalogCategorySingle = (postData) => async dispatch => {
//   //Get user locale of the machine he is on
//   const userLocale = General.getLocale();
//   postData.langLoc = userLocale;
//   postData.fromAdmin = true;

//   const response = await axios.post(`catalog-category/single`,postData);

//   if (DEBUG_MODE){
//     console.log(response.data);
//   }

//   dispatch({type: POST_CATALOG_CATEGORY_SINGLE, payload: response.data});
// };

// export const postCatalogCategoryAddEdit = (postData) => async dispatch => {
//   //Get user locale of the machine he is on
//   const userLocale = General.getLocale();
//   postData.langLoc = userLocale;
//   postData.fromAdmin = true;

//   const response = await axios.post(`catalog-category/addEdit`,postData);

//   if (DEBUG_MODE){
//     console.log(response.data);
//   }

//   dispatch({type: POST_CATALOG_CATEGORY_ADD_EDIT, payload: response.data});
// };

// export const postCatalogCategoryDelete = (postData) => async dispatch => {
//   //Get user locale of the machine he is on
//   const userLocale = General.getLocale();
//   postData.langLoc = userLocale;
//   postData.fromAdmin = true;

//   const response = await axios.post(`catalog-category/delete`,postData);

//   if (DEBUG_MODE){
//     console.log(response.data);
//   }

//   dispatch({type: POST_CATALOG_CATEGORY_DELETE, payload: response.data});
// };

// export const postCatalogCategoryList = (postData) => async dispatch => {
//   //Get user locale of the machine he is on
//   const userLocale = General.getLocale();
//   postData.langLoc = userLocale;
//   postData.fromAdmin = true;

//   const response = await axios.post(`catalog-category/list`,postData);

//   if (DEBUG_MODE){
//     console.log(response.data);
//   }

//   dispatch({type: POST_CATALOG_CATEGORY_LIST, payload: response.data});
// };

// export const postCatalogCategoryReorder = (postData) => async dispatch => {
//   //Get user locale of the machine he is on
//   const userLocale = General.getLocale();
//   postData.langLoc = userLocale;
//   postData.fromAdmin = true;

//   const response = await axios.post(`catalog-category/reorder`,postData);

//   if (DEBUG_MODE){
//     console.log(response.data);
//   }

//   dispatch({type: POST_CATALOG_CATEGORY_REORDER, payload: response.data});
// };

// export const postCatalogProductReorder = (postData) => async dispatch => {
//   //Get user locale of the machine he is on
//   const userLocale = General.getLocale();
//   postData.langLoc = userLocale;
//   postData.fromAdmin = true;

//   const response = await axios.post(`product/reorder`,postData);

//   if (DEBUG_MODE){
//     console.log(response.data);
//   }

//   dispatch({type: POST_CATALOG_PRODUCT_REORDER, payload: response.data});
// };

// export const postCatalogProductSingle = (postData) => async dispatch => {
//   //Get user locale of the machine he is on
//   const userLocale = General.getLocale();
//   postData.langLoc = userLocale;
//   postData.fromAdmin = true;

//   const response = await axios.post(`product/single`,postData);

//   if (DEBUG_MODE){
//     console.log(response.data);
//   }

//   dispatch({type: POST_CATALOG_PRODUCT_SINGLE, payload: response.data});
// };

// export const postCatalogProductAddEdit = (postData) => async dispatch => {
//   //Get user locale of the machine he is on
//   const userLocale = General.getLocale();
//   postData.langLoc = userLocale;
//   postData.fromAdmin = true;

//   const response = await axios.post(`product/addEdit`,postData);

//   if (DEBUG_MODE){
//     console.log(response.data);
//   }

//   dispatch({type: POST_CATALOG_PRODUCT_ADD_EDIT, payload: response.data});
// };


// export const postCatalogProductList = (postData) => async dispatch => {
//   //Get user locale of the machine he is on
//   const userLocale = General.getLocale();
//   postData.langLoc = userLocale;
//   postData.fromAdmin = true;

//   const response = await axios.post(`product/list`,postData);

//   if (DEBUG_MODE){
//     console.log(response.data);
//   }

//   dispatch({type: POST_CATALOG_PRODUCT_LIST, payload: response.data});
// };

// export const postCatalogProductAddImage = (postData) => async dispatch => {
//   //Get user locale of the machine he is on
//   const userLocale = General.getLocale();
//   postData.langLoc = userLocale;
//   postData.fromAdmin = true;

//   const response = await axios.post(`product/addProductImage`,postData);

//   if (DEBUG_MODE){
//     console.log(response.data);
//   }

//   dispatch({type: POST_CATALOG_PRODUCT_ADD_IMAGE, payload: response.data});
// };

// export const postCatalogProductDeleteImage = (postData) => async dispatch => {
//   //Get user locale of the machine he is on
//   const userLocale = General.getLocale();
//   postData.langLoc = userLocale;
//   postData.fromAdmin = true;

//   const response = await axios.post(`product/deleteProductImage`,postData);

//   if (DEBUG_MODE){
//     console.log(response.data);
//   }

//   dispatch({type: POST_CATALOG_PRODUCT_DELETE_IMAGE, payload: response.data});
// };

// export const postCatalogProductReorderImage = (postData) => async dispatch => {
//   //Get user locale of the machine he is on
//   const userLocale = General.getLocale();
//   postData.langLoc = userLocale;
//   postData.fromAdmin = true;

//   const response = await axios.post(`product/reorderProductImages`,postData);

//   if (DEBUG_MODE){
//     console.log(response.data);
//   }

//   dispatch({type: POST_CATALOG_PRODUCT_REORDER_IMAGE, payload: response.data});
// };




