import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Grid,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Box,
  IconButton,
  TextField,
} from "@mui/material";
import { showResponseMessage } from "../../utils/alerts/MyToastUtil";
import { POST_CATALOG_OFFERS_EDIT_PRODUCTS } from "../../redux/actionTypes";
import {
  postCatalogOffersEditProducts,
  postCatalogOffersListAddEdit,
} from "../../redux/catalog-offers/action";
import LangPicker from "../global/LangPicker";
import MultiLangTextField from "../global/textFields/MultilanguageTextField";
import { getDefaultLanguage } from "../../utils/languages";
import { postCatalogOffersSingle } from "../../redux/catalog-offers/action";
import ProductsPicker from "../catalog/ProductsPicker";
import { Delete, Edit } from "@mui/icons-material";
import MyStatusPicker from "../global/statusPicker/MyStatusPicker";
import { getStatusText } from "../../utils/tables/tableUtils";
import SweetAlert from "sweetalert2";
import CloseIcon from "@mui/icons-material/Close";
import { PRIMARY_COLOR } from "../../constants";

const EditCatalogOfferProducts = (props) => {
  const settingsData = useSelector(({ settings }) => settings.settingsData);
  const singleOfferData = useSelector(({ offers }) => offers.singleOfferData);
  const editOfferProductsData = useSelector(
    ({ offers }) => offers.editOfferProductsData
  );

  const dispatch = useDispatch();
  const trans = settingsData.trans;

  const [singleOffer, setSingleOffer] = useState(null);
  const [selectedLang, setSelectedLang] = useState(
    getDefaultLanguage(settingsData.app_languages)
  );

  const [names, setNames] = useState([]);
  const [selectedProductIds, setSelectedProductIds] = useState([]);
  const [is_free, setIsFree] = useState(false);
  const [required, setIsRequired] = useState(true);

  const [offerProducts, setOfferProducts] = useState([]);
  const [currentProduct, setCurrentProduct] = useState(null); // For editing existing products

  useEffect(() => {
    if (props.isOpen && props.itemId != null) {
      reloadData();
    }

    if (props.isOpen && props.itemId == null) {
      clearData();
    }
  }, [props.isOpen, props.itemId]);

  useEffect(() => {
    if (singleOffer != null) {
      // setNames(singleOffer.names);
      // setDescriptions(singleOffer.descriptions);
      setOfferProducts(singleOffer.offer_products);
    }
  }, [singleOffer]);

  useEffect(() => {
    if (singleOfferData != null) {
      setSingleOffer(singleOfferData);
    }
  }, [singleOfferData]);

  useEffect(() => {
    if (editOfferProductsData != null) {
      showResponseMessage(editOfferProductsData);
      if (editOfferProductsData.status == true) {
        clearData();
        props.onItemUpdate();
        setCurrentProduct(null);
        reloadData();
      }
      dispatch({ type: POST_CATALOG_OFFERS_EDIT_PRODUCTS, payload: null });
    }
  }, [editOfferProductsData]);

  const reloadData = () => {
    if (props.itemId != null) {
      let postData = { id: props.itemId };
      dispatch(postCatalogOffersSingle(postData));
    }
  };

  const handleSave = (deleteProductId) => {
    var updatedProducts = [];
    offerProducts.forEach((offerProduct, offerProductIndex) => {
      updatedProducts.push(offerProduct);
    });

    updatedProducts.forEach((offerProduct, offerProductIndex) => {
      updatedProducts[offerProductIndex].offer_product_items =
        offerProduct.offer_product_items.map((item) => item.id);
    });

    if (deleteProductId != null) {
     // console.log("DELETE MODE");
      updatedProducts = updatedProducts.filter(
        (product) => product.id !== deleteProductId
      );
    } else {
      if (currentProduct != null) {
       // console.log("EDIT MODE");
        updatedProducts.forEach((offerProduct, offerProductIndex) => {
          if (offerProduct.id == currentProduct.id) {
            updatedProducts[offerProductIndex].names = names;
            updatedProducts[offerProductIndex].offer_product_items =
              selectedProductIds;
            updatedProducts[offerProductIndex].is_free = is_free;
            updatedProducts[offerProductIndex].required = required;
          }
        });
      } else {
       // console.log("ADD MODE");
        updatedProducts.push({
          offer_product_items: selectedProductIds,
          is_free: is_free,
          required: required,
          names: names,
        });
      }
    }

    const postData = {
      offerId: props.itemId,
      offer_products: updatedProducts,
    };

    dispatch(postCatalogOffersEditProducts(postData));
  };

  const changeLanguage = (lang) => {
    setSelectedLang(lang);
  };
  const handleClose = () => {
    clearData();
    props.onClose();
  };

  const clearData = () => {
    setNames([]);
    setSelectedLang(getDefaultLanguage(settingsData.app_languages));
    setSelectedProductIds([]);
    setIsFree(false);
    setIsRequired(true);
    clearPreSelectedProducts();
  };

  // Callback function to handle product selection
  const handleProductSelect = (productIds) => {
    setSelectedProductIds(productIds); // Update the state with the selected product ID(s)
  };

  // Set the product data for editing
  const handleEditProduct = (product) => {
    setCurrentProduct(product); // Set the current product for editing
    setNames(product.names);
    setSelectedProductIds(product.offer_product_items.map((item) => item.id));
  };

  // Remove a product from the offerProducts list
  const handleDeleteProduct = (productId) => {
    console.log("productId", productId);

    SweetAlert.fire({
      theme: "dark",
      title: trans.delete_record_title,
      text: trans.delete_record_desc,
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: PRIMARY_COLOR,
      confirmButtonText: trans.delete_record_button,
      cancelButtonText: trans.cancel_btn,
      reverseButtons: true,
    }).then((result) => {
      if (result.value) {
        handleSave(productId);
      }
    });
  };

    // Function to clear preSelectedProductIds
    const clearPreSelectedProducts = () => {
      setSelectedProductIds([]); // Clear the pre-selected product IDs
    };

  return (
    <Dialog open={props.isOpen} onClose={handleClose} fullWidth maxWidth="md">
      <DialogTitle>
        <div className="_flex_row_space">
          <div>
            {singleOffer
              ? trans.catalog_offer_edit_title
              : trans.catalog_offer_add_title}
          </div>
          <IconButton
            onClick={() => {
              handleClose();
            }}
          >
            <CloseIcon />
          </IconButton>
        </div>
      </DialogTitle>
      <DialogContent>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <LangPicker onLangSelectedChanged={changeLanguage} />
          </Grid>

          <Grid item xs={12}>
            <MultiLangTextField
              label={trans.catalog_offer_add_offer_name}
              currentLang={selectedLang}
              texts={names}
              onTextsChange={setNames}
            />
          </Grid>

          <Grid item xs={6}>
            <MyStatusPicker
              label={trans.catalog_offer_product_required}
              status={required}
              onChange={(value) => setIsRequired(value)}
            />
          </Grid>

          <Grid item xs={6}>
            <MyStatusPicker
              label={trans.catalog_offer_product_free}
              status={is_free}
              onChange={(value) => setIsFree(value)}
            />
          </Grid>

          <Grid item xs={12}>
            <ProductsPicker
              onProductSelect={handleProductSelect}
              multiple={true}
              preSelectedProductIds={selectedProductIds}
              storeItems={singleOffer != null ? singleOffer.offer_stores : []}
              clearPreSelectedProducts={clearPreSelectedProducts}
            />
          </Grid>

          <Grid item xs={12}>
            <div style={{ height: "10px" }}></div>
          </Grid>

          <Grid container justifyContent="end">
            <Grid item>
              <Button
                variant="outlined"
                style={{ marginRight: "10px" }}
                onClick={props.onClose}
              >
                {trans.catalog_offer_state_cancel_button}
              </Button>
            </Grid>
            <Grid>
              <Button variant="contained" onClick={() => {handleSave(null)}}>
                {trans.catalog_offer_state_save_button}
              </Button>
            </Grid>
          </Grid>

          <Grid item xs={12}>
            <TableContainer component={Paper}>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell>{trans.offer_product_image}</TableCell>
                    <TableCell>{trans.offer_product_name}</TableCell>
                    <TableCell>{trans.offer_product_required}</TableCell>
                    <TableCell>{trans.offer_product_free}</TableCell>
                    <TableCell></TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {offerProducts.map((product) => (
                    <TableRow key={product.id}>
                      <TableCell>
                        {product.main_image_thumb ? (
                          <img
                            src={product.main_image_thumb}
                            alt={product.name}
                            style={{ width: "50px", height: "50px" }}
                          />
                        ) : (
                          "N/A"
                        )}
                      </TableCell>
                      <TableCell>{product.name}</TableCell>
                      <TableCell>
                        {getStatusText(product.required, trans)}
                      </TableCell>
                      <TableCell>
                        {getStatusText(product.is_free, trans)}
                      </TableCell>
                      <TableCell>
                        <Box sx={{ display: "flex", gap: 1 }}>
                          <IconButton
                            onClick={() => handleEditProduct(product)}
                          >
                            <Edit />
                          </IconButton>
                          <IconButton
                            onClick={() => handleDeleteProduct(product.id)}
                          >
                            <Delete />
                          </IconButton>
                        </Box>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </Grid>
        </Grid>
      </DialogContent>
    </Dialog>
  );
};

export default EditCatalogOfferProducts;
