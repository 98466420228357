import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Grid,
  TextField,
  Box,
} from "@mui/material";
import { showResponseMessage } from "../../utils/alerts/MyToastUtil";
import { POST_CATALOG_EXTRAS_ADD_EDIT, POST_CATALOG_OFFERS_ADD_EDIT } from "../../redux/actionTypes";
import {
  postCatalogExtrasListAddEdit,
  postCatalogExtrasSingle,
} from "../../redux/catalog-offers/action";
import LangPicker from "../global/LangPicker";
import MultiLangTextField from "../global/textFields/MultilanguageTextField";
import MyStatusPicker from "../global/statusPicker/MyStatusPicker";
import MyListPicker from "../global/listPicker/MyListPicker";
import { getDefaultLanguage } from "../../utils/languages";
import { postCatalogOffersListAddEdit, postCatalogOffersSingle } from "../../redux/catalog-offers/action";
import StoresPicker from "../stores/StoresPicker";

const AddEditCatalogOffer = (props) => {
  const settingsData = useSelector(({ settings }) => settings.settingsData);
  const singleOfferData = useSelector(
    ({ offers }) => offers.singleOfferData
  );
  const addEditOfferData = useSelector(
    ({ offers }) => offers.addEditOfferData
  );

  const dispatch = useDispatch();
  const trans = settingsData.trans;

  const [singleOffer, setSingleOffer] = useState(null);
  const [selectedLang, setSelectedLang] = useState(
    getDefaultLanguage(settingsData.app_languages)
  );
  const [names, setNames] = useState([]);
  const [descriptions, setDescriptions] = useState([]);
  const [discountPercentage, setDiscountPercentage] = useState(0);
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [offerPrice, setOfferPrice] = useState(0);
  const [startPrice, setStartPrice] = useState(0);
  const [offerProducts, setOfferProducts] = useState([]);
  const [isExpiring, setIsExpiring] = useState(false);
  const [status, setStatus] = useState(true);
  const [storeItems, setStoreItems] = useState([]);

  useEffect(() => {
    if (props.isOpen && props.itemId != null) {
      reloadData();
    }

    if ( props.isOpen && props.itemId == null){
      clearData();
    }

  }, [props.isOpen, props.itemId]);

  useEffect(() => {
    if (singleOffer != null) {
      setNames(singleOffer.names);
      setDescriptions(singleOffer.descriptions);
      setDiscountPercentage(singleOffer.discount_percentage);
      setStartDate(singleOffer.start_date);
      setEndDate(singleOffer.end_date);
      setOfferPrice(singleOffer.offer_price);
      setStartPrice(singleOffer.start_price);
      setOfferProducts(singleOffer.offer_products);
      setIsExpiring(singleOffer.isExpiring);
      setStatus(singleOffer.status.value);
      setStoreItems(singleOffer.offer_stores);
    } 
  }, [singleOffer]);

  useEffect(() => {
    if (singleOfferData != null) {
      setSingleOffer(singleOfferData);
    }
  }, [singleOfferData]);

  useEffect(() => {
    if (addEditOfferData != null) {
      showResponseMessage(addEditOfferData);
      dispatch({ type: POST_CATALOG_OFFERS_ADD_EDIT, payload: null });
      props.onItemUpdate();
      if (props.onClose) props.onClose();
    }
  }, [addEditOfferData]);

  const reloadData = () => {
    if (props.itemId != null) {
      let postData = { id: props.itemId };
      dispatch(postCatalogOffersSingle(postData));
    }
  };

  const handleSave = () => {
    const postData = {
      names,
      descriptions,
      discount_percentage: discountPercentage,
      offer_products: offerProducts,
      isExpiring,
      start_date: startDate,
      end_date: endDate,
      offer_price: offerPrice,
      start_price: startPrice,
      status: {
        value: status
      },
      stores: storeItems
    };

    if (props.itemId != null && props.itemId != ""){
      postData.offerId = props.itemId;
    }


    dispatch(postCatalogOffersListAddEdit(postData));
  };

  const changeLanguage = (lang) => {
    setSelectedLang(lang);
  };
  const handleClose = () => {
    clearData();
    props.onClose();
  };

  const clearData = () => {
    setNames([]);
    setDescriptions([]);
    setSelectedLang(getDefaultLanguage(settingsData.app_languages));
    setDiscountPercentage(0);
    setStartDate(null);
    setEndDate(null);
    setOfferPrice(0);
    setStartPrice(0);
    setOfferProducts([]);
    setIsExpiring(false);
    setStatus(true);
    setStoreItems([]);
  };


  return (
    <Dialog open={props.isOpen} onClose={handleClose}     fullWidth
    maxWidth="md">
      <DialogTitle>
        {singleOffer ? trans.catalog_offer_edit_title : trans.catalog_offer_add_title}
      </DialogTitle>
      <DialogContent>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <LangPicker onLangSelectedChanged={changeLanguage} />
          </Grid>

          <Grid item xs={12}>
            <MultiLangTextField
              label={trans.catalog_offer_add_offer_name}
              currentLang={selectedLang}
              texts={names}
              onTextsChange={setNames}
            />
          </Grid>

          <Grid item xs={12}>
            <MultiLangTextField
              label={trans.catalog_offer_add_offer_description}
              currentLang={selectedLang}
              texts={descriptions}
              onTextsChange={setDescriptions}
            />
          </Grid>

          <Grid item xs={12}>
            <br/>
          </Grid>

          <Grid item xs={4}>
            <TextField
              label={trans.catalog_offer_discount_label}
              type="number"
              value={discountPercentage}
              fullWidth
              onChange={(e) => setDiscountPercentage(e.target.value)}
            />
          </Grid>

          <Grid item xs={4}>
            <TextField
              label={trans.catalog_offer_price_label}
              type="number"
              value={offerPrice}
              fullWidth
              onChange={(e) => setOfferPrice(e.target.value)}
            />
          </Grid>

          <Grid item xs={4}>
            <TextField
              label={trans.catalog_offer_start_price_label}
              type="number"
              value={startPrice}
              fullWidth
              onChange={(e) => setStartPrice(e.target.value)}
            />
          </Grid>

          {isExpiring ? <Grid item xs={4}>
            <TextField
              label={trans.catalog_offer_start_date_label}
              type="datetime-local"
              value={startDate ?? new Date()}
              fullWidth
              onChange={(e) => setStartDate(e.target.value)}
            />
          </Grid> : null}

          {isExpiring ?<Grid item xs={4}>
            <TextField
              label={trans.catalog_offer_end_date_label}
              type="datetime-local"
              value={endDate ?? new Date()}
              fullWidth
              onChange={(e) => setEndDate(e.target.value)}
            />
          </Grid> : null}

          <Grid item xs={4}>
            <MyStatusPicker
              label={trans.catalog_offer_is_expiring_label}
              status={isExpiring}
              onChange={(value) => setIsExpiring(value)}
            />
          </Grid>

          <Grid item xs={12}>
                <StoresPicker
                  onStoreSelect={(selectedStores) => {
                    setStoreItems(selectedStores);
                  }}
                  multiple={true}
                  preSelectedStoreIds={storeItems}
                />
              </Grid>

          <Grid item xs={12}>
            <MyStatusPicker
              label={trans.catalog_offer_status_label}
              status={status}
              onChange={(value) => setStatus(value)}
            />
          </Grid>

        </Grid>
      </DialogContent>
      <DialogActions>
        <Button onClick={props.onClose}>{trans.catalog_offer_state_cancel_button}</Button>
        <Button onClick={handleSave}>{trans.catalog_offer_state_save_button}</Button>
      </DialogActions>
    </Dialog>
  );
};

export default AddEditCatalogOffer;
