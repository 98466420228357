import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Dialog,
  DialogContent,
  DialogTitle,
  Button,
  Grid,
  IconButton,
  TextField,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { getDefaultLanguage } from "../../utils/languages";
import LangPicker from "../global/LangPicker";
import MultiLangTextField from "../global/textFields/MultilanguageTextField";
import {
  postCatalogProductAddEdit,
  postCatalogProductSingle,
} from "../../redux/catalog/action";
import CatalogCategoriesPicker from "./categories/CatalogCategoriesPicker";
import MyStatusPicker from "../global/statusPicker/MyStatusPicker";
import DatePickerComponent from "../global/datePicker/DatePickerComponent";
import { POST_CATALOG_CATEGORY_ADD_EDIT, POST_CATALOG_PRODUCT_ADD_EDIT } from "../../redux/actionTypes";
import { showResponseMessage } from "../../utils/alerts/MyToastUtil";

const AddCatalogProduct = (props) => {
  const settingsData = useSelector(({ settings }) => settings.settingsData);
  const addEditCatalogProductData = useSelector(
    ({ catalog }) => catalog.addEditCatalogProductData
  );
  const singleCatalogProductData = useSelector(
    ({ catalog }) => catalog.singleCatalogProductData
  );

  //Variables
  const dispatch = useDispatch();
  const trans = settingsData.trans;

  //Hooks
  const [catalogItem, setCatalogItem] = useState(null);
  const [names, setNames] = useState([]);
  const [descriptions, setDescriptions] = useState([]);
  const [catalog_categories, setCatalogCategories] = useState([]);
  const [price, setPrice] = useState(0);
  const [fixed_price, setFixedPrice] = useState(0);
  const [disabled, setDisabled] = useState(false);
  const [disabled_until, setDisabledUntil] = useState(null);
  const [status, setStatus] = useState(true);
  const [selectedLang, setSelectedLang] = useState(
    getDefaultLanguage(settingsData.app_languages)
  );

  useEffect(() => {
    if (props.editProductItem != null && props.open && catalogItem == null) {
      getCatalogProduct(props.editProductItem.id);
    }
  }, [props.editProductItem]);

  useEffect(() => {
    if (props.productAddCategory != null) {
      setCatalogCategories([props.productAddCategory.id]);
    }
  }, [props.productAddCategory]);

  useEffect(() => {
    if (singleCatalogProductData != null) {
      setCatalogItem(singleCatalogProductData);
      setNames(singleCatalogProductData.names);
      setDescriptions(singleCatalogProductData.descriptions);

      var catalogCategoriesIds = [];

      if (singleCatalogProductData.catalog_categories != null){
        singleCatalogProductData.catalog_categories.forEach(
          (catalog_category) => {
            catalogCategoriesIds.push(catalog_category.id);
          }
        );
      }

      setCatalogCategories(catalogCategoriesIds);

      setPrice(singleCatalogProductData.price);
      setFixedPrice(singleCatalogProductData.fixed_price);

      setStatus(singleCatalogProductData.status.value);

      setDisabled(singleCatalogProductData.disabled);

      setDisabledUntil(singleCatalogProductData.disabled_until);
    }
  }, [singleCatalogProductData]);

  useEffect(() => {
    if (addEditCatalogProductData != null) {
      showResponseMessage(addEditCatalogProductData);

      if (addEditCatalogProductData.status == true) {
        if (props.editCategoryItem != null) {
          getCatalogProduct(props.editCategoryItem.id);
        } else {
          handleClose();
        }

        props.onDataUpdated();
      }
      dispatch({ type: POST_CATALOG_PRODUCT_ADD_EDIT, payload: null });
    }
  }, [addEditCatalogProductData]);

  const getCatalogProduct = (pId) => {
    let postData = {
      id: pId,
    };

    dispatch(postCatalogProductSingle(postData));
  };

  const changeLanguage = (lang) => {
    setSelectedLang(lang);
  };

  const onSaveCatalogProduct = (e) => {
    e.preventDefault();

    var postData = {
      names: names,
      descriptions: descriptions,
      catalog_categories: catalog_categories.map((cat) => cat.id),
      price: price,
      fixed_price: fixed_price,
      disabled: disabled,
      disabled_until: disabled_until,
      status: {
        value: status,
      },
    };

    if (props.editProductItem != null) {
      postData.productId = props.editProductItem.id;
    }

    dispatch(postCatalogProductAddEdit(postData));
  };

  const handleClose = () => {
    clearData();
  };

  const clearData = () => {
    setNames([]);
    setDescriptions([]);
    setCatalogCategories([]);
    setStatus(true);
    setPrice(0);
    setFixedPrice(0);
    setDisabled(false);
    setDisabledUntil(null);
    setSelectedLang(getDefaultLanguage(settingsData.app_languages));
    setCatalogItem(null);
    props.onClose();
  };

  return (
    <Dialog
      open={props.open}
      onClose={() => {
        handleClose();
      }}
      fullWidth
      maxWidth="md"
    >
      <DialogTitle>
        <div className="_flex_row_space">
          <div>
            {props.editProductItem != null
              ? settingsData.trans.catalog_menu_product_edit
              : settingsData.trans.catalog_menu_product_add}
          </div>
          <IconButton
            onClick={() => {
              handleClose();
            }}
          >
            <CloseIcon />
          </IconButton>
        </div>
      </DialogTitle>
      <DialogContent>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <LangPicker onLangSelectedChanged={changeLanguage} />
          </Grid>

          <Grid item xs={12}>
            <MultiLangTextField
              label={trans.table_head_category_name}
              currentLang={selectedLang}
              texts={names}
              onTextsChange={(texts) => setNames(texts)}
            />
          </Grid>

          <Grid item xs={12}>
            <MultiLangTextField
              label={trans.table_head_store_description}
              currentLang={selectedLang}
              texts={descriptions}
              onTextsChange={(texts) => setDescriptions(texts)}
            />
          </Grid>

          <Grid item xs={12}>
            <CatalogCategoriesPicker
              onCatalogCategorySelect={(selectedCategories) => {
                setCatalogCategories(selectedCategories);
              }}
              multiple={true}
              autoSelectFirst={false}
              preSelectedCatalogCategoryIds={catalog_categories}
              style={{ width: "100%" }}
              selectedStores={props.selectedStores}
            />
          </Grid>

          <Grid item xs={6}>
            <TextField
              margin="dense"
              id="price"
              label={trans.product_price_field}
              value={price}
              type="number"
              onChange={(e) => {
                setPrice(e.target.value);
              }}
              fullWidth
              variant="standard"
            />
          </Grid>

          <Grid item xs={6}>
            <TextField
              margin="dense"
              id="fixed_price"
              label={trans.product_fixed_price_field}
              value={fixed_price}
              type="number"
              onChange={(e) => {
                setFixedPrice(e.target.value);
              }}
              fullWidth
              variant="standard"
            />
          </Grid>

          <Grid item xs={12}>
            <MyStatusPicker
              label={trans.catalog_category_status_field}
              status={status}
              onChange={(value) => setStatus(value)}
            />
          </Grid>

          <Grid item xs={9}>
            <MyStatusPicker
              label={trans.catalog_category_disabled_field}
              status={disabled}
              onChange={(value) => setDisabled(value)}
            />
          </Grid>

          <Grid item xs={3}>
            <DatePickerComponent
              label={trans.catalog_category_disabled_until_field}
              value={disabled_until}
              onChange={(newDate) => {
                setDisabledUntil(newDate);
              }}
            />
          </Grid>

          <Grid container justifyContent="center" style={{ marginTop: "20px" }}>
            <Grid
              item
              xs={12}
              style={{ display: "flex", justifyContent: "center" }}
            >
              <Button
                variant="contained"
                color="primary"
                onClick={(e) => onSaveCatalogProduct(e)}
              >
                {trans.button_save_product}
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </DialogContent>
    </Dialog>
  );
};

export default AddCatalogProduct;
