import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Dialog,
  DialogContent,
  DialogTitle,
  Button,
  List,
  ListItem,
  ListItemText,
  Grid,
} from "@mui/material";
import {
  GoogleMap,
  Polygon,
  Marker,
  useJsApiLoader,
} from "@react-google-maps/api";
import {
  postStoreZonesAdd,
  postStoreZonesDelete,
  postStoreZonesEdit,
  postStoreZonesList,
  postStoreZonesReorder,
} from "../../redux/stores/action";
import {
  POST_STORES_ZONE_ADD,
  POST_STORES_ZONE_DELETE,
  POST_STORES_ZONE_EDIT,
} from "../../redux/actionTypes";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TextField from "@mui/material/TextField";
import { getStatusText } from "../../utils/tables/tableUtils";
import MyColorPicker from "../global/colorPicker/MyColorPicker";
import MyStatusPicker from "../global/statusPicker/MyStatusPicker";
import SweetAlert from "sweetalert2";
import { PRIMARY_COLOR } from "../../constants";
import TableBodySortable from "../global/TableBodySortable";
import DragHandler from "../global/DragHandler";
import SortableRow from "../global/SortableRow";
import {
  SortableContainer,
  SortableHandle,
  SortableElement,
  arrayMove,
} from "react-sortable-hoc";

const ZoneDialog = (props) => {
  const settingsData = useSelector(({ settings }) => settings.settingsData);
  const zoneListData = useSelector(({ stores }) => stores.zoneListData);
  const addZoneData = useSelector(({ stores }) => stores.addZoneData);
  const editZoneData = useSelector(({ stores }) => stores.editZoneData);
  const deleteZoneData = useSelector(({ stores }) => stores.deleteZoneData);

  //Variables
  const dispatch = useDispatch();
  const trans = settingsData.trans;

  const [zones, setZones] = useState([]);
  const [editablePolygon, setEditablePolygon] = useState([]);
  const [drawing, setDrawing] = useState(false);
  const [defaultCenter, setDefaultCenter] = useState({ lat: 0.0, lng: 0.0 });

  //Zone details
  const [zone_title, setZoneTitle] = useState("");
  const [zone_color, setZoneColor] = useState("#f90000");
  const [zone_status, setZoneStatus] = useState(true);
  const [zone_min_cost, setZoneMinCost] = useState(0.0);
  const [editZoneItem, setEditZoneItem] = useState(null);

  useEffect(() => {
    if (props.storeItem) {
      setDefaultCenter({
        lat: props.storeItem.lat,
        lng: props.storeItem.lng,
      });

      getStoreZones();
    }
  }, [props.storeItem]);

  useEffect(() => {
    if (zoneListData != null) {
      setZones(zoneListData.zones);
    }
  }, [zoneListData]);

  useEffect(() => {
    if (editZoneItem != null) {
      setZoneTitle(editZoneItem.title);
      setZoneColor(editZoneItem.zone_color);
      setZoneStatus(editZoneItem.status);
      setZoneMinCost(editZoneItem.min_cost);
      setEditablePolygon(editZoneItem.zone_paths);
    }
  }, [editZoneItem]);

  useEffect(() => {
    if (addZoneData != null) {
      getStoreZones();
      dispatch({ type: POST_STORES_ZONE_ADD, payload: null });
    }
  }, [addZoneData]);

  useEffect(() => {
    if (editZoneData != null) {
      getStoreZones();
      setEditZoneItem(null);
      dispatch({ type: POST_STORES_ZONE_EDIT, payload: null });
    }
  }, [editZoneData]);

  useEffect(() => {
    if (deleteZoneData != null) {
      getStoreZones();
      dispatch({ type: POST_STORES_ZONE_DELETE, payload: null });
    }
  }, [deleteZoneData]);

  const getStoreZones = () => {
    dispatch(postStoreZonesList({ storeId: props.storeItem.id }));
  };

  const { isLoaded } = useJsApiLoader({
    googleMapsApiKey: settingsData.initData.maps_key,
    libraries:['places']
  });

  if (!isLoaded) {
    return <div>Loading...</div>;
  }

  const handleMapClick = (event) => {
    if (drawing) {
      setEditablePolygon([
        ...editablePolygon,
        { lat: event.latLng.lat(), lng: event.latLng.lng() },
      ]);
    }
  };

  const handleSaveZone = () => {
    if (editablePolygon.length > 0) {
      const newZone = {
        zone_id: zones.length + 1,
        title: zone_title,
        zone_color: zone_color,
        zone_paths: editablePolygon,
        min_cost: zone_min_cost,
        min_cost: zone_min_cost,
        status: zone_status,
      };
      var postData = {
        storeId: props.storeItem.id,
        zone: newZone,
      };

      if (editZoneItem != null) {
        postData.zoneId = editZoneItem._id;
        dispatch(postStoreZonesEdit(postData));
      } else {
        dispatch(postStoreZonesAdd(postData));
      }

      clearDetailsData();
    }
  };

  const clearDetailsData = () => {
    setEditablePolygon([]);
    setDrawing(false);
    setZoneTitle("");
    setZoneMinCost(0.0);
    setZoneColor("#f90000");
    setZoneStatus(true);
  };

  const handleCancelLastPoint = () => {
    setEditablePolygon(editablePolygon.slice(0, -1));
    if (editablePolygon.length <= 1) {
      setDrawing(false);
    }
  };

  const handleStartDrawing = () => {
    setDrawing(!drawing);
    setEditablePolygon([]);
  };

  const handleEditButton = (e, zoneItem) => {
    e.preventDefault();
    setEditZoneItem(zoneItem);
    setDrawing(true);
  };

  const handleClearPoints = () => {
    setEditablePolygon([]);
    if (editablePolygon.length <= 1) {
      setDrawing(false);
    }
  };

  const cancelEditZone = () => {
    setEditZoneItem(null);
    clearDetailsData();
  };

  const handleDeleteButton = (e, item) => {
    e.preventDefault();
    SweetAlert.fire({
      theme: "dark",
      title: trans.add_store_zone_delete_title,
      text: trans.add_store_zone_delete_desc,
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: PRIMARY_COLOR,
      confirmButtonText: trans.add_store_zone_delete_button,
      cancelButtonText: trans.cancel_btn,
      reverseButtons: true,
    }).then((result) => {
      if (result.value) {
        deleteZoneItem(item);
      }
    });
  };

  const deleteZoneItem = (item) => {
    dispatch(
      postStoreZonesDelete({ zoneId: item._id, storeId: props.storeItem.id })
    );
  };

  // Handler for traversing completion, helper arrayMove is used
  const onSortEnd = ({ oldIndex, newIndex }) => {
    const updatedItems = arrayMove(zones, oldIndex, newIndex);
    const updatedItemsWithOrder = updatedItems.map((item, index) => {
      return { ...item, order: index + 1 };
    });
    setZones(updatedItemsWithOrder);
    dispatch(
      postStoreZonesReorder({
        storeId: props.storeItem.id,
        orderedZones: updatedItemsWithOrder,
      })
    );
  };

  return (
    <Dialog
      open={props.open}
      onClose={props.onCloseZones}
      fullWidth
      maxWidth="md"
    >
      <DialogTitle>
        <div className="_flex_row_space">
          <div>{trans.edit_store_zones}</div>
        </div>
      </DialogTitle>
      <DialogContent>
        <Grid container spacing={1} style={{ marginTop: "5px" }}>
          <Grid item xs={3}>
            <TextField
              margin="dense"
              id="zone_title"
              label={trans.add_store_zone_name}
              value={zone_title}
              type="text"
              onChange={(e) => {
                setZoneTitle(e.target.value);
              }}
              fullWidth
              variant="standard"
            />
          </Grid>
          <Grid item xs={3}>
            <MyColorPicker
              labelText={trans.add_store_zone_color}
              color={zone_color}
              onChangeColor={(color) => {
                setZoneColor(color);
              }}
            />
          </Grid>
          <Grid item xs={3}>
            <TextField
              margin="dense"
              id="zone_title"
              label={trans.add_store_zone_min_cost}
              value={zone_min_cost}
              type="number"
              onChange={(e) => {
                setZoneMinCost(e.target.value);
              }}
              fullWidth
              variant="standard"
            />
          </Grid>
          <Grid item xs={3}>
            <MyStatusPicker
              label={trans.add_store_zone_status}
              status={zone_status}
              onChange={(value) => setZoneStatus(value)}
            />
          </Grid>
        </Grid>

        <br />

        <GoogleMap
          mapContainerStyle={{ height: "600px", width: "100%" }}

          center={defaultCenter}
          zoom={14}
          onClick={handleMapClick}
        >
          {zones
            .filter((zone) => zone._id !== editZoneItem?._id)
            .map((zone) => (
              <Polygon
                key={zone._id}
                paths={zone.zone_paths}
                options={{
                  fillColor: zone.zone_color,
                  fillOpacity: 0.7,
                  strokeColor: zone.zone_color,
                  strokeOpacity: 1,
                  strokeWeight: 2,
                }}
              />
            ))}

          {editablePolygon.length > 0 && (
            <Polygon
              paths={editablePolygon}
              options={{
                fillColor: "#FFEB3B",
                fillOpacity: 0.6,
                strokeColor: "#FFEB3B",
                strokeOpacity: 1,
                strokeWeight: 2,
              }}
            />
          )}

          <Marker position={defaultCenter} />
        </GoogleMap>

        <br />

        <div className="_flex_row_space">
          <div className="_flex_row">
            <Button
              onClick={() => {
                handleStartDrawing();
              }}
              color="secondary"
              variant="contained"
            >
              {drawing === true
                ? trans.add_store_zone_button_enable
                : trans.add_store_zone_button}
            </Button>
            {drawing === true ? (
              <Button onClick={handleClearPoints} color="secondary">
                {trans.add_store_zone_clear}
              </Button>
            ) : null}
            {drawing === true ? (
              <Button onClick={handleCancelLastPoint} color="secondary">
                {trans.add_store_zone_undo}
              </Button>
            ) : null}
          </div>
          <div className="_flex_row">
            {zone_title == "" || editablePolygon.length == 0 ? null : (
              <Button
                onClick={handleSaveZone}
                color="primary"
                variant="contained"
              >
                {trans.add_store_save_zone}
              </Button>
            )}
            {editZoneItem != null ? (
              <Button
                style={{ marginRight: "5px" }}
                onClick={cancelEditZone}
                color="secondary"
                variant="contained"
              >
                {trans.add_store_edit_zone_cancel}
              </Button>
            ) : null}
          </div>
        </div>

        <TableContainer>
          <Table>
            <TableHead>
              <TableRow>
              <TableCell></TableCell>
                <TableCell>{trans.add_store_zone_name}</TableCell>
                <TableCell>{trans.add_store_zone_color}</TableCell>
                <TableCell>{trans.add_store_zone_min_cost}</TableCell>
                <TableCell>{trans.add_store_zone_status}</TableCell>
              </TableRow>
            </TableHead>
            <TableBodySortable
              onSortEnd={(sortEnd) => onSortEnd(sortEnd)}
              useDragHandle
            >
              {zones.map((zoneItem, index) => (
                <SortableRow index={index} key={zoneItem._id}>
                  <TableCell>
                    <DragHandler />
                  </TableCell>
                  <TableCell component="th" scope="row" className="fontBold">
                    {zoneItem.title}
                  </TableCell>
                  <TableCell component="th" scope="row">
                    {zoneItem.zone_color}
                  </TableCell>
                  <TableCell component="th" scope="row">
                    {zoneItem.min_cost}€
                  </TableCell>
                  <TableCell component="th" scope="row">
                    {getStatusText(zoneItem.status, trans)}
                  </TableCell>
                  <TableCell>
                    <Button
                      variant="contained"
                      onClick={(e) => handleEditButton(e, zoneItem)}
                    >
                      {trans.table_row_edit_button}
                    </Button>
                  </TableCell>
                  <TableCell>
                    <Button
                      variant="contained"
                      onClick={(e) => handleDeleteButton(e, zoneItem)}
                    >
                      {trans.add_store_zone_delete_button}
                    </Button>
                  </TableCell>
                </SortableRow>
              ))}
            </TableBodySortable>
          </Table>
        </TableContainer>
      </DialogContent>
    </Dialog>
  );
};

export default ZoneDialog;
