import React from 'react';

const NoDataWidget = (props) => {
  return (
    <div >

        <div className='_flex_column'>
            <div className="fontBook" style={{fontSize:'13px',margin:'16px'}}>{props.msg}</div>
        </div>

    </div>
  );
};

export default NoDataWidget;
