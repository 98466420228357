import {
  POST_SIDE_MENU_ALL,
  POST_SIDE_MENU_ADD,
  POST_SIDE_MENU_EDIT,
  POST_SIDE_MENU_DELETE,
  POST_SIDE_MENU_REORDER
  } from '../actionTypes';
  import axios from '../../helpers/Api'
  import * as General from '../../helpers/General'
import { DEBUG_MODE } from '../../constants';
  
export const postSideMenusAll = (postData) => async dispatch => {
  //Get user locale of the machine he is on
  const userLocale = General.getLocale();
  postData.langLoc = userLocale;
  postData.fromAdmin = true;

  const response = await axios.post(`side-menu/list`,postData);

  if (DEBUG_MODE){
    console.log(response.data);
  }

  dispatch({type: POST_SIDE_MENU_ALL, payload: response.data});
};

    
export const postSideMenusAdd = (postData) => async dispatch => {
  //Get user locale of the machine he is on
  const userLocale = General.getLocale();
  postData.langLoc = userLocale;
  postData.user_id = General.getUserId();

  const response = await axios.post(`side-menu/addEditSideMenu`,postData);

  if (DEBUG_MODE){
    console.log(response.data);
  }

  dispatch({type: POST_SIDE_MENU_ADD, payload: response.data});
};


export const postSideMenusDelete = (postData) => async dispatch => {
  //Get user locale of the machine he is on
  const userLocale = General.getLocale();
  postData.langLoc = userLocale;

  const response = await axios.post(`side-menu/delete`,postData);

  if (DEBUG_MODE){
    console.log(response.data);
  }

  dispatch({type: POST_SIDE_MENU_DELETE, payload: response.data});
};


export const postSideMenuReorder = (postData) => async dispatch => {
  //Get user locale of the machine he is on
  const userLocale = General.getLocale();
  postData.langLoc = userLocale;

  const response = await axios.post(`side-menu/reorder`,postData);

  if (DEBUG_MODE){
    console.log(response.data);
  }

  dispatch({type: POST_SIDE_MENU_REORDER, payload: response.data});
};


