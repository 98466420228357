// SortableProductList.js
import React from "react";
import { SortableContainer } from "react-sortable-hoc";
import { List } from "@mui/material";
import { SortableProduct } from "./SortableProduct";
import NoDataWidget from "../../global/no-content/NoDataWidget";

export const SortableProductList = SortableContainer(
  ({ items, trans, onSortEnd, onEditProduct }) => {
    if (items.length == 0) {
      return <NoDataWidget msg={trans.menu_list_category_no_producs}/>
    }
    return (
      <List>
        {items.map((product, index) => (
          <SortableProduct
            key={`item-${product.id}`}
            index={index}
            product={product}
            onEditProduct={onEditProduct}
          />
        ))}
      </List>
    );
  },
  { useDragHandle: true }
);
