import {
  POST_ORDERS_ALL,
  POST_ORDERS_SINGLE,
  POST_ORDERS_ANSWER
  } from '../actionTypes';
  import axios from '../../helpers/Api'
  import * as General from '../../helpers/General'
import { DEBUG_MODE } from '../../constants';
  
export const postOrdersAll = (postData) => async dispatch => {
  //Get user locale of the machine he is on
  const userLocale = General.getLocale();
  postData.langLoc = userLocale;
  postData.fromAdmin = true;

  const response = await axios.post(`order/list`,postData);

  if (DEBUG_MODE){
    console.log(response.data);
  }

  dispatch({type: POST_ORDERS_ALL, payload: response.data});
};

export const postOrdersSingle = (postData) => async dispatch => {
  //Get user locale of the machine he is on
  const userLocale = General.getLocale();
  postData.langLoc = userLocale;
  postData.fromAdmin = true;

  const response = await axios.post(`order/single`,postData);

  if (DEBUG_MODE){
    console.log(response.data);
  }

  dispatch({type: POST_ORDERS_SINGLE, payload: response.data});
};

export const postOrdersAnswer = (postData) => async dispatch => {
  //Get user locale of the machine he is on
  const userLocale = General.getLocale();
  postData.langLoc = userLocale;
  postData.fromAdmin = true;

  const response = await axios.post(`order/answer`,postData);

  if (DEBUG_MODE){
    console.log(response.data);
  }

  dispatch({type: POST_ORDERS_ANSWER, payload: response.data});
};

    
// export const postSideMenusAdd = (postData) => async dispatch => {
//   //Get user locale of the machine he is on
//   const userLocale = General.getLocale();
//   postData.langLoc = userLocale;
//   postData.user_id = General.getUserId();

//   const response = await axios.post(`side-menu/addEditSideMenu`,postData);

//   if (DEBUG_MODE){
//     console.log(response.data);
//   }

//   dispatch({type: POST_SIDE_MENU_ADD, payload: response.data});
// };


// export const postSideMenusDelete = (postData) => async dispatch => {
//   //Get user locale of the machine he is on
//   const userLocale = General.getLocale();
//   postData.langLoc = userLocale;

//   const response = await axios.post(`side-menu/delete`,postData);

//   if (DEBUG_MODE){
//     console.log(response.data);
//   }

//   dispatch({type: POST_SIDE_MENU_DELETE, payload: response.data});
// };


// export const postSideMenuReorder = (postData) => async dispatch => {
//   //Get user locale of the machine he is on
//   const userLocale = General.getLocale();
//   postData.langLoc = userLocale;

//   const response = await axios.post(`side-menu/reorder`,postData);

//   if (DEBUG_MODE){
//     console.log(response.data);
//   }

//   dispatch({type: POST_SIDE_MENU_REORDER, payload: response.data});
// };


