import React from 'react';
import { SortableContainer , SortableHandle , SortableElement , arrayMove } from  'react-sortable-hoc'
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';

// Universal component for turning a TableBody into a sortable container
const  TableBodySortable  =  SortableContainer(({children, displayrowcheckbox}) => (
    <TableBody displayrowcheckbox={displayrowcheckbox} >
      {children}
    </TableBody >
  ))
  
  // The string is necessary for our custom body to be perceived as a TableBody and in this case there will be no error
  TableBodySortable.muiName  =  'TableBody'


  export default TableBodySortable;


