import React, { useState, useEffect } from "react";
import TextField from "@mui/material/TextField";
import { useSelector, useDispatch } from "react-redux";
import Grid from "@mui/material/Grid";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import { createFilterOptions } from "@mui/material/Autocomplete";
import { Checkbox } from "@mui/material";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import DirectionsIcon from "@mui/icons-material/Directions";
import Button from "@mui/material/Button";
import { generatePassword, getBirthdateFormated } from "../../helpers/General";
import DesktopDatePicker from "@mui/lab/DesktopDatePicker";
import { postUserSingle } from "../../redux/auth/action";
import { POST_USER_SINGLE } from "../../redux/actionTypes";

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;
const filter = createFilterOptions();

const AddEditUser = (props) => {
  const settingsData = useSelector(({ settings }) => settings.settingsData);
  const userSingleData = useSelector(({ auth }) => auth.userSingleData);
  const { editItem } = props;

  const [firstname, setFirstName] = useState("");
  const [lastname, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState(generatePassword());
  const [toSendWelcomeEmail, setToSendWelcomeEmail] = useState(false);
  const [mobile, setMobile] = useState("");
  const [mobileCode, setMobileCode] = useState("");
  const [mobileCodeImg, setMobileCodeImg] = useState("");

  const dispatch = useDispatch();
  const trans = settingsData.trans;

  useEffect(() => {
    if (props.toGetData) {
      let postData = {
        fromAdmin: true,
        userId: editItem != null ? editItem.id : "",
        firstname: firstname,
        lastname: lastname,
        phone: mobile,
        mobileCode: mobileCode,
      };

      if (editItem == null) {
        postData = {
          fromAdmin: true,
          firstname: firstname,
          lastname: lastname,
          password: password,
          email: email,
          phone: mobile,
          mobileCode: mobileCode,
          toSendWelcomeEmail,
          usertypes: ["APP_USER"],
        };
      }

      props.onSubmitData(postData);
    }
  }, [props.toGetData]);

  useEffect(() => {
    if (userSingleData != null) {
      setFirstName(userSingleData.firstname);
      setLastName(userSingleData.lastname);
      setEmail(userSingleData.email);
      const mobileData = userSingleData.contact_options.find(
        (contact) => contact.type === "MOBILE"
      );
      setMobile(mobileData?.value || "");

      
      setMobileCode(mobileData?.mobileCode || "");

      settingsData.country_items.forEach((country) => {
        if (country.phone_code === mobileData?.mobileCode) {
          setMobileCodeImg(country.icon);
        }
      });

      dispatch({ type: POST_USER_SINGLE, payload: null });
    }
  }, [userSingleData]);

  useEffect(() => {
    if (editItem != null) {
      dispatch(postUserSingle({ userId: editItem.id }));
    } else {
      clearData();
    }
  }, [editItem]);

  useEffect(() => {
    if (props.toGetData) {
      // let tagsIds = selectedTags.map((value) => value.id);
      // let postData = {name,visible,slug,defaultTags:tagsIds,order};
      // props.onSubmitData(postData)
    }
  }, [props.toGetData]);

  useEffect(() => {
    if (props.toClearData) {
      clearData();
    }
  }, [props.toClearData]);

  const clearData = () => {
    setFirstName("");
    setLastName("");
    setEmail("");
    setPassword(generatePassword());
    setMobile("");
      // Set default mobile code and flag
      const defaultCountry = settingsData.country_items.find(
        (country) => country.phone_code === "+30"
      );
      setMobileCode(defaultCountry.phone_code);
      setMobileCodeImg(defaultCountry.icon);
  };

  return (
    <div>
      <Grid container spacing={3} alignItems="center">
        <Grid item xs={12}>
          <h4>{trans.user_details}</h4>
          <Grid container spacing={1} alignItems="center">
            <Grid item xs={12}>
              <TextField
                margin="dense"
                id="firstname"
                label={trans.table_head_firstname}
                value={firstname}
                type="text"
                onChange={(e) => {
                  setFirstName(e.target.value);
                }}
                fullWidth
                variant="standard"
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                margin="dense"
                id="lastname"
                label={trans.table_head_lastname}
                value={lastname}
                type="text"
                onChange={(e) => {
                  setLastName(e.target.value);
                }}
                fullWidth
                variant="standard"
              />
            </Grid>
            <Grid item xs={editItem == null ? 6 : 12}>
              <TextField
                margin="dense"
                id="email"
                label={trans.email_field}
                value={email}
                type="text"
                onChange={(e) => {
                  setEmail(e.target.value);
                }}
                fullWidth
                variant="standard"
              />
            </Grid>

            {editItem == null ? (
              <Grid item xs={6}>
                <TextField
                  margin="dense"
                  id="pass"
                  label={trans.pass_field}
                  value={password}
                  type="text"
                  onChange={(e) => {
                    setPassword(e.target.value);
                  }}
                  fullWidth
                  variant="standard"
                />
              </Grid>
            ) : null}
          </Grid>
        </Grid>

        <Grid item xs={1}>
          {mobileCodeImg && (
            <img style={{ width: "35px" }} src={mobileCodeImg} alt="Code Img" />
          )}
        </Grid>

        <Grid item xs={2}>
          <FormControl fullWidth>
            <InputLabel id="mobile-code-label">{trans.mobile_code_field}</InputLabel>
            <Select
              labelId="mobile-code-label"
              id="mobile-code"
              value={mobileCode}
              onChange={(e) => {
                setMobileCode(e.target.value);
                const selectedCountry = settingsData.country_items.find(
                  (country) => country.phone_code === e.target.value
                );
                setMobileCodeImg(selectedCountry.icon);
              }}
            >
              {settingsData.country_items.map((country) => (
                <MenuItem key={country.id} value={country.phone_code}>
                  {country.phone_code}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>

        <Grid item xs={9}>
          <TextField
            margin="dense"
            id="mobile"
            label={trans.mobile_field}
            value={mobile}
            type="text"
            onChange={(e) => {
              setMobile(e.target.value);
            }}
            fullWidth
            variant="standard"
          />
        </Grid>

        {editItem == null ? (
          <Grid item xs={12}>
            <div>
              <Checkbox
                icon={icon}
                checkedIcon={checkedIcon}
                style={{ marginRight: 8 }}
                checked={toSendWelcomeEmail}
                onChange={(e, value) => setToSendWelcomeEmail(value)}
              />
              <span>{trans.to_send_welcome_email}</span>
            </div>
          </Grid>
        ) : null}

  
    
      </Grid>
    </div>
  );
};

export default AddEditUser;
