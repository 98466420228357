// // Import the functions you need from the SDKs you need
import { getMessaging, getToken, onMessage } from "firebase/messaging";
import { initializeApp } from "firebase/app";
import { DEBUG_MODE, MAIN_ID } from "../constants";
import Api from '../helpers/Api'
const ARG_FIREBASE_ADMIN = "topic_admin_all";

// // TODO: Add SDKs for Firebase products that you want to use
// // https://firebase.google.com/docs/web/setup#available-libraries

// // Your web app's Firebase configuration
// // For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
    apiKey: "AIzaSyDu-gRk5zF3AyAEeSeyNWKXI4sRRcKChwg",
    authDomain: "appiko-b951c.firebaseapp.com",
    projectId: "appiko-b951c",
    storageBucket: "appiko-b951c.appspot.com",
    messagingSenderId: "671436153020",
    appId: "1:671436153020:web:72248fe8aaa4b4d0d0f0b9",
    measurementId: "G-KJ27EZF92H"
};

// Initialize Firebase
var firebaseApp = initializeApp(firebaseConfig);
const messaging = getMessaging(firebaseApp);

export const initializeFirebase = () => {
  firebaseApp = initializeApp(firebaseConfig);
}

export const updateUserTopics = (toSub,userStores) => {
    return getToken(messaging, {vapidKey: 'BMy5EdlIj_DKaN0BoxoOszfG9SDoNEhgeFdVV7sI56bnLgL2TGAurovYTDPvH9RajeXFGtit0WiC7MvOfjlJg-w'}).then((currentToken) => {
      if (currentToken) {
       // console.log('current token for client: ', currentToken);
        // setTokenFound(true);
        // Track the token -> client mapping, by sending to backend server
        // show on the UI that permission is secured

        userStores.forEach(userStore => {
          if (toSub){
            subscribeToTopic(currentToken,userStore);
          } else {
            unSubscribeToTopic(currentToken,userStore);
          }
        });
       

      } else {
        console.log('No registration token available. Request permission to generate one.');
        // setTokenFound(false);
        // shows on the UI that permission is required 
      }
    }).catch((err) => {
      console.log('An error occurred while retrieving token. ', err);
      // catch error while creating client token
    });
  }
  
  
  export const onMessageListener = () =>
    new Promise((resolve) => {
      onMessage(messaging, (payload) => {
        resolve(payload);
      });
  });

  export const subscribeToTopic = async (token,storeId) => {
    
    let topic  = `${DEBUG_MODE ? "debug_" : ""}${ARG_FIREBASE_ADMIN}_${MAIN_ID}_${storeId}`;

    let postData = {topic,token,main_app_id: MAIN_ID};

    Api.post('/initData/subscribeToTopic', postData)
    .then(function (response) {
      if (DEBUG_MODE) {
        console.log(response);
      }
    })
    .catch(function (error) {
      if (DEBUG_MODE) {
        console.log(error);
      }
    });
}

export const unSubscribeToTopic = async (token,storeId) => {

  let topic  = `${DEBUG_MODE ? "debug_" : ""}${ARG_FIREBASE_ADMIN}_${MAIN_ID}_${storeId}`;

  let postData = {topic,token,main_app_id: MAIN_ID};

  Api.post('/initData/unsubscribeToTopic', postData)
  .then(function (response) {
    if (DEBUG_MODE) {
      console.log(response);
    }
  })
  .catch(function (error) {
    if (DEBUG_MODE) {
      console.log(error);
    }
  });
}

export const askForPermissioToReceiveNotifications = async () => {
  try {
    Notification.requestPermission().then((permission) => {
      if (permission === 'granted') {
        console.log('Notification permission granted.');
        // TODO(developer): Retrieve a registration token for use with FCM.
        // ...
      } else {
        console.log('Unable to get permission to notify.');
      }
    });
  } catch (error) {
    console.error(error);
  }
}