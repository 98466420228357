// UserReviewsList.js

import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Container from "@mui/material/Container";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Button from "@mui/material/Button";
import { Grid, Paper, TablePagination, Dialog } from "@mui/material";
import PageTitleDesc from "../global/pageTitleDesc/PageTitleDesc";
import { toast } from "react-toastify";
import { POST_USER_REVIEW_ALL } from "../../redux/actionTypes";
import { postReviewssAll } from "../../redux/userReview/action";
import UserReviewDetails from "./UserReviewDetails";
import StoresPicker from "../stores/StoresPicker";

const UserReviewsList = () => {
  const dispatch = useDispatch();
  const settingsData = useSelector(({ settings }) => settings.settingsData);
  const userReviewsData = useSelector(({ userReview }) => userReview.userReviewsData);

  const trans = settingsData.trans;

  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [sortOrder, setSortOrder] = useState('asc');
  const [sortBy, setSortBy] = useState('id');
  const [selectedReview, setSelectedReview] = useState(null);
  const [reviews, setReviews] = useState([]);
  const [storeItems, setStoreItems] = useState([]);

  useEffect(() => {
    reloadData();
  }, [page, rowsPerPage, sortOrder, sortBy]);

  useEffect(() => {
    if (userReviewsData) {
      setReviews(userReviewsData.user_reviews);
    }
  }, [userReviewsData]);

  const reloadData = () => {
    dispatch(postReviewssAll({ page: page + 1, limit: rowsPerPage, sort: sortBy, order: sortOrder }));
  };

  const handleSort = (property) => {
    const isAsc = sortBy === property && sortOrder === 'asc';
    setSortOrder(isAsc ? 'desc' : 'asc');
    setSortBy(property);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleViewDetails = (review) => {
    setSelectedReview(review);
  };

  const handleCloseDetails = () => {
    setSelectedReview(null);
  };

  return (
    <Container maxWidth="xl" sx={{ mt: 4, mb: 4 }}>
      <Grid container spacing={1}>
        <Grid item xs={12}>
          <Paper
            sx={{
              p: 2,
              display: "flex",
              flexDirection: { xs: "column", md: "row" },
              alignItems: "center",
              justifyContent: "space-between",
            }}
          >

            <div style={{flexGrow:1}}>
            <div style={{ width: "100% "}}>
              <PageTitleDesc
                title={settingsData.trans.menu_user_reviews_title}
                desc={settingsData.trans.menu_user_reviews_desc}
              />
            </div>

            <br/>

            <div style={{ width: "100% "}}>
            <StoresPicker
                  onStoreSelect={(selectedStores) => {
                    setStoreItems(selectedStores);
                  }}
                  multiple={false}
                  autoSelectFirst={true}
                />
            </div>
            </div>
           
          </Paper>
        </Grid>

       

        <Grid item xs={12}>
          <Paper sx={{ p: 2, display: "flex", flexDirection: "column" }}>
            <TableContainer>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell onClick={() => handleSort('nickname')}>{settingsData.trans.table_head_user_review_name}</TableCell>
                    <TableCell onClick={() => handleSort('avgStarRating')}>{settingsData.trans.table_head_user_review_avg_rating}</TableCell>
                    <TableCell onClick={() => handleSort('created_at')}>{settingsData.trans.table_head_user_review_date}</TableCell>
                    <TableCell>{settingsData.trans.table_head_actions}</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {reviews.map((review) => (
                    <TableRow key={review.id}>
                      <TableCell>{review.reviewUser.firstname} {review.reviewUser.lastname}</TableCell>
                      <TableCell>{review.avgStarRating.toFixed(1)}</TableCell>
                      <TableCell>{new Date(review.created_at).toLocaleString()}</TableCell>
                      <TableCell>
                        <Button
                          variant="contained"
                          onClick={() => handleViewDetails(review)}
                        >
                          {settingsData.trans.table_row_user_review_details}
                        </Button>
                      </TableCell>

                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
            <TablePagination
              rowsPerPageOptions={[5, 10, 25]}
              component="div"
              count={userReviewsData ? userReviewsData.total : 0}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          </Paper>
        </Grid>
      </Grid>

      <Dialog open={!!selectedReview} onClose={handleCloseDetails} maxWidth="md" fullWidth>
        <UserReviewDetails review={selectedReview} onClose={handleCloseDetails} />
      </Dialog>
    </Container>
  );
};

export default UserReviewsList;
