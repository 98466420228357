const environment = process.env.REACT_APP_ENV;
const envType = environment === `production` ? `production` : `staging`;
const isProduction = envType == "production";

// console.log("environment",environment);

//GENERAL VALUES
export const MAIN_ID = process.env.REACT_APP_MAIN_ID;
export const APP_NAME = process.env.REACT_APP_NAME;
export const WEB_SITE_NAME = process.env.REACT_APP_SITE_NAME;
export const DEBUG_MODE = !isProduction;
export const DATE_FORMAT = "DD/MM/yyyy HH:mm"
export const DATE_FORMAT_NO_TIME = "DD/MM/yyyy"
export const DATE_FORMAT_ONLY_TIME = "HH:mm"
export const PICKER_DATE_FORMAT = "dd/MM/yyyy HH:mm"
export const PRIMARY_COLOR = "#f05138";
export const DATE_TIME_FORMAT = "DD/MM/yyyy HH:mm"
export const LIGHTS_MODE = "LIGHTS_MODE_VALUE";


//URLS
export const WEB_SITE_URL = process.env.REACT_APP_WEB_URL;
export const API_URL = DEBUG_MODE ? process.env.REACT_APP_DEBUG_API_URL : process.env.REACT_APP_API_URL;
export const SOCKET_URL = DEBUG_MODE ? process.env.REACT_APP_DEBUG_SOCKET_URL : process.env.REACT_APP_SOCKET_URL;
export const UNSPLASH_KEY = process.env.UNSPLASH_KEY ?? "T7lkWNJ65xPjghKo1aY_sOnqz5C-1bbTwbU7Rv_E9tY";


//UPLOAD TYPES
export const APP_ASSETS = "appassets";
export const APP_ASSETS_STORES = "stores";
export const APP_ASSETS_PRODUCTS = "products";


//DATES
export const START_DATE = 'startDate';
export const END_DATE = 'endDate';

//IDS
export const SOURCE_TWITTER_ID = "";

//OPTIONS
export const SLUG_OPTIONS = {
    replacement: '-',  // replace spaces with replacement character, defaults to `-`
    remove: /[*+~.()/'"!:@,]/g, // remove characters that match regex, defaults to `undefined`
    lower: true,      // convert to lower case, defaults to `false`
    strict: false,     // strip special characters except replacement, defaults to `false`
    locale: 'vi',       // language code of the locale to use
    trim: true         // trim leading and trailing replacement chars, defaults to `true`
};

export const SEARCH_TYPE_EXACT = "exact";
export const SEARCH_TYPE_EXPANDED = "expanded";
export const SEARCH_TYPE_FUZZY = "fuzzy";


export const SOCIAL_IMAGE_TEMPLATES = [
    {
        id: 1,
        type: 'NEW',
        bgColorOne: "#ff7b35",
        bgColorTwo: "#ea4849",
        icon: "/social_icons/new.png"
    },
    {
        id: 2,
        type: 'HIDDEN GEM',
        bgColorOne: "#2b97ee",
        bgColorTwo: "#12e9fe",
        icon: "/social_icons/gem.png"
    },
    {
        id: 3,
        type: 'VIDEO',
        bgColorOne: "#dd2838",
        bgColorTwo: "#3e2878",
        icon: "/social_icons/video.png"
    },
    {
        id: 4,
        type: 'PODCAST',
        bgColorOne: "#c0bc37",
        bgColorTwo: "#369c60",
        icon: "/social_icons/podcast.png"
    },
    {
        id: 5,
        type: 'BOOK',
        bgColorOne: "#101b2e",
        bgColorTwo: "#305275",
        icon: "/social_icons/book.png"
    }
]

export const  editorConfig = {
    readonly: false ,
    iframe: true,
    video: true,
    height: 300
  }
  

  export const  editorConfigSmall = {
    readonly: false ,
    iframe: true,
    video: true,
    height: 150
  }
  

  export const PUSH_TYPES = {
    SIMPLE: "push_simple",
    ORDER_REPLY: "push_order_reply",
    NEW_ORDER: "push_new_order"
};

export const  USER_TYPES =  {
    ADMIN: "ADMIN",
    MANAGER: "MANAGER",
    APP_USER: "APP_USER",
    STORE_USER: "STORE_USER"
};