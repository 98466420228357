import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Dialog,
  DialogContent,
  DialogTitle,
  Button,
  List,
  ListItem,
  TextField,
  IconButton,
  Grid,
} from "@mui/material";

import CloseIcon from "@mui/icons-material/Close";
import WorkingHoursDay from "./WorkingHoursDay"; // You would create this component
import { postStoreWorkingHoursList, postStoreWorkingHoursUpdate } from "../../redux/stores/action";
import { POST_STORES_UPDATE_WORKING_HOURS } from "../../redux/actionTypes";
import { toast } from "react-toastify";

const StoreWorkingHoursDialog = (props) => {
 
  const settingsData = useSelector(({ settings }) => settings.settingsData);
  const workingHoursData = useSelector(
    ({ stores }) => stores.workingHoursData
  );
  const updateWorkingHoursData = useSelector(
    ({ stores }) => stores.updateWorkingHoursData
  );

  //Variables
  const dispatch = useDispatch();
  const trans = settingsData.trans;

  const [daysOfWeek, setDaysOfWeek] = useState([]);
  const [workingHours, setWorkingHours] = useState();

  useEffect(() => {
    if (props.open == true && props.storeItem != null) {
      getWorkingHoursData();
    }
  }, [props.open, props.storeItem]);


  useEffect(() => {
    if (updateWorkingHoursData != null) {
      if (updateWorkingHoursData.status) {
        toast.dismiss();
        toast.success(updateWorkingHoursData.message, {
            position: toast.POSITION.TOP_RIGHT
            });
            props.onDataUpdated();
      } else {
        toast.dismiss();
        toast.error(updateWorkingHoursData.message, {
            position: toast.POSITION.TOP_RIGHT
            });
      }
      dispatch({ type: POST_STORES_UPDATE_WORKING_HOURS, payload: null });
    }
  }, [updateWorkingHoursData]);

  useEffect(() => {
    if (settingsData != null) {
      var daysAdded = [
        trans.day_monday,
        trans.day_tuesday,
        trans.day_wednesday,
        trans.day_thursday,
        trans.day_friday,
        trans.day_saturday,
        trans.day_sunday,
      ];

      setDaysOfWeek(daysAdded);
    }
  }, [settingsData]);

  useEffect(() => {
    if (workingHoursData != null && daysOfWeek != null && daysOfWeek.length > 0) {
      const newWorkingHours = daysOfWeek.map((day, index) => {
        // Find the working hour data for the current day of the week
        const workingHourDataForDay = workingHoursData.workingHours.find(
          (wh) => wh.dayOfWeek === index + 1
        );
  
        // Map server format to UI expected format or set default times if not present
        const regularPeriods = workingHourDataForDay
          ? workingHourDataForDay.regularPeriods.map((period) => ({
              start: parseTime(period.startTime),
              end: parseTime(period.endTime),
            }))
          : [
              {
                start: parseTime("07:00"), // Default start time: 7 AM
                end: parseTime("08:00"), // Default end time: 8 AM
              },
            ];
  
        const exceptions = workingHourDataForDay
          ? workingHourDataForDay.exceptions.map((exception) => ({
              date: new Date(exception.date),
              start: parseTime(exception.startTime),
              end: parseTime(exception.endTime),
              isClosed: exception.isClosed,
            }))
          : [];
  
        return {
          dayOfWeek: day,
          regularPeriods,
          exceptions,
        };
      });
  
      setWorkingHours({ days: newWorkingHours });
    }
  }, [workingHoursData, daysOfWeek]);
  
  // Utility function to parse time string to Date object
  function parseTime(timeStr) {
    const [hours, minutes] = timeStr.split(":").map(Number);
    const date = new Date();
    date.setHours(hours, minutes, 0, 0); // Resets to today's date with specified hours and minutes
    return date;
  }
  

  const  getWorkingHoursData = () => {
    dispatch(postStoreWorkingHoursList({storeId: props.storeItem.id}));
  }

  const handlePeriodChange = (dayIndex, periods) => {
    const updatedWorkingHours = { ...workingHours };
    updatedWorkingHours.days[dayIndex].regularPeriods = periods;
    setWorkingHours(updatedWorkingHours);
  };

  const handleExceptionChange = (dayIndex, exceptions) => {
    const updatedWorkingHours = { ...workingHours };
    updatedWorkingHours.days[dayIndex].exceptions = exceptions;
    setWorkingHours(updatedWorkingHours);
  };

  const saveWorkingHours = () => {
    const formattedWorkingHours = workingHours.days.map(day => ({
      dayOfWeek: daysOfWeek.indexOf(day.dayOfWeek) + 1, // Convert day name to index (1 for Monday, etc.)
      regularPeriods: day.regularPeriods.map(period => ({
        startTime: formatTime(period.start), // Assuming 'start' is a Date object or similar
        endTime: formatTime(period.end),
      })),
      exceptions: day.exceptions.map(exception => ({
        date: formatDate(exception.date), // Assuming 'date' is a Date object
        startTime: formatTime(exception.start),
        endTime: formatTime(exception.end),
        isClosed: exception.isClosed,
      })),
    }));
  
    var postData = {
      langLoc: "en",
      storeId: props.storeItem.id,
      workingHours: formattedWorkingHours,
    };
  
    console.log(JSON.stringify(postData));
    dispatch(postStoreWorkingHoursUpdate(postData));
  };
  
  function formatTime(date) {
    // Manually construct a time string that ignores the timezone.
    const hours = date.getHours().toString().padStart(2, '0');
    const minutes = date.getMinutes().toString().padStart(2, '0');
    return `${hours}:${minutes}`; // Returns time in HH:mm format
  }
  
  function formatDate(date) {
    // Manually construct a date string in YYYY-MM-DD format.
    const year = date.getFullYear();
    const month = (date.getMonth() + 1).toString().padStart(2, '0'); // +1 because months are 0-indexed
    const day = date.getDate().toString().padStart(2, '0');
    return `${year}-${month}-${day}`;
  }
  
  

  return (
    <Dialog open={props.open} onClose={props.onClose} fullWidth maxWidth="md">
      <DialogTitle>
        <div style={{ display: "flex", justifyContent: "space-between" }}>
           {trans.working_hours_edit_title}
          <IconButton onClick={props.onClose}>
            <CloseIcon />
          </IconButton>
        </div>
      </DialogTitle>
      <DialogContent>
        {workingHours != null ? (
           <Grid container spacing={2}>
            <Grid item md={12}>
            <List>
              {workingHours.days.map((day, index) => (
                <ListItem key={day.dayOfWeek} divider>
                  <WorkingHoursDay
                    day={day.dayOfWeek}
                    regularPeriods={day.regularPeriods}
                    exceptions={day.exceptions}
                    onPeriodChange={(periods) =>
                      handlePeriodChange(index, periods)
                    }
                    onExceptionChange={(exceptions) =>
                      handleExceptionChange(index, exceptions)
                    }
                  />
                </ListItem>
              ))}
            </List>
            </Grid>
            <Grid item md={12}>
            <Button
              variant="contained"
              color="primary"
              onClick={saveWorkingHours}
            >
             {trans.working_hours_save_changes}
            </Button>
            </Grid>
           
          </Grid>
        ) : null}
      </DialogContent>
    </Dialog>
  );
};

export default StoreWorkingHoursDialog;
