import React from 'react';
import {useDispatch, useSelector} from "react-redux";
import { Dialog, DialogContent, IconButton, Typography } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import ChatComponent from './ChatComponent'; // Import your ChatComponent

const PopupChat = ({ isOpen, onClose, userId, selectedItem  }) => {

  const settingsData = useSelector(({settings}) => settings.settingsData);

  const trans = settingsData.trans;

  return (
    <Dialog open={isOpen} onClose={onClose} maxWidth="sm" fullWidth>
      <DialogContent>
        <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
          <Typography variant="h6">{trans.chat_with} {selectedItem != null ? selectedItem.fullname : ""}</Typography>
          <IconButton onClick={onClose} color="primary">
            <CloseIcon />
          </IconButton>
        </div>
        <ChatComponent chatUserId={userId} />
      </DialogContent>
    </Dialog>
  );
};

export default PopupChat;
