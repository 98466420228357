import React, { useState } from 'react';
import {  TextField } from '@mui/material';

const MyColorPicker = (props) => {


  return(<div className='_flex_row'>
     <TextField
      margin="dense"
      label={" "}
      style={{width:"30px"}}
      type="color"
      value={props.color}
      onChange={(event) => {props.onChangeColor(event.target.value)}}
      variant="standard"
      // You can add additional TextField props as needed
    />

      <TextField
              margin="dense"
              label={`${props.labelText}`}
              id="powered_by_bg"
              value={props.color}
              onChange={(event) => {props.onChangeColor(event.target.value)}}
              type="text"
              fullWidth
              variant="standard" />
  </div>);
};

export default MyColorPicker;
