import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Grid,
  TextField,
  FormControlLabel,
  Switch,
} from "@mui/material";
import DatePickerComponent from "../global/datePicker/DatePickerComponent";
import LangPicker from "../global/LangPicker";
import MultiLangTextField from "../global/textFields/MultilanguageTextField";
import {
  postCatalogExtrasSingle,
  postCatalogExtrasAddExtraItem,
} from "../../redux/catalog-extras/action";
import MyStatusPicker from "../global/statusPicker/MyStatusPicker";
import { getDefaultLanguage } from "../../utils/languages";
import { POST_CATALOG_EXTRAS_ADD_EXTRA_ITEM } from "../../redux/actionTypes";
import { showResponseMessage } from "../../utils/alerts/MyToastUtil";

const AddEditCatalogExtrasItem = (props) => {
  const settingsData = useSelector(({ settings }) => settings.settingsData);

  const dispatch = useDispatch();
  const trans = settingsData.trans;

  const singleCatalogExtrasData = useSelector(
    ({ catalogExtras }) => catalogExtras.singleCatalogExtrasData
  );

  const addEditCatalogExtraItemData = useSelector(
    ({ catalogExtras }) => catalogExtras.addEditCatalogExtraItemData
  );

  const [names, setNames] = useState([]);
  const [status, setStatus] = useState(true);
  const [price, setPrice] = useState(0);
  const [fixedPrice, setFixedPrice] = useState(0.0);
  const [disabled, setDisabled] = useState(false);
  const [disabledUntil, setDisabledUntil] = useState(null);
  const [selectedLang, setSelectedLang] = useState(
    getDefaultLanguage(settingsData.app_languages)
  );

  useEffect(() => {
    if (props.isOpen) {
      reloadData();
    }
  }, [props.isOpen, props.extraId]);

  useEffect(() => {
    if (addEditCatalogExtraItemData != null) {
      showResponseMessage(addEditCatalogExtraItemData);
      dispatch({ type: POST_CATALOG_EXTRAS_ADD_EXTRA_ITEM, payload: null });
      props.onItemUpdate();
      if (props.onClose) props.onClose();
    }
  }, [addEditCatalogExtraItemData]);

  useEffect(() => {
    if (props.isOpen && singleCatalogExtrasData && props.extraId) {
      console.log("props.extraItemId", props.extraItemId);
      const extra = singleCatalogExtrasData;
      if (props.extraItemId) {
        // Editing an existing extra item
        const item = extra.extra_items.find(
          (i) => i.extra_id === props.extraItemId
        );
        if (item) {
          console.log("setting item", item);
          setNames(item.names);
          setStatus(item.extra_status);
          setPrice(item.extra_price);
          setFixedPrice(item.extra_fixed_price);
          setDisabled(item.extra_disabled);
          setDisabledUntil(item.extra_disabled_until);
        } else {
          setNames([]);
          setStatus(true);
          setPrice(0);
          setFixedPrice(0.0);
          setDisabled(false);
          setDisabledUntil(null);
        }
      } else {
        // Adding a new extra item
        setNames([]);
        setStatus(true);
        setPrice(0);
        setFixedPrice(0.0);
        setDisabled(false);
        setDisabledUntil(null);
      }
    }
  }, [props.isOpen, props.extraId, props.extraItemId, singleCatalogExtrasData]);

  const reloadData = () => {
    if (props.extraId) {
      const postData = { itemId: props.extraId };
      dispatch(postCatalogExtrasSingle(postData));
    }
  };

  const handleSave = () => {
    var postData = {
      names,
      status,
      price,
      fixed_price: fixedPrice,
      disabled,
      disabled_until: disabledUntil,
    };

    if (props.extraId != null) {
      postData.extraId = props.extraId;
    }

    if (props.extraItemId != null) {
      postData.extraItemId = props.extraItemId;
    }

    console.log(postData);
    dispatch(postCatalogExtrasAddExtraItem(postData));
  };

  const handleClose = () => {
    clearData();
  };

  const clearData = () => {
    setNames([]);
    setSelectedLang(getDefaultLanguage(settingsData.app_languages));
    setStatus(true);
    setPrice(0);
    setFixedPrice(0.0);
    setDisabled(false);
    setDisabledUntil(null);
    props.onClose();
  };

  return (
    <Dialog open={props.isOpen} onClose={handleClose} maxWidth="md">
      <DialogTitle>
        {props.itemId
          ? trans.catalog_extra_edit_item_title
          : trans.catalog_extra_add_item_title}
      </DialogTitle>
      <DialogContent>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <LangPicker onLangSelectedChanged={setSelectedLang} />
          </Grid>

          <Grid item xs={12}>
            <MultiLangTextField
              label={trans.catalog_extra_item_name}
              currentLang={selectedLang}
              texts={names}
              onTextsChange={setNames}
            />
          </Grid>

          <Grid item xs={6}>
            <TextField
              label={trans.catalog_extra_item_price}
              type="number"
              fullWidth
              value={price}
              onChange={(e) => setPrice(e.target.value)}
            />
          </Grid>

          <Grid item xs={6}>
            <TextField
              label={trans.catalog_extra_item_fixed_price}
              type="number"
              fullWidth
              value={fixedPrice}
              onChange={(e) => setFixedPrice(e.target.value)}
            />
          </Grid>

          <Grid item xs={12}>
            <MyStatusPicker
              label={trans.catalog_extra_add_extra_status}
              status={status}
              onChange={(value) => setStatus(value)}
            />
          </Grid>

          <Grid item xs={9}>
            <MyStatusPicker
              label={trans.catalog_extra_add_extra_disabled}
              status={disabled}
              onChange={(value) => setDisabled(value)}
            />
          </Grid>

          <Grid item xs={3}>
            <DatePickerComponent
              label={trans.catalog_extra_add_extra_disabled_until}
              value={disabledUntil}
              onChange={(newDate) => {
                setDisabledUntil(newDate);
              }}
            />
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button onClick={props.onClose}>
          {trans.catalog_extra_state_cancel_button}
        </Button>
        <Button onClick={handleSave}>
          {trans.catalog_extra_state_save_button}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default AddEditCatalogExtrasItem;
