import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Grid,
  TextField,
  Box,
} from "@mui/material";
import { showResponseMessage } from "../../utils/alerts/MyToastUtil";
import { POST_CATALOG_EXTRAS_ADD_EDIT, POST_CATALOG_OFFERS_ADD_EDIT, POST_COUPONS_ADD_EDIT } from "../../redux/actionTypes";
import {
  postCatalogExtrasListAddEdit,
  postCatalogExtrasSingle,
} from "../../redux/catalog-offers/action";
import LangPicker from "../global/LangPicker";
import MultiLangTextField from "../global/textFields/MultilanguageTextField";
import MyStatusPicker from "../global/statusPicker/MyStatusPicker";
import MyListPicker from "../global/listPicker/MyListPicker";
import { getDefaultLanguage } from "../../utils/languages";
import { postCatalogOffersListAddEdit, postCatalogOffersSingle } from "../../redux/catalog-offers/action";
import StoresPicker from "../stores/StoresPicker";
import DatePickerComponent from "../global/datePicker/DatePickerComponent";
import UsersPicker from "../users/UsersPicker";
import { postCouponsListAddEdit, postCouponsSingle } from "../../redux/coupons/action";

const AddEditCoupon = (props) => {
  const settingsData = useSelector(({ settings }) => settings.settingsData);
  const addEditCouponData = useSelector(({ coupons }) => coupons.addEditCouponData);
  const singleCouponData = useSelector(({ coupons }) => coupons.singleCouponData);

  const dispatch = useDispatch();
  const trans = settingsData.trans;

  const [couponCode, setCouponCode] = useState('');
  const [selectedLang, setSelectedLang] = useState(
    getDefaultLanguage(settingsData.app_languages)
  );
  const [descriptions, setDescriptions] = useState([]);
  const [coupon_type, setCouponType] = useState('PERCENT');
  const [discountPercentage, setDiscountPercentage] = useState(0);
  const [discount_price, setDiscountPrice] = useState(0);
  const [isExpiring, setIsExpiring] = useState(false);
  const [status, setStatus] = useState(true);
  const [allStores, setAllStores] = useState(true);
  const [storeItems, setStoreItems] = useState([]);
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [allUsers, setAllUsers] = useState(true);
  const [userItems, setUseritems] = useState([]);

  const couponTypes = [
    {
      value: "PERCENT",
      text: trans.coupon_type_percent,
    },
    {
      value: "PRICE",
      text: trans.coupon_type_price,
    },
  ];

  useEffect(() => {
    if (props.isOpen && props.itemId) {
      // Load data for editing
      reloadData();
    } else if (props.isOpen) {
      // Setup for adding new coupon
      clearData();
    }
  }, [props.isOpen, props.itemId]);


  useEffect(() => {
    if (addEditCouponData != null) {
      showResponseMessage(addEditCouponData);
      dispatch({ type: POST_COUPONS_ADD_EDIT, payload: null });
      props.onItemUpdate();
      if (props.onClose) props.onClose();
    } 
  }, [addEditCouponData]);

  
  useEffect(() => {
    if (singleCouponData != null) {
      setCouponCode(singleCouponData.coupon_code);
      setCouponType(singleCouponData.coupon_type);
      setDiscountPercentage(singleCouponData.discount_percentage);
      setDiscountPrice(singleCouponData.discount_price);
      setDescriptions(singleCouponData.descriptions);
      setIsExpiring(singleCouponData.isExpiring);
      setStatus(singleCouponData.status.value);
      setAllStores(singleCouponData.allStores);
      setStoreItems(singleCouponData.stores);
      setStartDate(singleCouponData.start_date);
      setEndDate(singleCouponData.end_date);
      setAllUsers(singleCouponData.allUsers);
      setUseritems(singleCouponData.users);
    } 
  }, [singleCouponData]);

  

  const reloadData = () => {
    let postData = { id: props.itemId };
    dispatch(postCouponsSingle(postData));
  };

  const handleSave = () => {

    const postData = {
      couponId: props.itemId,
      descriptions,
      coupon_type: coupon_type,
      discount_percentage: discountPercentage,
      discount_price: discount_price,
      coupon_code: couponCode,
      isExpiring,
      allStores,
      stores: storeItems,
      allUsers: allUsers, 
      users: userItems,
      start_date: startDate,
      end_date: endDate,
      status: {
        value: status
      },
    };

    dispatch(postCouponsListAddEdit(postData));
  };

  const handleDescriptionChange = (newDescriptions) => {
    setDescriptions(newDescriptions);
  };

  const handleClose = () => {
    clearData();
    if (props.onClose) props.onClose();
  };

  const clearData = () => {
    setCouponCode('');
    setDescriptions([]);
    setSelectedLang(getDefaultLanguage(settingsData.app_languages));
    setCouponType('PERCENT');
    setDiscountPercentage(0);
    setDiscountPrice(0);
    setIsExpiring(false);
    setStatus(true);
    setAllStores(true);
    setStoreItems([]);
  };

  const changeLanguage = (lang) => {
    setSelectedLang(lang);
  };

  return (
    <Dialog open={props.isOpen} onClose={handleClose} fullWidth maxWidth="md">
      <DialogTitle>{props.itemId ? trans.coupons_edit_title : trans.coupons_add_title}</DialogTitle>
      <DialogContent>
        <Grid container spacing={2}>
        <Grid item xs={12}>
            <LangPicker selectedLang={selectedLang} onLangSelectedChanged={changeLanguage} />
            <MultiLangTextField
              label={trans.coupon_description_field}
              currentLang={selectedLang}
              texts={descriptions}
              onTextsChange={handleDescriptionChange}
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              label={trans.coupon_code_field}
              value={couponCode}
              onChange={(e) => setCouponCode(e.target.value)}
              fullWidth
            />
          </Grid>

          <Grid item xs={6}>
            <MyStatusPicker
              label={trans.coupon_status_field}
              status={status}
              onChange={setStatus}
            />
          </Grid>

          <Grid item xs={6}>
          <MyListPicker
              labelId={"coupon_type"}
              label={trans.coupon_type_field}
              selected={coupon_type}
              onChange={(value) => setCouponType(value)}
              list={couponTypes}
            />
          </Grid>

          <Grid item xs={6}>
            {coupon_type == "PRICE" ?  <TextField
              label={trans.coupon_discount_price_field}
              type="number"
              value={discount_price}
              onChange={(e) => setDiscountPrice(e.target.value)}
              fullWidth
            /> : <TextField
            label={trans.coupon_discount_percentage_field}
            type="number"
            value={discountPercentage}
            onChange={(e) => setDiscountPercentage(e.target.value)}
            fullWidth
          />}
            
          </Grid>

        


          <Grid item xs={6}>
            <MyStatusPicker
              label={trans.coupon_is_expiring_field}
              status={isExpiring}
              onChange={setIsExpiring}
            />
          </Grid>

         <Grid item xs={3}>
          {isExpiring  ?   <DatePickerComponent
              label={trans.coupon_start_date_label}
              value={startDate}
              onChange={(newDate) => {setStartDate(newDate)}}
            /> : null}
          </Grid> 

      <Grid item xs={3}>
      {isExpiring  ?  <DatePickerComponent
              label={trans.coupon_end_date_label}
              value={endDate}
              onChange={(newDate) => {setEndDate(newDate)}}
            />: null}
          </Grid> 

          <Grid item xs={6}>
            <MyStatusPicker
              label={trans.coupon_all_stores}
              status={allStores}
              onChange={setAllStores}
            />
          </Grid>
          
          <Grid item xs={6}>
           {allStores == false ?  <StoresPicker
              onStoreSelect={setStoreItems}
              multiple={true}
              preSelectedStoreIds={storeItems}
            /> : null}
          </Grid>

          <Grid item xs={6}>
            <MyStatusPicker
              label={trans.coupon_all_users}
              status={allUsers}
              onChange={setAllUsers}
            />
          </Grid>
          
          <Grid item xs={6}>
           {allUsers == false ? <UsersPicker
            onUserSelect={setUseritems}
            multiple={true}
            autoSelectFirst={false}
            preSelectedUserIds={userItems} // example user IDs
          /> : null}
          </Grid>
          
       
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose}>{trans.catalog_offer_state_cancel_button}</Button>
        <Button onClick={handleSave}>{trans.catalog_offer_state_save_button}</Button>
      </DialogActions>
    </Dialog>
  );
};

export default AddEditCoupon;
