import React from 'react';
import { useSelector } from "react-redux";
import CircleLoader from "react-spinners/CircleLoader";

const LoadingDataWidget = (props) => { 
  const settingsData = useSelector(({ settings }) => settings.settingsData);

  return (
    <div style={{ 
      display: 'flex', 
      justifyContent: 'center', 
      alignItems: 'center', 
    }}>
      <CircleLoader
        color={settingsData != null ? settingsData.initData.app_color : 'red'}
        loading={true}
        size={50}
        aria-label="Loading Spinner"
        data-testid="loader"
      />
    </div>
  );
}

export default LoadingDataWidget;
