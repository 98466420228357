import {
  POST_SIDE_MENU_ALL,
  POST_SIDE_MENU_ADD,
  POST_SIDE_MENU_EDIT,
  POST_SIDE_MENU_DELETE,
  POST_SIDE_MENU_REORDER
} from "../actionTypes";

const INIT_STATE = {
  sideMenusData: null,
  addSideMenuData: null,
  editSideMenuData: null,
  deleteSideMenuData: null,
  reorderSideMenuData: null
};

const reducer = (state = INIT_STATE, action) => {
  switch (action.type) {
    case POST_SIDE_MENU_ALL:
      return {
        ...state,
        sideMenusData: action.payload,
      };
    case POST_SIDE_MENU_ADD:
      return {
        ...state,
        addSideMenuData: action.payload,
      };
    case POST_SIDE_MENU_EDIT:
      return {
        ...state,
        editSideMenuData: action.payload,
      };
    case POST_SIDE_MENU_DELETE:
      return {
        ...state,
        deleteSideMenuData: action.payload,
      };
    case POST_SIDE_MENU_REORDER:
        return {
          ...state,
          reorderSideMenuData: action.payload,
      };
    default:
      return state;
  }
};

export default reducer;
