import {
  POST_CATALOG_OFFERS_LIST_ALL,
  POST_CATALOG_OFFERS_ADD_EDIT,
  POST_CATALOG_OFFERS_DELETE,
  POST_CATALOG_OFFERS_SINGLE,
  POST_CATALOG_OFFERS_EDIT_PRODUCTS
} from "../actionTypes";

const INIT_STATE = {
  offersListData: null,
  addEditOfferData: null,
  singleOfferData: null,
  deleteOfferData: null,
  editOfferProductsData: null
};

const reducer = (state = INIT_STATE, action) => {
  switch (action.type) {
    case POST_CATALOG_OFFERS_LIST_ALL:
      return {
        ...state,
        offersListData: action.payload,
      };
    case POST_CATALOG_OFFERS_ADD_EDIT:
      return {
        ...state,
        addEditOfferData: action.payload,
      };
    case POST_CATALOG_OFFERS_SINGLE:
      return {
        ...state,
        singleOfferData: action.payload,
      };
    case POST_CATALOG_OFFERS_DELETE:
      return {
        ...state,
        deleteOfferData: action.payload,
      };
    case POST_CATALOG_OFFERS_EDIT_PRODUCTS: 
      return {
        ...state,
        editOfferProductsData: action.payload,
      };
    default:
      return state;
  }
};

export default reducer;
