import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
import LangPicker from "../global/LangPicker";
import MultiLangTextField from "../global/textFields/MultilanguageTextField";
import MyStatusPicker from "../global/statusPicker/MyStatusPicker";
import { getDefaultLanguage } from "../../utils/languages";
import MyListPicker from "../global/listPicker/MyListPicker";
import { toast } from "react-toastify";

const POSITIONS = [
  { value: "RIGHT", text: "Right" },
  { value: "LEFT", text: "Left" },
  // Add other positions as needed
];

const AddEditSideMenu = (props) => {
  const { editItem } = props;

  const settingsData = useSelector(({ settings }) => settings.settingsData);

  const trans = settingsData.trans;
  const dispatch = useDispatch();

  //HOOKS VARIABLES
  const [page_types_options, setPageTypesOptions] = useState([]);
  const [selectedLang, setSelectedLang] = useState(
    getDefaultLanguage(settingsData.app_languages)
  );
  const [names, setNames] = useState([]);
  const [pageType, setPageType] = useState("");
  const [position, setPosition] = useState("RIGHT");
  const [icon, setIcon] = useState("");
  const [iconUrl, setIconUrl] = useState("");
  const [status, setStatus] = useState(true);

  //HOOKS
  useEffect(() => {
    var pageTypesOptions = [];
    settingsData.pageTypesItems.forEach((element) => {
      pageTypesOptions.push({
        value: element.slug_type,
        text: element.name,
      });
    });
    setPageTypesOptions(pageTypesOptions);
  }, [settingsData]);

  useEffect(() => {
   if (props.toGetData) {
        let postData = makePostItem();

        if (editItem != null) {
          postData.sideMenuId = editItem.id;
        }
        

        props.onSubmitData(postData);
    }
  }, [props.toGetData]);

  const makePostItem = () => {
    let postData = {
      names: names,
      page_type: pageType,
      position:position,
      icon: icon,
      status: status
    };

    return postData;
  };

  useEffect(() => {
    if (editItem != null) {
      setNames(editItem.names);
      setPageType(editItem.menu_page_type);
      setPosition(editItem.position);
      setIcon(editItem.menu_icon_type);
      setIconUrl(editItem.menu_icon);
      setStatus(editItem.active);
    } else {
      clearData();
    }
  }, [editItem]);

  useEffect(() => {
    if (props.toClearData) {
      clearData();
    }
  }, [props.toClearData]);

   const clearData = () => {
      setNames([]);
      setPageType("");
      setPosition("RIGHT");
      setIcon("");
      setIconUrl("");
      setStatus(true);
  };
  
  const changeLanguage = (lang) => {
    setSelectedLang(lang);
  };

  const handlePageTypChange = (newPageType) => {
    setPageType(newPageType);
    var iconItem = "";
    var iconItemUrl = "";
    settingsData.pageTypesItems.forEach(element => {
      if (element.slug_type == newPageType) {
        iconItem = element.icon;
        iconItemUrl = element.icon_url;
      }
    });
    setIcon(iconItem);
    setIconUrl(iconItemUrl);
  }

  // UI
  return (
    <div>
      <Grid container spacing={3} alignItems="center">
        <Grid item xs={12}>
          <LangPicker onLangSelectedChanged={changeLanguage} />
        </Grid>

        <Grid item xs={12}>
          <MultiLangTextField
            label={trans.table_head_side_menu_name}
            currentLang={selectedLang}
            texts={names}
            onTextsChange={(texts) => setNames(texts)}
          />
        </Grid>

        <Grid item xs={1}>
          <img src={iconUrl} width="30px"></img>
        </Grid>
    
        <Grid item xs={11}>
          <MyListPicker
            label="Page Type"
            selected={pageType}
            onChange={handlePageTypChange}
            list={page_types_options}
          />
        </Grid>

        {/* <Grid item xs={6}>
          <MyListPicker
            label="Position"
            selected={position}
            onChange={setPosition}
            list={POSITIONS}
          />
        </Grid> */}
     
        <Grid item xs={12}>
          <MyStatusPicker
            label={trans.side_menu_status}
            status={status}
            onChange={(value) => setStatus(value)}
          />
        </Grid>
      </Grid>
    </div>
  );
};

export default AddEditSideMenu;
