import { ListItem, ListItemText, ListItemIcon, IconButton } from "@mui/material";
import React from "react";
import { SortableElement,SortableHandle } from 'react-sortable-hoc';
import DragIndicatorIcon from '@mui/icons-material/DragIndicator'; // Ensure you've imported the icons library
import EditIcon from '@mui/icons-material/Edit';
import PermMediaIcon from '@mui/icons-material/PermMedia';
import DeleteIcon from '@mui/icons-material/Delete';

import LocalPharmacyIcon from '@mui/icons-material/LocalPharmacy';


// The drag handle component
const DragHandle = SortableHandle(() => (
  <ListItemIcon>
    <DragIndicatorIcon style={{ cursor: 'grab' }} />
  </ListItemIcon>
));

// Sortable component for each product
export const SortableProduct = SortableElement(({ product, onEditProduct }) => (
  <div className="_flex_row_space" style={{marginTop:'15px', width: '100%', display: 'flex', justifyContent: 'space-between', alignItems: 'center'}}>
       <DragHandle />
      {product.main_image_thumb != null && product.main_image_thumb != "" ? <img src={product.main_image_thumb} style={{width:"50px", height:'50px', objectFit:'cover',borderRadius: '10%'}}></img> : null}
       <div style={{flexGrow: 1, display: 'flex', flexDirection: 'column', alignItems: 'flex-start', margin: '0 10px'}}>
       
           <div style={{fontSize:'14px'}} className="fontBold">{product.name}</div>
           <div style={{fontSize:'13px'}} className="fontBook">{`Price: ${product.price}€`}</div>
           <div style={{fontSize:'13px'}}>{product.description}</div>
       </div>
       <div>
       <IconButton onClick={() => onEditProduct(product,EDIT_PRODUCT_TYPE_EXTRAS)} size="large">
           <LocalPharmacyIcon />
         </IconButton>
        <IconButton onClick={() => onEditProduct(product,EDIT_PRODUCT_TYPE_IMAGES)} size="large">
           <PermMediaIcon />
         </IconButton>
         <IconButton onClick={() => onEditProduct(product,EDIT_PRODUCT_TYPE_EDIT)} size="large">
           <EditIcon />
         </IconButton>
         <IconButton onClick={() => onEditProduct(product,EDIT_PRODUCT_TYPE_DELETE)} size="large">
           <DeleteIcon />
         </IconButton>
       </div>
  </div> 
));


export const EDIT_PRODUCT_TYPE_EDIT = "EDIT_PRODUCT_TYPE_EDIT";
export const EDIT_PRODUCT_TYPE_IMAGES = "EDIT_PRODUCT_TYPE_IMAGES";
export const EDIT_PRODUCT_TYPE_EXTRAS = "EDIT_PRODUCT_TYPE_EXTRAS";
export const EDIT_PRODUCT_TYPE_DELETE = "EDIT_PRODUCT_TYPE_DELETE";