import React, { useState,useEffect,useImperativeHandle, forwardRef } from 'react';
import {useDispatch, useSelector} from "react-redux";
import { preview_image } from '../../../images';
import { useTheme } from '@mui/material/styles';
import { Button } from '@mui/material';
import FileUploadIcon from '@mui/icons-material/FileUpload';
import { postUploadImage } from '../../../redux/upload/action';
import { APP_ASSETS } from '../../../constants';
import { toast } from 'react-toastify';
import { POST_UPLOAD_IMAGE } from '../../../redux/actionTypes';

const ImageSelector = forwardRef((props, ref) => {

  const uploadImageData = useSelector(({upload}) => upload.uploadImageData);

  const dispatch = useDispatch();
  const theme = useTheme();

  //SELECTORS
  const settingsData = useSelector(({settings}) => settings.settingsData);
  const [imageInitialUrl, setImageInitialUrl] = useState(props.initialImageUrl);
  const [selectedFile, setSelectedFile] = useState(null);
  const trans = settingsData.trans;

  useEffect(() => { 
    if (uploadImageData != null){
      if (props.type == uploadImageData.assettype){
        if (uploadImageData.status) {
          toast.dismiss();
          toast.success(uploadImageData.message, {
              position: toast.POSITION.TOP_RIGHT
              });

              props.onSave(uploadImageData)
        } else {
          toast.dismiss();
          toast.error(uploadImageData.message, {
              position: toast.POSITION.TOP_RIGHT
              });
        }
      }
      dispatch({type: POST_UPLOAD_IMAGE, payload: null});
    }
  }, [uploadImageData]);

  const handleImageChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        setImageInitialUrl(reader.result);
        setSelectedFile(file);
      };
      reader.readAsDataURL(file);
    }
  };

  const handleSave = () => {
    if (selectedFile) {
        uploadImage(selectedFile);
    }
  };

  const uploadImage = (imageFile) => {
    var formData = new FormData();
    formData.append('path',props.path ?? APP_ASSETS);
    formData.append("image_file", imageFile);
    formData.append("mainOnly", props.mainOnly ?? "true");
    formData.append("asset_type", props.type);
    dispatch(postUploadImage(formData));
  }

  // useEffect can be used here if there is a need to perform side effects
  // For example, if you want to reset the component state when the initialImageUrl prop changes:
  useEffect(() => {
    setImageInitialUrl(props.initialImageUrl);
    setSelectedFile(null);
  }, [props.initialImageUrl]);

  useImperativeHandle(ref, () => ({
    clearData() {
      // logic to clear data in ImageSelector
      setSelectedFile(null);
      setImageInitialUrl(null);
    },
  }));

  return (
    <div style={{backgroundColor: theme.palette.background.default, borderRadius:'8px',padding:'10px'}}>
        <div style={{display:'flex', flexDirection:'column', justifyContent:'center', alignItems:'center'}}>
            <div className='fontMedium' style={{fontSize:'14px'}}>{props.title}</div>
            <p className='fontRegular' style={{fontSize:'13px'}}>{props.desc}</p>
            {imageInitialUrl != null && imageInitialUrl != "" ? 
                <img src={imageInitialUrl} alt="Initial" style={{ width: '100px', height: '100px', objectFit:'contain'}} /> :
                <img src={preview_image} alt="Preview" style={{ width: '20%',height: '100px', objectFit:'contain' }} />
            }

        
            <Button style={{marginTop:'10px',textTransform: 'none'}} size="small" variant="outlined" component="label">
                {trans.choose_file}
                <input
                    type="file"
                    accept="image/*"
                    onChange={handleImageChange}
                    id="fileInput"
                    style={{ display: 'none' }}
                />
            </Button>
            {selectedFile == null ? <div style={{height: '30px'}}></div> : 
              <Button 
                style={{marginTop:'10px'}} 
                startIcon={<FileUploadIcon />} 
                size="small" 
                color="primary" 
                onClick={(e) => handleSave(e)}  
                variant="contained">{trans.save_image_button}
              </Button>
            }
        </div>
    </div>
  );
});

export default ImageSelector;
