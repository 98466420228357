import React, { useEffect, useState } from "react";
import { Grid, Button, CircularProgress } from "@mui/material";
import { toast } from "react-toastify";
import { UNSPLASH_KEY } from "../../../constants";
import { useDispatch,useSelector } from "react-redux";
import { postUploadImage } from "../../../redux/upload/action";
import { POST_UPLOAD_IMAGE } from "../../../redux/actionTypes";
import FileUploadIcon from "@mui/icons-material/FileUpload";


const ImageSuggestions = ({ productTitle, onSelectImage }) => {
  const [suggestions, setSuggestions] = useState([]);
  const [loading, setLoading] = useState(false);
  const [uploading, setUploading] = useState(null);
  const dispatch = useDispatch();

  //SELECTORS
  const settingsData = useSelector(({ settings }) => settings.settingsData);

  //VARIABLES
  const trans = settingsData.trans;

  useEffect(() => {
    if (productTitle) {
      fetchSuggestions(productTitle);
    }
  }, [productTitle]);

  const fetchSuggestions = async (query) => {
    setLoading(true);
    try {
      const response = await fetch(
        `https://api.unsplash.com/search/photos?query=${query}&client_id=${UNSPLASH_KEY}`
      );
      const data = await response.json();
      setSuggestions(data.results);
    } catch (error) {
      toast.error("Failed to fetch image suggestions");
    }
    setLoading(false);
  };

  const handleImageUpload = async (imageUrl, index) => {
    setUploading(index);
    try {
      // Step 1: Download the image as a blob
      const response = await fetch(imageUrl);
      const blob = await response.blob();

      // Step 2: Convert the blob to a file
      const file = new File([blob], "suggested-image.jpg", { type: blob.type });

      // Step 3: Create FormData and dispatch the upload action
      var formData = new FormData();
      formData.append("path", "/products"); // Adjust this path as needed
      formData.append("image_file", file);
      formData.append("mainOnly", "false");
      formData.append("asset_type", "product");

      dispatch(postUploadImage(formData));

      // Step 4: Listen for the upload completion
      const unsubscribe = dispatch({
        type: POST_UPLOAD_IMAGE,
        callback: (uploadedData) => {
          if (uploadedData.status) {
            toast.success("Image uploaded successfully");
            onSelectImage(uploadedData); // Return the uploaded file data
          } else {
            toast.error("Image upload failed");
          }
          setUploading(null);
          unsubscribe();
        },
      });
    } catch (error) {
      toast.error("Failed to upload image");
      setUploading(null);
    }
  };

  return (
    <div>
      {loading ? (
        <CircularProgress />
      ) : (
        <div>
          <h3>{trans.image_suggestions}</h3>
          <Grid container spacing={2}>
            {suggestions.map((image, index) => (
              <Grid item xs={3} key={image.id}>
                <div
                  style={{
                    textAlign: "center",
                    display: "flex",
                    flexDirection: "column",
                  }}
                >
                  <img
                    src={image.urls.thumb}
                    alt={image.alt_description}
                    style={{
                      width: "100%",
                      height: "120px",
                      objectFit: "cover",
                    }}
                  />
                  <Button
                    variant="contained"
                    size="small"
                    startIcon={<FileUploadIcon />}
                    style={{ marginTop: "8px" }}
                    onClick={() => handleImageUpload(image.urls.full, index)}
                    disabled={uploading === index}
                  >
                    {uploading === index ? "Uploading..." : "Upload"}
                  </Button>
                </div>
              </Grid>
            ))}
          </Grid>
        </div>
      )}
    </div>
  );
};

export default ImageSuggestions;
