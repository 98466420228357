import { toast } from "react-toastify";

export const showResponseMessage = (responseData) => {
    if (responseData.message != null && responseData.message != ""){
    if (responseData.status) {
        toast.dismiss();
        toast.success(responseData.message, {
            position: toast.POSITION.TOP_RIGHT
            });
      } else {
        toast.dismiss();
        toast.error(responseData.message, {
            position: toast.POSITION.TOP_RIGHT
            });
      }
    }
}