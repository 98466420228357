import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Container from "@mui/material/Container";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Button from "@mui/material/Button";
import { postStoresAdd, postStoresAll } from "../../redux/stores/action";
import { Grid, Paper } from "@mui/material";
import PageTitleDesc from "../global/pageTitleDesc/PageTitleDesc";
import ListImage from "../global/listWidgets/ListImage";
import { getStatusText } from "../../utils/tables/tableUtils";
import AddEditStore from "./AddEditStore";
import FormDialog from "../global/formDialog";
import { toast } from "react-toastify";
import { POST_STORES_ADD } from "../../redux/actionTypes";
import ZoneDialog from "./ZoneDialog";
import StoreOrderDetailsDialog from "./StoreOrderDetailsDialog";
import StoreWorkingHoursDialog from "./StoreWorkingHoursDialog";
import StoreContactOptionsDialog from "./StoreContactOptionsDialog";

const StoresList = () => {
  //Selectors
  const settingsData = useSelector(({ settings }) => settings.settingsData);
  const storesData = useSelector(({ stores }) => stores.storesData);
  const addStoreData = useSelector(({ stores }) => stores.addStoreData);

  //Variables
  const dispatch = useDispatch();
  const trans = settingsData.trans;

  //Hooks
  const [storesList, setStoresList] = useState([]);
  const [addModal, setAddModal] = useState(false);
  const [toGetData, setToGetData] = useState(false);
  const [toClearData, setToClearData] = useState(false);
  const [editItem, setEditItem] = useState(null);
  const [openZones, setOpenZones] = useState(false);
  const [zoneEditItem, setZoneEditItem]= useState(null);
  const [openPaymentMethods, setOpenPaymentMethods] = useState(false);
  const [paymentMethodEditItem, setPaymentMethodEditItem]= useState(null);
  const [openWorkingHours, setOpenWorkingHours] = useState(false);
  const [workingHoursEditItem, setWorkingHoursEditItem]= useState(null);

  const [openContactOptions, setOpenContactOptions] = useState(false);
  const [contactOptionsEditItem, setContactOptionsEditItem]= useState(null);

  

  useEffect(() => {
    reloadData();
  }, []);

  useEffect(() => {
    if (storesData) {
      setStoresList(storesData.stores);
    }
  }, [storesData]);

  const getData = () => {
    dispatch(postStoresAll({}));
  };

  const reloadData = () => {
    getData();
  };

  useEffect(() => {
    //ADD DATA
    if (addStoreData != null) {
      if (addStoreData.status) {
        toast.dismiss();
        toast.success(addStoreData.message, {
          position: toast.POSITION.TOP_RIGHT,
        });
        setToClearData(true);
        reloadData();
      } else {
        toast.dismiss();
        toast.error(addStoreData.message, {
          position: toast.POSITION.TOP_RIGHT,
        });
      }
      dispatch({ type: POST_STORES_ADD, payload: null });
    }
  }, [addStoreData]);

  const handleEdit = (e, item) => {
    e.preventDefault();
    setToClearData(false);
    setEditItem(item);
    setAddModal(true);
  };

  const onAddNew = (e) => {
    e.preventDefault();
    //OPEN THE ADD NEW MODAL
    setEditItem(null);
    setAddModal(true);
  };

  const onDialogClose = () => {
    setAddModal(false);
  };

  const onDialogSubmit = () => {
    setToGetData(true);
  };

  const onSubmitData = (data) => {
    if (data != null) {
      let postData = data;
      setEditItem(null);
      dispatch(postStoresAdd(postData));
      setToGetData(false);
      setToClearData(false);
      onDialogClose();
    } else {
      setToGetData(false);
    }
  };

  const handleAddStore = (e) => {
    e.preventDefault();
    setEditItem(null);
    setAddModal(true);
  };

  const handleZonesEdit = (e, item) => {
    e.preventDefault();
    setZoneEditItem(item);
    setOpenZones(true);
  };

  const handlePaymentMethodsEdit = (e, item) => {
    e.preventDefault();
    setPaymentMethodEditItem(item);
    setOpenPaymentMethods(true);
  };

  const handleWorkingHoursEdit = (e,item) => {
    e.preventDefault();
    setWorkingHoursEditItem(item);
    setOpenWorkingHours(true);
  }

  const handleContactOptionsEdit = (e,item) => {
    e.preventDefault();
    setContactOptionsEditItem(item);
    setOpenContactOptions(true);
  }

  return (
    <Container maxWidth="xl" sx={{ mt: 4, mb: 4 }}>
      <Grid container spacing={1}>
        <Grid item xs={12}>
          <Paper
            sx={{
              p: 2,
              display: "flex",
              flexDirection: { xs: "column", md: "row" },
              alignItems: "center",
              justifyContent: "space-between",
            }}
          >
            <div style={{ flexGrow: 1 }}>
              <PageTitleDesc
                title={settingsData.trans.stores_list_title}
                desc={settingsData.trans.stores_list_desc}
              />
            </div>
            <Button
              variant="contained"
              onClick={handleAddStore}
              sx={{ mt: { xs: 2, md: 0 } }} // Conditional top margin
            >
              {trans.add_store_title}
            </Button>
          </Paper>
        </Grid>

        <Grid item xs={12}>
          <Paper sx={{ p: 2, display: "flex", flexDirection: "column" }}>
            <TableContainer>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell>{trans.table_head_image}</TableCell>
                    <TableCell>{trans.table_head_name}</TableCell>
                    <TableCell>{trans.table_head_status}</TableCell>
                    <TableCell>{trans.table_head_actions}</TableCell>
                    <TableCell></TableCell>
                    <TableCell></TableCell>
                    <TableCell></TableCell>
                    <TableCell></TableCell>
                    
                  </TableRow>
                </TableHead>
                <TableBody>
                  {storesList.map((store) => (
                    <TableRow key={store.id}>
                      <TableCell component="th" scope="row">
                        <ListImage url={store.logo_thumb_url} />
                      </TableCell>
                      <TableCell
                        component="th"
                        scope="row"
                        className="fontBold"
                      >
                        {store.name}
                      </TableCell>
                      <TableCell component="th" scope="row">
                        {getStatusText(store.active, trans)}
                      </TableCell>
                      <TableCell>
                        <Button
                          variant="contained"
                          onClick={(e) => handleEdit(e, store)}
                        >
                          {trans.table_row_edit_button}
                        </Button>
                      </TableCell>
                      <TableCell>
                        <Button
                          variant="contained"
                          onClick={(e) => handlePaymentMethodsEdit(e, store)}
                        >
                          {trans.table_store_payment_methods_button}
                        </Button>
                      </TableCell>
                      <TableCell>
                        <Button
                          variant="contained"
                          onClick={(e) => handleWorkingHoursEdit(e, store)}
                        >
                          {trans.table_store_working_hours_button}
                        </Button>
                      </TableCell>
                      <TableCell>
                        <Button
                          variant="contained"
                          onClick={(e) => handleContactOptionsEdit(e, store)}
                        >
                          {trans.table_store_contact_options_button}
                        </Button>
                      </TableCell>
                      {store.delivery_method == "ZONE" ? (
                        <TableCell>
                          <Button
                            variant="contained"
                            onClick={(e) => handleZonesEdit(e, store)}
                          >
                            {trans.table_row_edit_zones_button}
                          </Button>
                        </TableCell>
                      ) : null}
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </Paper>
        </Grid>
      </Grid>

      <FormDialog
        open={addModal}
        onClose={onDialogClose}
        title={`${
          editItem == null ? trans.add_store_title : trans.edit_store_title
        }`}
        body={
          <AddEditStore
            toGetData={toGetData}
            toClearData={toClearData}
            onSubmitData={onSubmitData}
            realoadData={() => {
              reloadData();
            }}
            editItem={editItem}
          />
        }
        onSubmit={onDialogSubmit}
        maxWidth={"md"}
      />

      <ZoneDialog
        open={openZones}
        storeItem={zoneEditItem}
        onCloseZones={() => {
            setOpenZones(false);
            setZoneEditItem(null);
        }}
      />
      
      <StoreOrderDetailsDialog
        open={openPaymentMethods}
        storeItem={paymentMethodEditItem}
        onClosePaymentMethods={() => {
          setOpenPaymentMethods(false);
          setPaymentMethodEditItem(null);
        }}
        onDataUpdated={() => {
          reloadData();
        }}
      />

    <StoreWorkingHoursDialog
        open={openWorkingHours}
        storeItem={workingHoursEditItem}
        onClose={() => {
          setOpenWorkingHours(false);
          setWorkingHoursEditItem(null);
        }}
        onDataUpdated={() => {
          reloadData();
        }}
      />

<StoreContactOptionsDialog
        open={openContactOptions}
        storeItem={contactOptionsEditItem}
        onClose={() => {
          setOpenContactOptions(false);
          setContactOptionsEditItem(null);
        }}
        onDataUpdated={() => {
          reloadData();
        }}
      />

    </Container>
  );
};

export default StoresList;
