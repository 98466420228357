import React, { useEffect, useState } from "react";
import { useParams } from 'react-router-dom';
import { useDispatch, useSelector } from "react-redux";
import Container from "@mui/material/Container";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Button from "@mui/material/Button";
import { Grid, Paper, TablePagination } from "@mui/material";
import PageTitleDesc from "../global/pageTitleDesc/PageTitleDesc";
import { postOrdersAll } from "../../redux/orders/action";
import {
  getLocalDateWithoutTime,
  getTimeFromDate,
} from "../../helpers/General";
import {
  getEuroSymbol,
  getOrderStateText,
  getPaymentMethodString,
} from "../../utils/orders/OrdersUtils";
import OrderDetailsDialog from "./OrderDetailsDialog";
import { PUSH_TYPES } from "../../constants";
import StoresPicker from "../stores/StoresPicker";

const OrdersList = () => {


  let { passedOrderId } = useParams();



  //Selectors
  const settingsData = useSelector(({ settings }) => settings.settingsData);
  const ordersListData = useSelector(({ orders }) => orders.ordersListData);
  const userTypeStoreUser = useSelector(({ auth }) => auth.userTypeStoreUser);


  //Variables
  const dispatch = useDispatch();
  const trans = settingsData.trans;

  //Hooks
  const [ordersList, setOrdersList] = useState([]);
  const [orderDetailsModal, setOrderDetailsModal] = useState(false);
  const [orderDetailsOrderId, setOrderDetailsOrderId] = useState("");
  const [page, setPage] = useState(0); // Pagination uses zero-based index for pages
  const [rowsPerPage, setRowsPerPage] = useState(25);
  const [selectedStore, setSelectedStore] = useState(null);


  useEffect(() => {
    if (selectedStore){
      if (selectedStore != null){
        reloadData(true);
      }

  }
  }, [page, rowsPerPage,selectedStore]); // Reload data when page or rowsPerPage changes

  useEffect(() => {
    if (passedOrderId) {
      if (passedOrderId != null && passedOrderId != ""){
        setOrderDetailsModal(true);
        setOrderDetailsOrderId(passedOrderId);
      }
    }
  }, [passedOrderId]);

  

  useEffect(() => {
    if (ordersListData) {
      setOrdersList(ordersListData.orders);
    }
  }, [ordersListData]);

  const reloadData = (toPaginate) => {
    dispatch(
      postOrdersAll({ fromAdmin: true, page: toPaginate == true ?  page + 1 : page, limit: rowsPerPage , order_store: selectedStore})
    ); // `page + 1` because backend pagination often starts from 1
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage); // Update the current page
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10)); // Update the rows per page
    setPage(0); // Reset to the first page
  };

  const openOrderToEdit = (e, orderItemId) => {
    e.preventDefault();
    setOrderDetailsModal(true);
    setOrderDetailsOrderId(orderItemId);
  };

  const onOrderDetailsClose = () => {
    setOrderDetailsModal(false);
    setOrderDetailsOrderId(null);
  };

  const onOrderAnswered = () => {
    reloadData();
  }

  return (
    <Container maxWidth="xl" sx={{ mt: 4, mb: 4 }}>
      <Grid container spacing={1}>
        <Grid item xs={12}>
          <Paper
            sx={{
              p: 2,
              display: "flex",
              flexDirection: { xs: "column", md: "row" },
              alignItems: "center",
              justifyContent: "space-between",
            }}
          >

            <Grid container spacing={2}>
            <Grid item xs={12}>
              <PageTitleDesc
                  title={settingsData.trans.menu_orders}
                  desc={settingsData.trans.menu_orders_desc}
                />

            </Grid>

            <Grid item xs={12}>
            <StoresPicker
                onStoreSelect={(selectedStores) => {
                  setSelectedStore(selectedStores);
                }}
                multiple={false}
                autoSelectFirst={true}
                showOnlyUserStores={userTypeStoreUser == true}
                style={{ width: "100%" }} // Style the StoresPicker to take full width
              />

            </Grid>

            </Grid>

         

            {/* <Button
              variant="contained"
              onClick={handleAddStore}
              sx={{ mt: { xs: 2, md: 0 } }} // Conditional top margin
            >
              {trans.add_side_menu_button}
            </Button> */}
          </Paper>
        </Grid>

     
        <Grid item xs={12}>
          <Paper sx={{ p: 2, display: "flex", flexDirection: "column" }}>
            <TableContainer>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell>{trans.table_order_num}</TableCell>
                    <TableCell>{trans.table_order_date}</TableCell>
                    <TableCell>{trans.table_order_payment_way}</TableCell>
                    <TableCell>{trans.table_order_user_name}</TableCell>
                    <TableCell>{trans.table_order_user_phone}</TableCell>
                    <TableCell>{trans.table_order_address}</TableCell>
                    <TableCell>{trans.table_order_total}</TableCell>
                    <TableCell>{trans.table_order_state}</TableCell>
                    <TableCell></TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {ordersList.map((orderItem) => (
                    <TableRow key={orderItem.id}>
                      <TableCell>{orderItem.orderCode}</TableCell>
                      <TableCell>
                        {getLocalDateWithoutTime(orderItem.created_at)}{" "}
                        {getTimeFromDate(orderItem.created_at)}
                      </TableCell>
                      <TableCell>
                        {getPaymentMethodString(
                          settingsData.payment_options,
                          trans,
                          orderItem.payment_type
                        )}
                      </TableCell>
                      <TableCell>
                        {orderItem.order_contact_details.firstname}{" "}
                        {orderItem.order_contact_details.lastname}
                      </TableCell>
                      <TableCell>
                        {orderItem.order_contact_details.mobileCode}
                        {orderItem.order_contact_details.mobilePhone}
                      </TableCell>
                      <TableCell>
                        {orderItem.order_address.address}{" "}
                        {orderItem.order_address.street_number}{" "}
                        {orderItem.order_address.area}{" "}
                        {orderItem.order_address.postalCode}
                      </TableCell>
                      <TableCell>
                        {orderItem.total_cost.toFixed(2)}
                        {getEuroSymbol()}
                      </TableCell>
                      <TableCell>
                        <b
                          style={{
                            color: `${
                              orderItem.order_state ==
                              settingsData.order_states.PENDING
                                ? "#FF9800"
                                : orderItem.order_state ==
                                  settingsData.order_states.CANCELLED
                                ? "#F44336"
                                : "#4CAF50"
                            }`,
                          }}
                        >
                          {getOrderStateText(
                            settingsData.order_states,
                            trans,
                            orderItem.order_state
                          )}
                        </b>
                      </TableCell>
                      <TableCell>
                        <Button
                          variant="contained"
                          onClick={(e) => {
                            openOrderToEdit(e, orderItem.id);
                          }}
                        >
                          {trans.table_order_vide_button}
                        </Button>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
            <TablePagination
              component="div"
              count={ordersList.length} // Total items
              page={page} // Current page
              onPageChange={handleChangePage} // Change page event handler
              rowsPerPage={rowsPerPage} // Rows per page
              onRowsPerPageChange={handleChangeRowsPerPage} // Change rows per page event handler
            />
          </Paper>
        </Grid>
      </Grid>

      <OrderDetailsDialog
        open={orderDetailsModal}
        orderId={orderDetailsOrderId}
        onClose={onOrderDetailsClose}
        onOrderAnswered={onOrderAnswered}
      />
    </Container>
  );
};

export default OrdersList;
