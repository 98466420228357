import {
  POST_CATALOG_OFFERS_LIST_ALL,
  POST_CATALOG_OFFERS_ADD_EDIT,
  POST_CATALOG_OFFERS_DELETE,
  POST_CATALOG_OFFERS_SINGLE,
  POST_CATALOG_OFFERS_EDIT_PRODUCTS
  } from '../actionTypes';
  import axios from '../../helpers/Api'
  import * as General from '../../helpers/General'
import { DEBUG_MODE } from '../../constants';
  
export const postCatalogOffersListAll = (postData) => async dispatch => {
  //Get user locale of the machine he is on
  const userLocale = General.getLocale();
  postData.langLoc = userLocale;
  postData.fromAdmin = true;

  const response = await axios.post(`offer/list`,postData);

  if (DEBUG_MODE){
    console.log(response.data);
  }

  dispatch({type: POST_CATALOG_OFFERS_LIST_ALL, payload: response.data});
};


export const postCatalogOffersListAddEdit = (postData) => async dispatch => {
  //Get user locale of the machine he is on
  const userLocale = General.getLocale();
  postData.langLoc = userLocale;
  postData.fromAdmin = true;

  const response = await axios.post(`offer/addEdit`,postData);

  if (DEBUG_MODE){
    console.log(response.data);
  }

  dispatch({type: POST_CATALOG_OFFERS_ADD_EDIT, payload: response.data});
};

export const postCatalogOffersSingle = (postData) => async dispatch => {
  //Get user locale of the machine he is on
  const userLocale = General.getLocale();
  postData.langLoc = userLocale;
  postData.fromAdmin = true;

  const response = await axios.post(`offer/single`,postData);

  if (DEBUG_MODE){
    console.log(response.data);
  }

  dispatch({type: POST_CATALOG_OFFERS_SINGLE, payload: response.data});
};


export const postCatalogOffersDelete = (postData) => async dispatch => {
  //Get user locale of the machine he is on
  const userLocale = General.getLocale();
  postData.langLoc = userLocale;
  postData.fromAdmin = true;

  const response = await axios.post(`offer/delete`,postData);

  if (DEBUG_MODE){
    console.log(response.data);
  }

  dispatch({type: POST_CATALOG_OFFERS_DELETE, payload: response.data});
};

export const postCatalogOffersEditProducts = (postData) => async dispatch => {
  //Get user locale of the machine he is on
  const userLocale = General.getLocale();
  postData.langLoc = userLocale;
  postData.fromAdmin = true;

  const response = await axios.post(`offer/edit-products`,postData);

  if (DEBUG_MODE){
    console.log(response.data);
  }

  dispatch({type: POST_CATALOG_OFFERS_EDIT_PRODUCTS, payload: response.data});
};
