import {
  POST_USER_REVIEW_ALL
} from "../actionTypes";

const INIT_STATE = {
  userReviewsData: null,
};

const reducer = (state = INIT_STATE, action) => {
  switch (action.type) {
    case POST_USER_REVIEW_ALL:
      return {
        ...state,
        userReviewsData: action.payload,
      };
    default:
      return state;
  }
};

export default reducer;
