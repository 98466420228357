import "./orders.css";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Dialog,
  DialogContent,
  DialogTitle,
  Button,
  List,
  ListItem,
  ListItemText,
  Grid,
  IconButton,
  TextField,
  InputLabel,
  MenuItem,
  FormControl,
  Select,
  Chip,
  OutlinedInput,
  Typography,
  DialogActions,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { toast } from "react-toastify";
import { postOrdersAnswer, postOrdersSingle } from "../../redux/orders/action";
import { POST_ORDERS_ANSWER, POST_ORDERS_SINGLE } from "../../redux/actionTypes";
import {
  getLocalDateWithoutTime,
  getTimeFromDate,
} from "../../helpers/General";
import OrderInfoItemWidget from "./OrderInfoItemWidget";
import {
  getEuroSymbol,
  getOrderMethodName,
  getOrderStateText,
  getPaymentMethodString,
} from "../../utils/orders/OrdersUtils";
import SweetAlert from "sweetalert2";
import { showResponseMessage } from "../../utils/alerts/MyToastUtil";

const OrderDetailsDialog = (props) => {
  const settingsData = useSelector(({ settings }) => settings.settingsData);
  const singleOrderData = useSelector(({ orders }) => orders.singleOrderData);
  const orderAnswerData = useSelector(({ orders }) => orders.orderAnswerData);


  //Variables
  const dispatch = useDispatch();
  const trans = settingsData.trans;

  // Local state for selections and Stripe key
  const [orderItem, setSingleOrderItem] = useState(null);
  const [deliveryTime, setDeliveryTime] = useState(null);
  const [isConfirmDialogOpen, setConfirmDialogOpen] = useState(false);
  const [isOrderCompleted, setIsOrderCompleted] = useState(false);


  useEffect(() => {
    if (props.open == true && props.orderId != null && props.orderId != "") {
      getOrderDetails();
    }
  }, [props.open, props.orderId]);

  useEffect(() => {
    if (singleOrderData) {
      setSingleOrderItem(singleOrderData);
      setDeliveryTime(singleOrderData.order_store.delivery_time);

      setIsOrderCompleted(singleOrderData.order_state == settingsData.order_states.CANCELLED || singleOrderData.order_state == settingsData.order_states.CONFIRMED )
      dispatch({ type: POST_ORDERS_SINGLE, payload: null });

      props.onOrderAnswered();
    }
  }, [singleOrderData]);


  useEffect(() => {
    if (orderAnswerData) {
      showResponseMessage(orderAnswerData);
      dispatch({ type: POST_ORDERS_ANSWER, payload: null });
      getOrderDetails();
    }
  }, [orderAnswerData]);
  

  const getOrderDetails = () => {
    dispatch(
      postOrdersSingle({
        orderId: props.orderId,
      })
    );
  };

  

  const openUserDirections = () => {
    const { lat, lng, address, street_number, area, postalCode } = orderItem.order_address;
  
    // Create the full address text
    const addressText = `${address} ${street_number} ${area} ${postalCode}`;
  
    // Generate a URL to open Google Maps with the given address and coordinates
    const url = `https://www.google.com/maps/search/?api=1&query=${encodeURIComponent(
      `${lat}, ${lng}, ${addressText}`
    )}`;
  
    window.open(url, "_blank"); // Opens in a new tab
  };
  
  

  const handleAcceptClick = (e) => {
    e.preventDefault();
    setConfirmDialogOpen(true); // Open the confirmation dialog
  };

  const handleConfirm = () => {
    // Logic for confirming the delivery time and proceeding with the acceptance
    //console.log("Confirmed with delivery time:", deliveryTime);
    setConfirmDialogOpen(false);

    dispatch(postOrdersAnswer({id: orderItem.id, toAccept: true, deliveryTime}));
  };

  const handleCancel = () => {
    setConfirmDialogOpen(false); // Close the confirmation dialog
  };

  const handleDeclineClick = (e) => {
    e.preventDefault();
    SweetAlert.fire({
      theme: "dark",
      title: "Decline Order",
      text: "Are you sure you want to decline this order?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: settingsData.initData.app_color,
      confirmButtonText: "Decline",
      cancelButtonText: trans.cancel_btn,
      reverseButtons: true,
    }).then((result) => {
      if (result.value) {
        declineOrderConfirm();
      }
    });
  }

  const declineOrderConfirm = () => {
    setConfirmDialogOpen(false);


    dispatch(postOrdersAnswer({id: orderItem.id, toAccept: false, deliveryTime}));
  }

  return (<>

    <Dialog open={props.open} onClose={props.onClose} fullWidth maxWidth="md">
      <DialogTitle>
        <div className="_flex_row_space">
          {orderItem != null ? (
            <div>
              <div className="fontBold">
                {trans.dialog_order_details} #{orderItem.orderCode}
              </div>
              <div style={{ fontSize: "14px" }}>
                {" "}
                {getLocalDateWithoutTime(orderItem.created_at)}{" "}
                {getTimeFromDate(orderItem.created_at)}
              </div>
            </div>
          ) : null}

          <IconButton onClick={props.onClose}>
            <CloseIcon />
          </IconButton>
        </div>
      </DialogTitle>
      <DialogContent>
        {orderItem != null ? (
          <div>
            <br />
            <Grid container spacing={2}>
              <Grid item xs={4}>
                <div className="_flex_row">
                  <img
                    src={orderItem.order_store.logo_thumb_url}
                    width={50}
                  ></img>
                  <div style={{ marginLeft: "15px" }}>
                    <div className="fontBold">{trans.order_details_store}</div>
                    <div> {orderItem.order_store.name}</div>
                  </div>
                </div>
              </Grid>

              <Grid item xs={4}>
                <OrderInfoItemWidget
                  title={trans.table_order_payment_way}
                  text={`${getPaymentMethodString(
                    settingsData.payment_options,
                    trans,
                    orderItem.payment_type
                  )}`}
                />
              </Grid>

              <Grid item xs={4}>
                <OrderInfoItemWidget
                  title={trans.table_order_method}
                  text={`${getOrderMethodName(
                    settingsData.orderMethodsList,
                    orderItem.order_method
                  )}`}
                />
              </Grid>

              <Grid item xs={12}>
                <hr />
                <h4 style={{ padding: "0px", margin: "0px" }}>{trans.order_details_user_details}</h4>
              </Grid>

              <Grid item xs={4}>
                <OrderInfoItemWidget
                  title={trans.table_order_user_name}
                  text={`${orderItem.order_contact_details.firstname} ${orderItem.order_contact_details.lastname}`}
                />
              </Grid>

              <Grid item xs={4}>
                <OrderInfoItemWidget
                  title={trans.table_order_user_phone}
                  text={`${orderItem.order_contact_details.mobileCode}${orderItem.order_contact_details.mobilePhone}`}
                />
              </Grid>

              <Grid item xs={4}>
                <OrderInfoItemWidget
                  title={trans.table_order_user_email}
                  text={`${orderItem.order_contact_details.email}`}
                />
              </Grid>

              <Grid item xs={12}>
                <hr />
                <h4 style={{ padding: "0px", margin: "0px" }}>
                  {trans.order_details_address_details}
                </h4>
              </Grid>

              <Grid item xs={4}>
                <div style={{cursor:'pointer'}} onClick={(e) => openUserDirections(e)}>
                  <OrderInfoItemWidget
                    title={trans.table_order_address}
                    text={`${orderItem.order_address.address}
                  ${orderItem.order_address.street_number}`}
                  />
                </div>
              </Grid>

              <Grid item xs={4}>
                <OrderInfoItemWidget
                  title={trans.table_order_address_postal}
                  text={`${orderItem.order_address.postalCode}`}
                />
              </Grid>

              <Grid item xs={4}>
                <OrderInfoItemWidget
                  title={trans.table_order_address_area}
                  text={`${orderItem.order_address.area}`}
                />
              </Grid>

              <Grid item xs={4}>
                <OrderInfoItemWidget
                  title={trans.table_order_address_floor}
                  text={`${orderItem.order_address.floor}`}
                />
              </Grid>

              <Grid item xs={4}>
                <OrderInfoItemWidget
                  title={trans.table_order_address_bell}
                  text={`${orderItem.order_address.bell}`}
                />
              </Grid>

              <Grid item xs={4}>
                <OrderInfoItemWidget
                  title={trans.table_order_address_comment}
                  text={`${orderItem.order_address.comment}`}
                />
              </Grid>

              <Grid item xs={12}>
                <hr />
                <h4 style={{ padding: "0px", margin: "0px" }}>
                  {trans.order_details_order_procuts}
                </h4>
              </Grid>

              <Grid
                item
                xs={12}
                style={{ paddingLeft: "5px", paddingRight: "5px" }}
              >
                <Grid container spacing={2}>
                  {orderItem.order_items.map((item, index) => {
                    // Image source based on the type of order item
                    var img =
                      item?.type === "OFFER"
                        ? item?.offer_details?.offer_item?.offer_image_thumb
                        : item?.product_details?.product_item?.main_image_thumb;

                    // // Description based on the type of order item
                    // var desc = item?.type === 'OFFER'
                    //   ? item?.offerDetails?.offerItem?.description
                    //   : item?.product_details?.productItem?.description;

                    // Array to store extra items
                    let extraItems = [];

                    // Handling product-type order items
                    if (item?.type === "PRODUCT") {
                      item?.product_details?.order_product_extras?.forEach(
                        (orderProductExtra) => {
                          // Collecting selected extras
                          let selectedProductExtrasList = [];

                          orderProductExtra?.order_product_extra_items?.forEach(
                            (element) => {
                              const itemName = element.item_name; // Fetching item_name with underscore
                              if (itemName) {
                                selectedProductExtrasList.push(itemName); // Only add if itemName is not null
                              }
                            }
                          );

                          // Check if all extras are selected
                          if (orderProductExtra.allSelected) {
                            extraItems.push(
                              `${
                                trans.product_extras_all_selection_all
                              } (${selectedProductExtrasList.join(", ")})`
                            );
                          } else {
                            extraItems.push(
                              selectedProductExtrasList.join(", ")
                            );
                          }
                        }
                      );
                    } else {
                      // Handling offer-type order items
                      item?.offer_details?.selected_products?.forEach(
                        (selectedProduct) => {
                          let selectedProductExtrasList = [];

                          selectedProduct?.order_offer_product_extras?.forEach(
                            (orderOfferProductExtra) => {
                              let selectedProductExtraItemsList = [];

                              orderOfferProductExtra?.order_product_extra_items?.forEach(
                                (orderProductExtraItem) => {
                                  const itemName =
                                    orderProductExtraItem.item_name; // Fetching item_name with underscore
                                  selectedProductExtraItemsList.push(itemName);
                                }
                              );

                              // Check if all extras are selected
                              if (orderOfferProductExtra.allSelected) {
                                selectedProductExtrasList.push(
                                  `${
                                    trans.product_extras_all_selection_all
                                  } (${selectedProductExtraItemsList.join(
                                    ", "
                                  )})`
                                );
                              } else {
                                selectedProductExtrasList.push(
                                  selectedProductExtraItemsList.join(", ")
                                );
                              }
                            }
                          );

                          const selectedProductExtras =
                            selectedProductExtrasList.join(", ");
                          extraItems.push(
                            `${selectedProduct?.product_item?.name} ${selectedProductExtras}`
                          );
                        }
                      );
                    }

                    // Joining all extra items into a single string
                    var selectedExtras = extraItems.join(", ");

                    return (
                      <Grid
                        container
                        item
                        spacing={2}
                        key={item.id}
                        justifyContent={"space-between"}
                      >
                        {" "}
                        {/* Apply spacing for inner Grids */}
                        <Grid item xs={11}>
                          <div className="_flex_row">
                            {img != null && img != "" ? (
                              <img
                                src={img}
                                style={{
                                  objectFit: "cover",
                                  height: "50px",
                                  width: "50px",
                                  marginLeft: "10px",
                                  borderRadius:'5px'
                                }}
                              ></img>
                            ) : null}
                            <div style={{ marginLeft: "10px" }}>
                              <div className="fontMedium">{item.item_name}</div>
                              <div>{selectedExtras}</div>
                              <div
                                style={{ fontSize: "13px" }}
                                className="fontBold"
                              >
                                {orderItem.total_cost.toFixed(2)}
                                {getEuroSymbol()}
                              </div>
                              <div style={{fontSize:'13px'}}><span className="fontMedium">{trans.order_item_comment}: </span>{item.comment}</div>
                            </div>
                          </div>
                        </Grid>
                        <Grid item xs={1}>
                          <div className="quantity_box_wrapper">
                            {" "}
                            {/* Wrapper to limit width */}
                            <div className="quantity_box fontMedium">{item.quantity}</div>
                          </div>
                        </Grid>
                      </Grid>
                    );
                  })}
                </Grid>
              </Grid>

              <Grid item xs={12}>
                <div >
                  <hr style={{ opacity: "0.2" }} />
                </div>
                <div style={{marginTop:'20px'}} className="_flex_row_space">
                  <div className="fontBold">{trans.table_order_total}:</div>
                  <div className="fontBold" style={{ marginRight: "10px" }}>
                    {" "}
                    <span className="fontMedium" >{orderItem.coupon_code != "" ? `${trans.coupon_code_field}: (${orderItem.coupon_code})` : ""}</span>
                    <span style={{color: '#F44336'}}>{orderItem.coupon_code != "" ? ` -${orderItem.discount_num}${orderItem.coupon_type == "PERCENT" ? "%" : getEuroSymbol()} ` : ""}</span>
                    {orderItem.delivery_cost != null && orderItem.delivery_cost != 0.0 ? <span style={{fontSize:'13px',marginRight:'5px'}} className="fontMedium">{trans.delivery_cost}: {orderItem.delivery_cost.toFixed(2)}{getEuroSymbol()}</span>: ""}
                    {orderItem.total_cost.toFixed(2)}
                    {getEuroSymbol()}
                  </div>
                </div>
              </Grid>

              {isOrderCompleted == true ?   <Grid item xs={12}>
              <div>
                  <hr style={{ opacity: "0.2" }} />
                </div>
                <div style={{marginTop:'20px'}} className="_flex_row_space">
                  <div className="fontBold">{trans.table_order_state}:</div>
                  <div className="fontBold" style={{ marginRight: "10px" }}>
                  <b style={{color:`${orderItem.order_state == settingsData.order_states.PENDING ? "#FF9800" : orderItem.order_state == settingsData.order_states.CANCELLED ? "#F44336" : "#4CAF50"}`}}>{getOrderStateText(settingsData.order_states,trans,orderItem.order_state)}</b>
                  </div>
                </div>
            
          </Grid> : null}
      

            </Grid>
          </div>
        ) : null}
      </DialogContent>
      {isOrderCompleted == false ?    <DialogActions >
    <Button
         style={{backgroundColor:'#F44336',minWidth:'150px',marginRight:'15px'}}
         variant="contained"
          onClick={(e) => handleDeclineClick(e)}
        >
          {trans.order_details_button_decline}
        </Button> 
        <Button
          style={{backgroundColor:'#4CAF50',minWidth:'150px'}}
          variant="contained"
          onClick={(e) => handleAcceptClick(e)}
        >
          {trans.order_details_button_accept}
        </Button>
      
      </DialogActions> : null}
    </Dialog>
    {/* Confirmation dialog */}
    <Dialog open={isConfirmDialogOpen} onClose={handleCancel} fullWidth maxWidth="sm">
        <DialogTitle>
          
          {trans.order_details_confirm_delivery_title}
        </DialogTitle>

        <DialogContent>
          <div>

          <br/>
         
          <div>{trans.order_details_confirm_delivery_desc}</div>

          <br/>

          <div className="_flex_row">
            <span style={{marginRight:'10px'}}>{trans.order_details_confirm_delivery_minutes}: </span>
            <TextField
              margin="dense"
              id="deliveryTime"
              label="Select Delivery Time"
              value={deliveryTime}
              onChange={(e) => setDeliveryTime(e.target.value)}
            />

          </div>

         

          </div>
         
        </DialogContent>

        <DialogActions>
          <Button onClick={handleCancel} color="secondary">
            {trans.order_details_confirm_delivery_cancel}
          </Button>

          <Button
            onClick={handleConfirm}
            variant="contained"
            style={{ backgroundColor: "#4CAF50", minWidth: "150px" }}
          >
           {trans.order_details_confirm_delivery_confirm}
          </Button>
        </DialogActions>
      </Dialog>
  </>);
};

export default OrderDetailsDialog;
