import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Container from "@mui/material/Container";
import {
  Divider,
  Grid,
  Button,
  Paper,
  List,
  ListItem,
  ListItemText,
  ListItemSecondaryAction,
  IconButton,
  Switch,
  Box,
  TextField,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import PageTitleDesc from "../global/pageTitleDesc/PageTitleDesc";
import StoresPicker from "../stores/StoresPicker";
import { postCouponsDelete, postCouponsListAll } from "../../redux/coupons/action";
import AddEditCoupon from "./AddEditCoupon";
import SweetAlert from "sweetalert2";
import { showResponseMessage } from "../../utils/alerts/MyToastUtil";
import { POST_COUPONS_DELETE } from "../../redux/actionTypes";

const CouponsList = () => {
  const dispatch = useDispatch();
  const couponsListData = useSelector(({ coupons }) => coupons.couponsListData);

  const deleteCouponData = useSelector(({ coupons }) => coupons.deleteCouponData);


  const settingsData = useSelector(({ settings }) => settings.settingsData);
  const [storeItems, setStoreItems] = useState([]);
  const [coupons, setCoupons] = useState([]);
  const [addEditCouponId, setAddEditCouponId] = useState(null);
  const [isAddEditDialogOpen, setIsAddEditDialogOpen] = useState(false);

  const trans = settingsData.trans;

  

  useEffect(() => {
    if (storeItems) {
      reloadData();
    }
  }, [storeItems]);

  useEffect(() => {
    if (couponsListData) {
      setCoupons(couponsListData.coupons);
    }
  }, [couponsListData]);

  useEffect(() => {
    if (deleteCouponData) {
      showResponseMessage(deleteCouponData);
      dispatch({ type: POST_COUPONS_DELETE, payload: null });
      reloadData();
    }
  }, [deleteCouponData]);
  

  const reloadData = () => {
    dispatch(postCouponsListAll({}));
  };

  const handleAddCoupon = () => {
    setIsAddEditDialogOpen(true);
    setAddEditCouponId(null);
  };

  const handleEditOffer = (id) => {
    setIsAddEditDialogOpen(true);
    setAddEditCouponId(id);
  };

  const handleDeleteOffer = (e,id) => {
    e.preventDefault();
    SweetAlert.fire({
      theme: "dark",
      title: trans.delete_record_title,
      text: trans.delete_record_desc,
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: settingsData.initData.app_color,
      confirmButtonText: trans.delete_record_button,
      cancelButtonText: trans.cancel_btn,
      reverseButtons: true,
    }).then((result) => {
      if (result.value) {
        dispatch(
          postCouponsDelete({ id: id })
        );
      }
    });
  };

  const handleCloseDialog = () => {
    setIsAddEditDialogOpen(false);
    setAddEditCouponId(null);
  };

  return (
    <Container maxWidth="xl" sx={{ mt: 4, mb: 4 }}>
      <Grid container spacing={1}>
        <Grid item xs={12}>
          <Paper sx={{ p: 2, display: "flex", justifyContent: "space-between", alignItems: "center" }}>
            <PageTitleDesc
              title={settingsData.trans.menu_catalog_offers}
              desc={settingsData.trans.menu_catalog_offers_desc}
            />
            <Button
              startIcon={<AddCircleOutlineIcon />}
              onClick={handleAddCoupon}
              color="primary"
              variant="contained"
              sx={{ minWidth: 160 }}
            >
              {trans.coupons_add_title}
            </Button>
          </Paper>
          <br />
          <Paper sx={{ p: 2 }}>
            <TableContainer>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell>{trans.coupon_code_field}</TableCell>
                    <TableCell>{trans.coupon_description_field}</TableCell>
                    <TableCell>{trans.coupon_discount_percentage_field}</TableCell>
                    <TableCell>{trans.coupon_status_field}</TableCell>
                    <TableCell></TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {coupons.map((coupon) => (
                    <TableRow key={coupon.id}>
                      <TableCell>{coupon.coupon_code}</TableCell>
                      <TableCell>{coupon.description}</TableCell>
                      <TableCell>{coupon.discount_percentage}%</TableCell>
                      <TableCell>{coupon.active ? "Yes" : "No"}</TableCell>
                      <TableCell>
                        <IconButton onClick={() => handleEditOffer(coupon.id)}><EditIcon /></IconButton>
                        <IconButton onClick={(e) => handleDeleteOffer(e,coupon.id)}><DeleteIcon /></IconButton>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </Paper>
        </Grid>
      </Grid>

      <AddEditCoupon
        itemId={addEditCouponId}
        isOpen={isAddEditDialogOpen}
        onClose={handleCloseDialog}
        onItemUpdate={() => {
          reloadData();
        }}
        storeItems={storeItems}
      />
      
    </Container>
  );
};

export default CouponsList;
